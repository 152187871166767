
nsr.config(["$locationProvider", function($locationProvider) {
    $locationProvider.hashPrefix('!');
    //$locationProvider.html5Mode(true);
}]);

/**
 * Helper method to quickly generate an object that represents a standard state for our app
 */
function simpleState(url, templateUrl, controller, title) {
    var defaultView = {templateUrl: templateUrl};

    if (controller != null) {
        defaultView.controller = controller;
    }

    var state = {url: url, views: {'': defaultView}};

    if (title != null) {
        state.views.title = {template: '<h2>' + title + '</h2>'};
    }

    if(typeof tracking == 'undefined') {
        //tracking = title;
    }

    state.subView = function (viewName, templateUrl, controller) {
        this.views[viewName] = {templateUrl: templateUrl};
        if (controller != null) {
            this.views[viewName].controller = controller;
        }
        return this;
    };

    return state;
}

/**
 * Routes/States for our application
 */
nsr.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('index', {
            url: "/",
            templateUrl: 'partials/landing.html',
            controller: 'LandingController'
        })
    
        .state('landing', {
            url: "/landing", templateUrl: 'partials/landing.html', controller: 'StaticLandingController'
        })

        .state('legal',  simpleState("/legal", 'partials/legal.html', null, 'Legal'))
        .state('terms',  simpleState("/terms", 'partials/terms.html', null, 'Terms'))
        .state('settings',
            simpleState("/investor-settings/", 'partials/settings.html', 'SettingsController', 'Settings')
        )
        .state('settings.profile',
            simpleState("profile", 'partials/settings.profile.html', null, 'Profile')
        )
        .state('settings.billing',
            simpleState("billing", 'partials/settings.billing.html', 'BillingController', 'Billing')
        )
        .state('settings.security',
            simpleState("security", 'partials/settings.security.html', null, 'Billing')
        )
        .state('settings.notifications',
            simpleState("notification", 'partials/settings.notifications.html', null, 'Billing')
        )
        .state('settings.fees',
            simpleState("fees", 'partials/settings.fees.html', null, 'Management Fees')
        )
        .state('settings.statements',
            simpleState("statements", 'partials/settings.statements.html', null, 'Statements')
        )
        .state('settings.agreements',
            simpleState("agreements", 'partials/settings.agreements.html', 'AgreementController', 'Investor Documents')
        )
        .state('login',
            simpleState("/login", 'partials/login.html', 'AuthController', 'Sign in to your account')
        )

        .state('forgot-password', simpleState("/forgot-password", 'partials/forgot.html', 'PasswordController', 'Reset Password'))

        .state('reset-password', {
            url: "/reset-password/:token",
            templateUrl: 'partials/reset.html',
            controller: 'PasswordController'
        })
        .state('support', {
            url: "/support",
            templateUrl: 'partials/support.html'
        })
        .state('dashboard',
            simpleState("/dashboard", 'partials/dashboard.html', 'DashboardController', 'Dashboard')
        )

        .state('stats',
            simpleState("/stats/:platform/:portfolioId/:userFilterId", 'partials/stats.html', 'StatsController', 'Stats')
        )
        /*
        .state('stats',
            simpleState("/stats/:platform/:userFilterId", 'partials/stats.html', 'StatsController', 'Stats')
        )

        .state('stats-portfolio',
            simpleState("/stats/:platform/:portfolioId/:userFilterId", 'partials/stats.html', 'StatsController', 'Stats')
        )*/

        // # for migrated users
        .state('simple-mode',
            simpleState("/simple-mode/:platform/:accountId/", 'partials/simple-mode.html', 'SimpleModeController', 'Simple Mode Strategy')
        )
        // #

        .state('permalink', { url: "/permalink-load/:hash", controller: 'PermalinkController',
            template: '<div class="col-md-12 subject-block dead-center">Loading...<i class="fa fa-spinner fa-spin"></i></div>' }
        )

        // .state('secondary-market',
        //     simpleState("/secondary-market/:platform", 'partials/secondary-market.html', 'SecondaryMarketController', 'Secondary Market')
        // )
        //
        // .state('primary-market',
        //     simpleState("/primary-market/:platform", 'partials/primary-market.html', 'PrimaryMarketController', 'Primary Market')
        // )

        .state('transactions',
            simpleState("/transactions", 'partials/transactions.html', 'TransactionController', 'Transactions')
        )

            // notes

        .state('notes',
            simpleState("/notes/:accountId", 'partials/notes.html', 'NotesController', 'Detailed Notes View')
        )

        .state('notes-filtered',
            simpleState("/notes/filtered/:platform/:accountId", 'partials/notes.html', 'FilteredNotesController', 'Detailed Notes View')
        )

        // Account Manager
        .state('accounts',
            simpleState("/accounts/{subAction}", 'partials/autoinvest.html', 'AutoInvestController', 'Account Manager')
                .subView('account.edit', 'partials/account.html', 'AccountController')
                .subView('allocation.edit', 'partials/allocation.html', 'AllocationController')
                .subView('account.provision', 'partials/account-provision.html', 'AccountProvisionController')
        )


        // Userfilters / Strategies

        .state('strategies',
            simpleState("/strategies", 'partials/strategies.html', 'StrategyController', 'Strategy Manager')
                .subView('strategy.edit', 'partials/strategy-settings.html')
        )
        .state('strategy-groups',
            simpleState("/strategy-groups", 'partials/strategy-groups.html', 'StrategyGroupController', 'Strategy Groups Manager')
        )
        .state('balance',
            simpleState("/balance/:account_id", 'partials/balance.html', 'BalanceController', 'Extended Balance History')
        )

        .state('investing',
            simpleState("/investing", 'partials/investing.html', 'AutoInvestController', 'Investing')
        )

        .state('charts',
            simpleState("/charts/{type}", 'partials/charts.html', 'ChartController', 'Charts')
        )

        .state('secondary-transactions',
            simpleState("/secondary/transactions", 'partials/secondary.html', 'SecondaryController', 'Secondary Market Activity')
        )
        .state('secondary-sell',
            simpleState("/secondary/sell", 'partials/secondary-sell.html', 'SecondarySellController', 'Secondary Sell Strategies')
        )

        .state('signup', simpleState("/signup", 'partials/signup/create.html', 'SignupController'))
            .state('signup.account', { url: '/account', templateUrl: 'partials/signup/account.html' })
            .state('signup.create', { url: '/create', templateUrl: 'partials/signup/create.html' })
            .state('signup.profile', { url: '/profile', templateUrl: 'partials/signup/profile.html' })
            .state('signup.funds', { url: '/funds', templateUrl: 'partials/signup/funds.html' })
            .state('signup.guidance', { url: '/guidance', templateUrl: 'partials/signup/guidance.html' })
            .state('signup.legal', { url: '/legal', templateUrl: 'partials/signup/legal.html' })
            .state('signup.done', { url: '/finished', templateUrl: 'partials/signup/done.html'})

        .state('invoices',
            simpleState("/invoices", 'partials/invoices.html', 'InvoiceController', 'Invoices')
        )
        .state('advisor',
            simpleState("/advisor", 'partials/advisor.html', 'AdvisorController', 'Advisor Portal')
        )
        .state('advisor-setup',
            simpleState("/advisor/setup", 'partials/advisor-setup.html', 'AdvisorController', 'Advisor Setup')
        )
        .state('stats-roi',
            simpleState("/methodology", 'partials/methodology.html', null, 'NSR Return Methodology')
        )
        .state('stats-freshness',
            simpleState("/data-import-details", 'partials/freshness.html', null, 'Date Import Details')
        )
        .state('features',
            simpleState("/features", 'partials/matrix.html', null, 'Features')
        )
        .state('statements',
            simpleState("/statements", 'partials/statements.html', 'StatementController', 'Statements')
        )
        .state('pricing',
            simpleState("/pricing", 'partials/pricing.html', 'PricingController', 'Pricing')
        )
        .state('valuation',
            simpleState("/valuation", 'partials/valuation.html', 'ValuationController', 'Valuation Module')
        )
        .state('benchmark',
            simpleState("/benchmark", 'partials/benchmark.html', 'BenchmarkController', 'Benchmarking')
        )


        // .state('signup-wizard', simpleState("/signup-wizard", "partials/signup-wizard.html"))

        /* final semicolon for routes, make it easier to copy/paste */
        ;

    $urlRouterProvider.otherwise('/');

}]);

// Assist with navigation on mobile
$(document).on('click','.navbar-collapse.in',function(e) {
    if( $(e.target).is('a') ) {
        $(this).collapse('hide');
    }
});


