nsr.controller('NotesController', ['$log', 'API', 'TransitionService', 'ErrorHandler', '$timeout', 'MessageService', '$scope', '$stateParams',
    function ($log, API, TransitionService, ErrorHandler, $timeout, MessageService, $scope, $stateParams) {
        $scope.notes = [];

        API.get('accounts/' + $stateParams.accountId + '/notes').then(function (response) {
            $scope.notes = response.data;
        });

    }]);

nsr.controller('FilteredNotesController', ['$log', 'API', 'TransitionService', 'ErrorHandler', 'SearchConnector', 'MessageService', '$scope', '$stateParams',
    function ($log, API, TransitionService, ErrorHandler, SearchConnector, MessageService, $scope, $stateParams) {

        $scope.pageSize = 25;
        $scope.page = 1;
        $scope.total = 0;

        $scope.notes = [];
        $scope.displayNotes = [];


        $scope.statsHelper = SearchConnector.setVendorAndLoadStatsHelper($stateParams.platform);


        $scope.renderResults = function() {
            $scope.displayNotes = $scope.notes.slice(($scope.page - 1) * $scope.pageSize, $scope.pageSize * $scope.page);

            console.log("Range: " + " " + $scope.pageSize * $scope.page);
        };

        //make sure the stats helper has loaded before executing our query

        $scope.load = function () {

            $scope.statsHelper.initPromise.then(function () {
                var filterRequest = SearchConnector.getHistoricalFilter();

                // Always keeps the portfolio separate so it doesn't get mixed in with the user filter
                if (SearchConnector.portfolio !== null) {

                    filterRequest.filter.portfolio_id = SearchConnector.portfolio.portfolio_id;

                    if (_.has(SearchConnector.portfolio, 'sub_portfolio_id')) {
                        filterRequest.filter.sub_portfolio_id = SearchConnector.portfolio.sub_portfolio_id;
                    }
                }


                API.post('accounts/notes/filtered', filterRequest).then(function (response) {
                    $scope.notes = response.data;
                    $scope.total = response.data.length;

                    $scope.renderResults();
                });
            });
        };

        $scope.load();
    }]);
