nsr.factory('AccountService', ['$log', 'API', 'TransitionService', 'ErrorHandler', '$timeout', 'MessageService', function ($log, API, TransitionService, ErrorHandler, $timeout, MessageService) {
    /**
     * Consolidate Account management activities here, so we don't need to worry about refreshing account lists,
     * -- loading the list of accounts, adding, removing, editing, etc...
     */

    var AccountService = { accounts: [] };

    AccountService.refresh = function() {
        return TransitionService.showToastUntilCompleted(
            API.get('autoinvests').success(function (response) {
                _.each(response, function (account) {
                    if (account.last_balance == null) {
                        //add fake balance to the account to prevent errors
                        account.last_balance = {
                            //"balance_id": -1,
                            "cash": 0,
                            "pending": 0,
                            "outstanding_principal": 0,
                            "accrued_interest": 0,
                            "account_total": 0,
                            //"timestamp": "2014-02-01 12:45:03",
                            "account_id": account.account_id,
                            "managed_principal": null
                        };
                    }
                });
                AccountService.accounts = response;
            }), 'Loading Accounts');
    };

    AccountService.sync = function (accountId) {
        MessageService.showMessage("Starting Account Sync... This may take a little while...");
        return API.put('accounts/sync/' + accountId, {
            dispatch: 'now'
        }).success(function () {
            //$timeout(2000, service.refresh);
            //$timeout(7000, service.refresh);
        });
    };

    AccountService.remove = function (accountId) {
        return API.delete('accounts/' + accountId).success(AccountService.refresh);
    };


    AccountService.edit = function (account) {
        return API.put('accounts/' + account.account_id, account)
            .success(function () {
                //refresh so the account shows up right away
                AccountService.refresh();

                //give back end a few seconds to pull in portfolio and updates balances then refresh again
                // $timeout(7000, service.refresh);
                //slanger should update this
            })
            .error(function (response) { bootbox.alert(response.errors[0]); }
        );
    };

    AccountService.create = function (account) {
        return API.post('accounts', account)
            .success(function () {
                //refresh so the account shows up right away
                AccountService.refresh();

                //give back end a few seconds to pull in portfolio and updates balances then refresh again
                // $timeout(7000, service.refresh);
                //slanger should update this
            })
            .error(function (response) {
                bootbox.dialog({
                    message: ErrorHandler.format(),
                    title: 'Error Saving Account',
                    buttons: {
                        main: {
                            label: "Ok",
                            className: "btn-primary",
                            callback: function () { }
                        }
                    }
                });
            }
        );
    };


    AccountService.downgrade = function(account) {
        API.delete('accounts/upgrade/' + account.account_id).success(function (data) {
            AccountService.refresh();
        }).error(function (data) {
            bootbox.alert(data.error);
        });
    };

    AccountService.upgrade = function(accountId, managementStyleId) {
        API.put('accounts/upgrade/' + accountId, {
            'id' : managementStyleId
        }).success(function(data) {
            AccountService.refresh();
            bootbox.alert("Account successfully upgraded!");
        }).error(function(data) {
            bootbox.alert(data.errors[0]);
        });
    };


    MessageService.registerCallback('reload-account', AccountService.refresh);

    return AccountService;
}]);


nsr.factory('AccountSingletonService', ['$log', 'API', function ($log, API) {
    return {
        account: {},

        setAccount: function (account) {
            if (account === null) {
                this.accountId = null;
                this.vendorId = 2;
                this.description = null;
                this.account = null;
                return;
            }


            this.account = account;
            this.accountId = account.account_id;
            this.vendorId = account.vendor_id;
            this.description = account.description;
        },
        getAccount: function () {
            return this.account;
        },
        accountId: null,
        vendorId: 2,
        description: null
    }
}]);


nsr.controller('AutoInvestController', ['$scope', '$http', 'API', '$log', 'UserService', 'VendorService', 'AccountSingletonService', 'AccountService', '$stateParams', '$timeout', '$state', '$uibModal',
    function ($scope, $http, API, $log, UserService, VendorService, AccountSingletonService, AccountService, $stateParams, $timeout, $state, $uibModal) {

        $scope.slideoverWidth = '500px';

        $scope.search = {
            description: ''
        };

        $scope.slideovers = {
            account: false,
            allocation: false,
            provision: false
        };

        $scope.clearAutoInvest = function () {
            $scope.autoInvest = {
                enabled: true, primary: true, secondary: false
            };
        };


        $scope.accountService = AccountService;
        $scope.transition.flagInProgressUntilCompleted(AccountService.refresh());


        $scope.volumeAnalysis = function (idleCash, volumeAnalysis, idleCashReserve) {

            if (_.isUndefined(volumeAnalysis) || volumeAnalysis.fractional == 0) {
                return 'N/A';
            }


            //assumptions for listing to funded loan rates
            var fundingSuccessRatio = 0.98 * 0.67;


            var weeksAnalyzed = (volumeAnalysis.weeks_analyzed == null) ? 12 : volumeAnalysis.weeks_analyzed;
            var daysToFund = Math.floor(idleCash / (volumeAnalysis.fractional * fundingSuccessRatio / weeksAnalyzed / 7));

            if (daysToFund < 1) {
                return "Today";
            } else if (daysToFund == 1) {
                return "Tomorrow";
            } else {
                return daysToFund + " Days";
            }

        };

        /**
         * Initialize any custom strategies this user might own
         */
        $scope.vendorStrategies = {};
        API.get('strategy-group-summaries').success(function (response) {
            _.each(response, function (strategyGroup) {
                var vStrategies = $scope.vendorStrategies[strategyGroup.vendor_id];
                if (vStrategies == null) {
                    $scope.vendorStrategies[strategyGroup.vendor_id] = [strategyGroup];
                } else {
                    vStrategies.push(strategyGroup)
                }
            });
        });

        $scope.clearAutoInvest();

        $scope.isFilterSecondaryCapable = function(vendorId, userFilterId) {


            if (userFilterId == null || _.isUndefined(userFilterId)) return false;

            var filter = _.findWhere(UserService.getUserFilters(vendorId), {user_filter_id : userFilterId}) ;
            if (filter == null) return false;

            return (filter.filter_breakout.secondary != null);
        };

        $scope.toggleSecondary = function ($event, autoInvest, secondaryOnly) {
            autoInvest.secondary = $event.target.checked;
            if (secondaryOnly) {
                autoInvest.primary = !autoInvest.secondary;
            } else {
                autoInvest.primary = true;
            }
        };

        $scope.create = function (a) {
            var payload = {
                account_id: a.account_id,
                user_filter_id: $scope.autoInvest.user_filter_id,
                enabled: $scope.autoInvest.enabled,
                invest_amount: $scope.autoInvest.invest_amount,
                minimum_idle: $scope.autoInvest.minimum_idle,
                primary: $scope.autoInvest.primary,
                secondary: $scope.autoInvest.secondary
            };

            API.post('autoinvests', payload).success(function (response) {
                a.autoinvest.push(response);
                a.add = false;
                $scope.clearAutoInvest();
            });
        };

        $scope.delete = function (accountId) {
            API.get('accounts/closure-fees/' + accountId).then(function(response) {

                var msg = '';
                var fees = response.data.fees;

                if(fees > 0) {
                    msg = "There are $" + fees + " in management fees pending which will be charged to your billing source. This process can not be undone, are you sure you want to delete this account? <br/>Type DELETE to proceed";
                } else {
                    msg = "This process can not be undone, are you sure you want to delete this account? <br/>Type DELETE to proceed";
                }


                bootbox.prompt(msg, function (result) {
                    if (result == 'DELETE') {
                        $scope.slideovers.account = false;
                        $scope.transition.showToastUntilCompleted(
                            AccountService.remove(accountId), "Removing your account...").error(function() {
                                $scope.slideovers.account = true;
                            });
                    } else {
                        bootbox.alert("Account deletion canceled. DELETE must be typed in all uppercase to confirm deletion request.");
                    }
                });
            });
        };

        $scope.sync = function (accountId) {
            AccountService.sync(accountId);
        };

        $scope.transfer = function (accountId, vendorId) {
            var allowsTransfers = [1];

            var view = 'partials/transfers.html';


            if (!_.contains(allowsTransfers, vendorId)) {
                view = 'partials/transfers-unsupported.html';
            }

            

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: view,
                controller: 'TransferController',
                size: 'sm',
                resolve: {
                    accountId : accountId
                }
            });

            modalInstance.result.then(function (transfer) {
                switch (transfer.type) {
                    case 'deposit':
                        API.post('transfers/deposit/' + accountId, {
                            amount: transfer.transferAmount
                        }).then(function () {
                            bootbox.alert("Deposit Successfully Submitted")
                        });

                        break;

                    case 'withdraw':
                        API.post('transfers/withdraw/' + accountId, {
                            amount: transfer.transferAmount
                        }).then(function () {
                            bootbox.alert("Withdrawal Successfully Submitted")
                        });

                        break;

                    default:
                        bootbox.alert("Unsupported function: ".transfer.type);
                }
            }, function () {

            });

        };

        $scope.banking = function (accountId, vendorId) {
            var view = 'partials/banking.html';

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: view,
                controller: 'BankingController',
                size: 'sm',
                resolve: {}
            });

            modalInstance.result.then(function (banking) {
                API.post('accounts/banking/' + accountId, banking).then(function () {
                    bootbox.alert("Account Linked, please allow 3-5 business days for funds to settle");
                });
            }, function () {

            });
        };

        $scope.account = function (account) {
            if(window.innerWidth < 500) {
                $scope.slideoverWidth = window.innerWidth + 'px';
            } else {
                $scope.slideoverWidth = 500 + 'px';
            }

            $scope.slideovers.account = true;
            AccountSingletonService.setAccount(account);
        };

        $scope.allocation = function (account) {
            $scope.slideovers.allocation = true;
            AccountSingletonService.setAccount(account);
        };

        $scope.toggle = function (account) {

                account.enabled = !account.enabled;
                API.put('accounts/toggle/' + account.account_id);

        };

        $scope.accountMissingInvestmentCriteria = function(account) {
            if (account.vendor_id == 12 || account.vendor_id == 13) {
                return false;
            }
            return (!account.fully_managed && account.strategy == null && (account.autoinvest == null || account.autoinvest.length == 0));
        };

        /**
         * States of an account should be one of:
         *      error, analytics, paused, simulation, running, runningManaged
         *
         * @param account
         * @returns {*}
         */
        $scope.getAccountStatus = function (account) {
            if (account.account_status_id == 0) {
                return "setup";
            }

            if (account.account_status_id != 1) {
                return "error";
            }

            if (account.enabled == 0) {
                return (UserService.isSetupForInvesting()) ? "paused" : "analytics";
            }

            if (account.enabled == 1) {
                //not investing, needs auto invests
                if ($scope.accountMissingInvestmentCriteria(account)) {
                    return "noCriteria";
                }

                if (account.simulation == 1) return "simulation";

                if (account.fully_managed == 1) return "runningManaged";

                return "running";
            }
        };

        $scope.downgrade = function (account) {
            bootbox.confirm("If you proceed your account \"" + account.description + "\" will no longer be managed.", function (r) {
                if (r) {
                    API.delete('accounts/upgrade/' + account.account_id).success(function (data) {
                        AccountService.refresh();
                    }).error(function (data) {
                        bootbox.alert(data.error);
                    });
                }
            });

        };

        //service has this as a shared method that is keyed off the table
        $scope.getRouteKeyForId = VendorService.getRouteKeyForId;
        

        $scope.expectedReturn = function (a) {

            if(a.last_balance === null) {
                return 0;
            }

            //this part probably isn't junk since we want to use the strategy target_roi if this account is using one
            if (a.strategy != null) {
                return (a.strategy.strategy.target_roi == null) ? 0 : a.strategy.strategy.target_roi;
            }

            var idleCash = a.last_balance.cash;


            a = a.autoinvest;
            if (a.length == 0) {
                return 0;
            }

            var totalReturn = 0;
            var totalBid = 0;

            _.each(a, function (obj) {
                if (obj.enabled && (obj.minimum_idle <= idleCash)) {
                    totalBid += obj.invest_amount;
                }
                //totalReturn = obj.userfilter.meta.roi;
            });

            if (totalBid == 0) {
                return 0;
            }

            _.each(a, function (obj) {

                //console.log(_.has(obj.userfilter, 'meta'));

                if (obj.enabled && (obj.minimum_idle <= idleCash)) {
                    if (obj.userfilter == null) {
                        totalReturn += 0;
                    } else {
                        var scale = (obj.invest_amount / totalBid);
                        var roi = (obj.userfilter.meta == null) ? 0 : obj.userfilter.meta.roi;

                        totalReturn += (scale * roi);

                    }
                }

            });

            //console.log(totalReturn);
            return totalReturn;
        };

        $scope.bgColor = '#eaeaea';
        $scope.currentColor = '#ade5c0';
        $scope.gradient = false;

        $scope.getStyle = function () {
            var transform = ($scope.isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

            return {
                'top': $scope.isSemi ? 'auto' : '50%',
                'bottom': $scope.isSemi ? '5%' : 'auto',
                'left': '50%',
                'transform': transform,
                '-moz-transform': transform,
                '-webkit-transform': transform,
                'font-size': $scope.radius / 3.5 + 'px'
            };
        };


        $scope.getColor = function () {
            return $scope.gradient ? 'url(#gradient)' : $scope.currentColor;
        };


        // Load default state
        $timeout(function () {
            switch ($stateParams.subAction) {
                case 'add':
                    $scope.slideovers.account = true;
                    break;
            }
        }, 250);


        $scope.getAverageBidAmount = function (a) {
            var count = 0;
            var total = 0;
            _.each(a, function (obj) {
                total += obj.invest_amount;
                count++;
            });

            if (count == 0) {
                return;
            } else {
                return total / count;
            }
        };

        $scope.setupInvesting = function () {
            localStorage.removeItem('hideUpgrade');
            UserService.refreshState();
            $state.go("settings.profile");
        };

    }]);

nsr.controller('AccountStrategyEditController', ['$scope', 'API', 'AccountService', function ($scope, API, AccountService) {

    //SUPER HACKY!!! (don't try this at home)
    $scope.$parent.child = $scope;

    $scope.accountStrategy = null;

    $scope.editAccountStrategy = function (account) {
        $scope.editMode = true;
        if (account.strategy != null) {
            $scope.accountStrategy = angular.copy(account.strategy);
        } else {
            $scope.accountStrategy = {account_id: account.account_id};
        }
    };

    $scope.saveAccountStrategy = function (a, accountStrategy) {
        API.post('assign-strategy', accountStrategy).success(function (response) {
            $scope.cancelAccountStrategy();
            AccountService.refresh();
        });
    };

    $scope.removeAccountStrategy = function (account, accountStrategy) {
        var payload = {'account_id': accountStrategy.account_id, 'strategy_id': accountStrategy.strategy_id};

        API.post('unassign-strategy', payload).success(function (response) {
            $scope.cancelAccountStrategy();
            AccountService.refresh();
        });
    };

    $scope.cancelAccountStrategy = function () {
        $scope.accountStrategy = null;
        $scope.editMode = false;
    };




}]);

nsr.controller('AccountWebCredentialsController', ['$scope', 'API', 'UserService', '$uibModalInstance', 'ErrorHandler', 'accountId', function ($scope, API, UserService, $uibModalInstance, ErrorHandler, accountId) {
    $scope.loading = false;
    $scope.model = {
        accountId: accountId,
        login: null,
        password: null
    };

    $scope.errors = null;

    $scope.ok = function() {
        $scope.loading = true;

        API.post('accounts/web-credentials', $scope.model).then(function(response) {

            $scope.model = {
                accountId: null,
                login: null,
                password: null
            };
            $scope.loading = false;

            $uibModalInstance.close(response.data);

        }, function (response) {
            alert(angular.toJson(response.data.errors));
            $scope.loading = false;
            $scope.errors = response.data.errors;
        })

    };

    $scope.cancel = function () {
        $scope.model = {
            accountId: null,
            login: null,
            password: null
        };

        ErrorHandler.clearErrors();
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('AutoSetupController', ['$scope', 'API', 'UserService', '$uibModalInstance', 'ErrorHandler', function ($scope, API, UserService, $uibModalInstance, ErrorHandler) {
    $scope.loading = false;
    $scope.model = {
        login: null,
        password: null
    };

    $scope.errors = [];

    $scope.ok = function() {
        $scope.loading = true;

        API.post('accounts/auto-setup', $scope.model).then(function(response) {

            $scope.model = {
                login: null,
                password: null
            };
            $scope.loading = false;

            $uibModalInstance.close(response.data);

        }, function (response) {
            $scope.loading = false;
            $scope.errors = response.data.errors;
        })

    };

    $scope.cancel = function () {
        $scope.model = {
            login: null,
            password: null
        };

        ErrorHandler.clearErrors();
        $uibModalInstance.dismiss('cancel');
    };
}]);


nsr.controller('AutoInvestEditController', ['$scope', '$http', 'API', 'UserService', '$timeout', function ($scope, $http, API, UserService, $timeout) {
    $scope.editMode = false;

    $scope.edit = function (ai) {
        if (!$scope.editMode) {
            $scope.autoInvest = angular.copy(ai);
            $scope.editMode = true;
        }
    };

    $scope.cancel = function () {
        $scope.editMode = false;
    };

    $scope.deleteAutoInvest = function (autoInvestId, autoInvests) {
        bootbox.confirm("Remove Auto Invest?", function (r) {
            if (r) {
                API.delete('autoinvests/' + autoInvestId).success(function (response) {
                    var index = -1;
                    for (var i = 0; i < autoInvests.length; i++) {
                        if (autoInvestId == autoInvests[i].auto_invest_id) index = i;
                    }
                    if (index > -1) autoInvests.splice(index, 1);
                });
            }
        });
    };

    $scope.save = function (autoInvest, autoInvests) {

        API.put('autoinvests/' + autoInvest.auto_invest_id, autoInvest).success(function (response) {
            $scope.editMode = false;

            if (autoInvests != null) {
                for (var i = 0; i < autoInvests.length; i++) {
                    if (autoInvest.auto_invest_id == autoInvests[i].auto_invest_id) {
                        autoInvests[i] = response;
                    }
                }
            }

        });

    }
}]);


nsr.controller('AccountController', ['$scope', '$http', 'API', '$log', '$state', 'DataCache', 'AccountSingletonService', 'AccountService', 'ErrorHandler', '$window', '$uibModal',
    function ($scope, $http, API, $log, $state, DataCache, AccountSingletonService, AccountService, ErrorHandler, $window, $uibModal) {

    // remove Lending Club from options when add account
    $scope.vendorList = $scope.vendors.list().filter(v => v.vendor_id !== 1);

    $scope.enroll = false;

    /**
     * ACCOUNT MANAGER
     */

        // http://stackoverflow.com/questions/3291712/is-it-possible-to-open-a-popup-with-javascript-and-then-detect-when-the-user-clo
    $scope.oauth = function (url, title, w, h) {

        $window.open(url, "_self");
        return;
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

        width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var oauth = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (!oauth || oauth.closed || typeof oauth.closed == 'undefined') {
            bootbox.alert("Popups are disabled in your browser. Check enable them in the upper right-hand corner to complete account enrollment.");
            return;
        }

        if (window.focus) {
            oauth.focus();
        }

        var pollTimer = window.setInterval(function () {
            if (oauth.closed !== false) { // !== is required for compatibility with Opera
                window.clearInterval(pollTimer);
                bootbox.alert("Account Saved");
            }
        }, 200);
    };

    function clearAccount() {
        $scope.edit = false;
        $scope.rules = {};
        $scope.account = {
            vendor_id: 2,
            account_status_id: 0, // Un-provisioned,
            enabled: true,
            managementStyle: 0
        };
    }

    clearAccount();

    $scope.load = function (accountId) {
        $scope.edit = true;

        var result = API.get('accounts/' + accountId);
        result.success(function (response) {
            $scope.account = response.data;
            $scope.validationRules();
        });
    };

    $scope.save = function () {
        //the password field is hidden when it isn't required, but this doesn't stop the browser from trying to
        // autocomplete it if the user has saved their password to our site.
        // We manually null it out to prevent the autocompleted password from getting sent back to the server
        if (!$scope.ruleKeyExists('password')) {
            $scope.account.password = null;
        }

        if ($scope.edit) {
            AccountService.edit($scope.account).success(function(response) {
                if (_.has(response, 'oauth') && ($scope.account.account_status_id != 1 || $scope.enroll)) { // 1 = ok
                    $scope.oauth(response.oauth, response.title, 500, 700);
                } else {
                    $scope.$parent.slideovers.account = false;
                }
            });
        } else {
            AccountService.create($scope.account).success(function (response) {
                if (_.has(response, 'oauth')) {
                    $scope.oauth(response.oauth, response.title, 500, 700);
                } else {
                    $scope.$parent.slideovers.account = false;
                }
            });
        }
    };

    $scope.delete = function () {

    };

    $scope.validationRules = function () {
        if (!$scope.account.vendor_id) {
            bootbox.alert("Please select a Marketplace Lender to proceed.");
            $scope.rules = {};
            return;
        }

        API.get('vendors/rules/' + $scope.account.vendor_id).then(function (rules) {
            $scope.rules = rules.data;
        });
    };

    $scope.ruleKeyExists = function (key) {
        return key in $scope.rules;
    };

    $scope.sync = function (accountId) {
        AccountService.sync(accountId);
    };

    $scope.$watch(function () {
        return AccountSingletonService.accountId
    }, function (n, o) {

        if (n != null && o != n) {
            $scope.load(n);
        }

        if (n === null) { // new Account
            clearAccount();
        }
    });


        $scope.autoSetup = function () {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '_auto_setup.html',
                controller: 'AutoSetupController',
                size: 'sm'
            });

            modalInstance.result.then(function (account) {
                $scope.account.aid = account.aid;
                $scope.account.api_key = account.api_key;
                $scope.account.description = account.label;
                $scope.account.login = account.login;
                $scope.account.account_type_id = account.account_type_id;
            }, function () {

            });
        };

        $scope.deleteWebCredentials =function(account) {
            $scope.transition.showToastUntilCompleted('deleting web credentials',
                API.delete('accounts/web-credentials/' + account.account_id )
                    .success(function () { account.has_web_credentials = false; })
                    .error(function (data) { bootbox.alert(data); })
            );
        };

        $scope.editWebCredentials = function (account) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '_web_credentials.html',
                controller: 'AccountWebCredentialsController',
                size: 'sm',
                resolve: {
                    accountId: function () {
                        return account.account_id;
                    }
                }
            });

            modalInstance.result.then(function () {
                account.has_web_credentials = true;
            }, function () {

            });
        };
    }]);


nsr.controller('AllocationController', ['$scope', 'API', '$timeout', 'AccountSingletonService', function ($scope, API, $timeout, AccountSingletonService) {
    /*******
     * BALANCER
     */


    $scope.description = "";

    $scope.slider = {
        options: {
            floor: 5,
            ceil: 100,
            step: 1
        }
    };


    $scope.$watch(function () {
        return AccountSingletonService.accountId
    }, function (n, o) {
        $scope.description = AccountSingletonService.description;

        $timeout(function () {
            $scope.$broadcast('rzSliderForceRender');
        });

    });


    $scope.balancerDefinitions = {};

    API.get('allocation/definitions').success(function (data) {
        $scope.balancerDefinitions = data;
    });

    $scope.balancerInstances = {};


    $scope.refreshInstances = function () {
        API.get('allocation/instances').success(function (data) {
            $scope.balancerInstances = data;
        });
    };
    $scope.refreshInstances();

    $scope.getBalancerDefinitionsForVendor = function () {
        var defs = {};
        if (AccountSingletonService.vendorId == null) {
            return defs;
        }

        for (var id in $scope.balancerDefinitions) {
            if ($scope.balancerDefinitions[id].vendor_id == AccountSingletonService.vendorId) {
                defs[id] = $scope.balancerDefinitions[id];
            }
        }
        return defs;
    };

    $scope.getBalancerButtonDescription = function (account_id) {
        var balancer = $scope.balancerInstances[account_id];
        if (balancer == null) {
            return "Create Allocation Balancer";
        } else {
            var def = $scope.balancerDefinitions[balancer.balancer_def_id];
            def = (def == null) ? "" : " (" + def.description + ")";
            return "Edit Balancer" + def;
        }
    };


    $scope.balancer = {instance: null, definition: null};

    $scope.configureNewBalancer = function (account) {
        if ($scope.balancer.definition == null) return null;

        var balancerInstance = {
            balancer_def_id: $scope.balancer.definition.balancer_def_id,
            account_id: AccountSingletonService.accountId,
            allocations: {},
            type: 0
        };
        var count = 0;
        for (var counterId in $scope.balancer.definition.allocations) {
            count++;
        }

        var increment = Math.floor(100 / count);
        var delta = 100 - (increment * count);
        for (var id in $scope.balancer.definition.allocations) {
            balancerInstance.allocations[id] = {percent: increment};
            if (delta > 0) {
                balancerInstance.allocations[id].percent++;
                delta--;
            }
        }

        if ($scope.balancer.instance != null) {
            balancerInstance.monthly_volume_estimate = $scope.balancer.instance.monthly_volume_estimate;
        }
        $scope.balancer.instance = balancerInstance;
        buildChart($scope.balancer.definition, $scope.balancer.instance);
    };


    $scope.saveBalancer = function () {

        API.post('allocation',
            $scope.balancer.instance
        ).success(function (data, status, headers, config) {
            $scope.balancerInstances = data;
            $scope.balancer.instance = $scope.balancerInstances[$scope.balancer.instance.account_id];
            bootbox.alert("Allocation Balancer Saved.");
        }).error(function (data, status, headers, config) {
            bootbox.alert(data);
        });
    };

    $scope.deleteBalancer = function () {
        bootbox.confirm("Delete this Allocation Balancer?", function (result) {
            if (result) {
                $http({method: 'POST', url: 'balancer/delete', data: $scope.balancer.instance})
                    .success(function (data, status, headers, config) {
                        $scope.balancerInstances = data;
                        $scope.balancer.instance = null;
                        bootbox.alert("Allocation Balancer Deleted.");
                    }).error(function (data, status, headers, config) {
                    bootbox.alert(data);
                });
            }
        });
    };


    var chartOptions = {
        chart: {plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
        tooltip: {pointFormat: '<b>{point.percentage:.1f}%</b>'},
        plotOptions: {
            pie: {
                animation: false, allowPointSelect: true, cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    style: {color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'}
                }
            }
        },
        series: [{type: 'pie', data: [["Loading", 100]]}]
    };

    var generateChartData = function (balancerDefinition, balancerInstance) {
        var data = [];
        var total = 0;
        for (var id in balancerInstance.allocations) {
            var percent = parseFloat(balancerInstance.allocations[id].percent);
            //if (percent != 0) {
            data.push([balancerDefinition.allocations[id], percent]);
            //}
            total += percent;
        }
        $scope.balancer.totalAllocations = total;
        if (data.length == 0) {
            return [['No Allocations Yet', 100]];
        }
        return data;
    };

    var buildChart = function (balancerDefinition, balancerInstance) {
        $timeout(function () {
            var thisChartOptions = angular.copy(chartOptions);
            thisChartOptions.series[0].data = generateChartData(balancerDefinition, balancerInstance);
            thisChartOptions.title = {text: 'Target Allocations'};
            var chartDiv = $("#balancer_chart_" + balancerInstance.account_id);
            chartDiv.highcharts(thisChartOptions);
        }, 1000);
        buildAllocationsChart(null, null);
    };

    var buildAllocationsChart = function (newType, oldType) {
        if (oldType != null && oldType == newType) return;
        if ($scope.balancer.instance == null || $scope.balancer.definition == null) return;

        var balancerDefinition = $scope.balancer.definition;
        var balancerInstance = $scope.balancer.instance;

        var chartDiv = $("#balancer_current_chart_" + balancerInstance.account_id);
        chartDiv.html('<img class="img-responsive center-block" src="img/select2-spinner.gif">');

        //new future balancer allocations have no data to show
        if (balancerInstance.balancer_id == null && balancerInstance.balancer_type == 0) {
            var thisChartOptions = angular.copy(chartOptions);
            thisChartOptions.series[0].data = [['No Allocations', 0]];
            thisChartOptions.title = {text: 'No Current Allocations'};
            chartDiv.highcharts(thisChartOptions);
            return;
        }

        API.post('allocation/calc', {
            balancer_def_id: balancerDefinition.balancer_def_id,
            vendor_id: balancerDefinition.vendor_id,
            balancer_type: balancerInstance.type,
            balancer_id: balancerInstance.balancer_id,
            account_id: balancerInstance.account_id
        }).success(function (data, status, headers, config) {
            //need to align the allocation headers so that colors will match up
            // (i.e. color of B in target is the same as the color of B in current)
            //$log.debug(data);
            //var targetData = generateChartData(balancerDefinition, balancerInstance);
            //for (var i = 0 ; i < targetData.length ; i++) {
            //    var found = false;
            //    for(var j = i; j < data.length ; j++) {
            //        if (targetData[i][0] == data[j][0]) {
            //            found = true;
            //            if (i == j) break;   //in the right spot
            //
            //            //swap it into the right spot
            //            var tempSwap = data[i];
            //            data[i] = data[j];
            //            data[j] = tempSwap;
            //            break;
            //        }
            //    }
            //    if (!found) {   //add in a 0% holder if not target element found
            //        data.splice(i,0,[targetData[i][0], 0]);
            //    }
            //}

            var thisChartOptions = angular.copy(chartOptions);
            thisChartOptions.series[0].data = data;
            thisChartOptions.title = {text: (balancerInstance.type == 0) ? 'Allocations Since Creation' : 'Current Allocations'};
            chartDiv.highcharts(thisChartOptions);
        }).error(function (data, status, headers, config) {
            chartDiv.html('Error Loading Current Allocations');
            bootbox.alert(data);
        });
    };

    var updateAllocationsAndRedrawChart = function (newAllocations, oldAllocations) {


        //see which one changed
        if (oldAllocations != null && oldAllocations !== $scope.balancer.instance.allocations) {
            newAllocations = $scope.balancer.instance.allocations;
            var changed = null;
            var total = 0;
            var count = 0;
            var id;
            for (id in newAllocations) {
                if (oldAllocations[id] == null) return; //new balancer def, so these won't line up

                var newPercent = parseFloat(newAllocations[id].percent);
                if (newPercent != Math.round(newPercent)) {
                    newPercent = Math.round(newPercent);
                    newAllocations[id].percent = newPercent;
                }

                if (parseFloat(oldAllocations[id].percent) != newPercent) {
                    changed = newAllocations[id];
                }

                total += newPercent;
                count++;
            }

            //if we are over 100%, then keep decrementing everything by 1 until we get to 100%
            if (changed != null && total > 100 && count > 1) {
                while (total > 100) {
                    for (id in newAllocations) {
                        if (newAllocations[id] !== changed && parseFloat($scope.balancer.instance.allocations[id].percent) >= 1) {
                            $scope.balancer.instance.allocations[id].percent -= 1;
                            total -= 1;
                        }
                        if (total <= 100) break;
                    }
                }
            }
        }

        if ($scope.balancer.definition != null && $scope.balancer.instance != null) {
            var chart = $("#balancer_chart_" + $scope.balancer.instance.account_id);
            if (chart) chart = chart.highcharts();
            if (chart) chart.series[0].setData(generateChartData($scope.balancer.definition, $scope.balancer.instance));
        }
    };

    /*
     $scope.$watch('balancer.instance.allocations', updateAllocationsAndRedrawChart, true);
     $scope.$watch('balancer.instance.type', buildAllocationsChart, true);
     */

    $scope.toBool = function (v) {
        return Boolean(+v);
    };
}]);


nsr.controller('AccountProvisionController', ['$scope', 'API', '$state', 'UserService', 'ErrorHandler', 'AccountService', function ($scope, API, $state, UserService, ErrorHandler, AccountService) {

    $scope.account = {managementStyle: 0};

    UserService.getProfile().then(function (profile) {
        $scope.provision = {
            address: profile.extended.address1,
            city: profile.extended.city,
            state: profile.extended.state,
            phone: profile.extended.phone,
            dob: profile.extended.dob,
            zip: profile.extended.zip,
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,

        };
    });

    $scope.create = function () {
        if ($scope.ssn != $scope.ssn_confirm) {
            bootbox.alert("SSN and SSN Confirm do not match");
        } else {
            $scope.provision.managementStyle = $scope.account.managementStyle;

            $scope.transition.showToastUntilCompleted(
            API.post('accounts/provision', $scope.provision).then(
                function (response) {
                    $scope.transition.flagInProgressUntilCompleted(AccountService.refresh());
                    // $scope.slideovers.provision = false;

                    var a = response.data;
                    bootbox.alert("Account successfully created. Next, please link a bank account and transfer funds for investing to begin.", function() {
                        $scope.banking(a.account_id, a.vendor_id);
                    });
                },
                function (response) {
                    $scope.slideovers.provision = true;

                    bootbox.dialog({
                        message: ErrorHandler.format(),
                        title: 'Error Saving Account',
                        buttons: {
                            main: {
                                label: "Ok",
                                className: "btn-primary",
                                callback: function () {
                                    ErrorHandler.clearErrors();
                                }
                            }
                        }
                    });

                    $scope.transition.flagInProgressUntilCompleted(AccountService.refresh());
                }), "Creating account...");
            $scope.slideovers.provision = false;
        }
    }
}]);


nsr.factory('StrategyGroupService', ['$log', 'API', function ($log, API) {
    return {
        editCallback: function (groupToEdit) {
        },
        editRequest: function (groupToEdit) {
            this.editCallback(groupToEdit);
        }
    }
}]);

nsr.controller('StrategyGroupController', ['$scope', '$http', 'API', '$filter', 'StrategyGroupService', function ($scope, $http, API, $filter, StrategyGroupService) {
    $scope.slideovers = {edit: false};

    $scope.groups = [];

    function sortAutoInvests(group) {
        group.auto_invests = $filter('orderBy')(group.auto_invests, ['+minimum_idle', '-invest_amount', '+userfilter.name']);
        //console.log(group.auto_invests);
        return group;
    }

    $scope.load = function () {
        return $scope.transition.showToastUntilCompleted(
            API.get('strategy-groups').success(function (response) {
                $scope.groups = response;
                _.each($scope.groups, sortAutoInvests);  //sort to make the UI more logical and consistent
            }), 'Loading Strategy Groups');
    };

    $scope.transition.flagInProgressUntilCompleted($scope.load());

    $scope.openEditor = function (groupToEdit) {
        StrategyGroupService.editRequest(groupToEdit);
        $scope.slideovers.edit = true;
    };

    $scope.create = function (group, autoInvest) {

        var payload = {
            strategy_id: group.strategy_id,
            user_filter_id: autoInvest.user_filter_id,
            enabled: (autoInvest.enabled == null) ? false : autoInvest.enabled,
            invest_amount: autoInvest.invest_amount,
            minimum_idle: autoInvest.minimum_idle
        };

        console.log(angular.toJson(autoInvest));
        console.log(angular.toJson(payload));

        API.post('autoinvests', payload).success(function (response) {
            group.auto_invests.push(response);
            sortAutoInvests(group);
            group.add = false;
        });
    };

    $scope.asPercentage = function (number) {
        return (number == null || number == 0) ? 'N/A' : $filter('number')(number * 100, 1) + '%';
    }

}]);


nsr.controller('StrategyGroupEditController', ['$scope', '$http', 'API', '$log', 'UserService', 'StrategyGroupService', function ($scope, $http, API, $log, UserService, StrategyGroupService) {

    $scope.group = {};

    StrategyGroupService.editCallback = function (groupToEdit) {
        $scope.group = (groupToEdit == null) ? {} : angular.copy(groupToEdit);
    };

    $scope.isEditMode = function () {
        return ($scope.group.strategy_id != null);
    };

    $scope.save = function () {
        var apiCall = ($scope.isEditMode()) ?
            API.put('strategy-groups/' + $scope.group.strategy_id, $scope.group)
            : API.post('strategy-groups', $scope.group);

        apiCall.success(function (response) {
            $scope.$parent.slideovers.edit = false;
            $scope.$parent.load();
        });

    };

    $scope.delete = function (group) {
        bootbox.confirm("Are you sure you want to remove the strategy group: " + group.name + "?", function (r) {
            if (r) {
                API.delete('strategy-groups/' + group.strategy_id, group).success(function (response) {
                    bootbox.alert("Strategy deleted.");
                    $scope.$parent.slideovers.edit = false;
                    $scope.$parent.load();
                });
            }
        });
    };
}]);
