nsr.controller('ValuationController', ['$scope', 'API', 'UserService', function($scope, API, UserService) {

    $scope.account = null;
    $scope.allAccounts = [];

    $scope.valuations = {
        arcstone: null,
        folio: null
    };

    UserService.getAccounts().then(function (accounts) {
        $scope.allAccounts = accounts;
    });



    $scope.folio = function() {
        API.get('valuation/folio/' + $scope.account.account_id).then(function(response) {
            $scope.valuations.folio = response.data.value;
        });
    };

    $scope.arcstone = function() {
        API.get('valuation/arcstone/' + $scope.account.account_id).then(function(response) {
            $scope.valuations.arcstone =  response.data.value;
        });
    };

    $scope.accountChange = function() {
        $scope.arcstone();
        $scope.folio();
    }

}]);
