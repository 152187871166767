nsr.controller('DashboardController', ['$scope', 'API', '$timeout', 'UserService', 'ChartDefinitions', function ($scope, API, $timeout, UserService, ChartDefinitions) {

    $scope.pill = {
        invested: 0,
        growth: 0,
        available: 0,
        projected: 0
    };

    $scope.model = {
        account: {}
    };

    $scope.accountId = null;
    $scope.breakdownType = 'grade';

    $scope.monthlyGainChart = true;

    $scope.allAccounts = [];
    $scope.cashflowData = [];




    UserService.getDashboardData().then(function (dashboardData) {
        $scope.allAccounts = dashboardData;

        var idx = 0;

        if(!_.isUndefined(localStorage.getItem('defaultAccountId'))) {
            _.find($scope.allAccounts, function (obj, aidx) {
                if (obj.account_id == localStorage.getItem('defaultAccountId')) {
                    idx = aidx;
                    return;
                }
            });
        }

        $scope.model.account = $scope.allAccounts[idx]; // select first account
        $scope.update();
    });

    $scope.platform = function(account) {

        return (account.platform == 'All') ? undefined : account.platform;
    };


    $scope.setDefaultAccount = function(accountId) {
        localStorage.setItem('defaultAccountId', accountId);
    };



    ChartDefinitions.initHighChartsDashboardTheme();

    $scope.balanceChart = ChartDefinitions.getBalanceChartDefinition();

    $scope.cashflowChart = ChartDefinitions.getCashflowChartDefinition();

    $scope.monthlyGain = ChartDefinitions.getPerformanceChartDefinition();
    $scope.totalGain = ChartDefinitions.getPerformanceChartDefinition();


    $scope.hasAccounts = function() {
        return $scope.allAccounts.length !== 0;
    };

    $scope.isVintAccount = function () {
        return $scope.model.account !== undefined && $scope.model.account.platform === 'Vint';
    }

    function updateCashFlowChart(cashflow) {
        $scope.cashflowData = cashflow;

        //not sure why these chart refreshes are in $timeout calls, but i am following suit
        $timeout(function() {
            /*
            $scope.cashflowChart.series[0] = {
                name: 'TOTAL',
                data: _.map(cashflow, function (obj) {
                    return [obj.key, +(obj.interest + obj.principal).toFixed(2)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#3AB65B',
                yAxis: 0

            };*/

            $scope.cashflowChart.series[1] = {
                name: 'INTEREST',
                data: _.map(cashflow, function (obj) {
                    return [obj.key, +parseFloat(obj.interest).toFixed(2)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#F4604D',
                yAxis: 0,
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_click', 'INTEREST');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_mouseOut', 'INTEREST');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_mouseOver', 'INTEREST');
                    },
                }
            };

            $scope.cashflowChart.series[0] = {
                name: 'PRINCIPAL',
                data: _.map(cashflow, function (obj) {
                    return [obj.key, +parseFloat(obj.principal).toFixed(2)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#6d8fd9',
                yAxis: 0,
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_click', 'PRINCIPAL');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_mouseOut', 'PRINCIPAL');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'cashflow_chart_mouseOver', 'PRINCIPAL');
                    },
                }
            };



            // outstanding principal
            /*
            $scope.cashflowChart.series[3] = {
                name: 'OUTSTANDING PRINCIPAL',
                data: _.map(cashflow, function (obj) {
                    return [obj.key, +parseFloat(obj.outstanding_principal).toFixed(2)];
                }),
                marker: { radius: 3 },
                yAxis: 1,
                dashStyle: 'dash',
                color: '#cddff7'
            };*/
        });


    }


    $scope.get6MonthProjectedGrowth = function() {
        if ($scope.model && $scope.model.account && $scope.model.account.portfolio && $scope.model.account.balance) {
            if ($scope.model.account.portfolio.meta.total.age < 8) {
                //account is to young
                return null;
            }
            return $scope.model.account.balance.outstanding_principal * ( $scope.model.account.portfolio.roi / 2);
        }
        return null;
    };

    $scope.cashflowReinvest = false;

    $scope.updateCashflow = function(changeRadioEvent = true) {
        // Get cashflows - we broke this out because it's time consuming
        $scope.cashflowData = [];
        if($scope.model.account.account_id != null) {
            if (changeRadioEvent) {
                window.ga('send', 'event', 'dashboard',
                    $scope.cashflowReinvest ? 'cashflow_reinvestment_click' : 'cashflow_no_reinvestment_click');
            }
            UserService.getCashFlow($scope.model.account.account_id, 'monthly', $scope.cashflowReinvest).then(updateCashFlowChart);
        }
    };

    const timers = {};

    $scope.hoverIn = function (label) {
        timers[label] = new Date();
    }

    $scope.hoverOut = function (label, balance = false) {
        if (new Date() - timers[label] > 1000) {
            window.ga('send', 'event', 'dashboard',
                balance ? 'balance_indicators_hover' : 'profitability_indicators_hover', label);
        }
    }

    $scope.switching = function (switcher, label) {
        window.ga('send', 'event', 'dashboard', 'switching ' + switcher, label);
    }

    $scope.reinvestDetailsShow = function () {
        window.ga('send', 'event', 'dashboard', 'click_cashflow_details_show_button');
        $scope.showReinvestTable = !$scope.showReinvestTable;
    }

    $scope.accountHistoryClick = function () {
        window.ga('send', 'event', 'dashboard', 'account_history_click');
    }

    $scope.update = function () {
        window.ga('send', 'event', 'dashboard', 'change_accounts', new Date());
        if (!$scope.hasAccounts()) {
            return;
        }

        // Get cashflows - we broke this out because it's time consuming
        $scope.updateCashflow(false);




        $timeout(function() {

            // Setup Pill
            var projected = $scope.get6MonthProjectedGrowth();
            if (projected == null) projected = 0;

            var total = $scope.model.account.balance.outstanding_principal + $scope.model.account.balance.cash + $scope.model.account.balance.pending + projected;

            
            $scope.pill.invested = Math.round(10000 * $scope.model.account.balance.outstanding_principal / total) / 100;
            $scope.pill.growth = Math.round(10000 * $scope.model.account.performance.gain / total) / 100;
            $scope.pill.available = Math.round(10000 * $scope.model.account.balance.cash / total) / 100;
            $scope.pill.pending = Math.round(10000 * $scope.model.account.balance.pending / total) / 100;
            $scope.pill.projected = Math.round(10000 * projected / total) / 100 ;
            $scope.pill.projectedValue = projected;


            $scope.balanceChart.xAxis.categories = _.pluck($scope.model.account.history, 'month');

            $scope.balanceChart.series[0] = {
                name: 'CASH',
                data: _.map($scope.model.account.charts.history, function (obj) {
                    return [obj.month + ' ' + obj.year, parseFloat(obj.cash)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#F4604D',
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_click', 'cash');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOut', 'cash');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOver', 'cash');
                    },
                }
            };

            $scope.balanceChart.series[1] = {
                name: 'ACCOUNT TOTAL',
                data: _.map($scope.model.account.charts.history, function (obj) {
                    return [obj.month + ' ' + obj.year, parseFloat(obj.account_total)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#6d8fd9',
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_click', 'account_total');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOut', 'account_total');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOver', 'account_total');
                    },
                }
            };

            $scope.balanceChart.series[2] = {
                name: 'MANAGED PRINCIPAL',
                data: _.map($scope.model.account.charts.history, function (obj) {
                    return [obj.month + ' ' + obj.year, parseFloat(obj.managed_principal)];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#cddff7',
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_click', 'managed_principal');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOut', 'managed_principal');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'chart_mouseOver', 'managed_principal');
                    },
                }
            };



            $scope.monthlyGain.series[0] = {
                name: 'MONTHLY GAIN',
                data: _.map($scope.model.account.charts.monthly_returns, function (obj) {
                    if(obj.return == -1) {
                        return [obj.month, 0];
                    }
                    return [obj.month+ ' ' + obj.year, obj.return * 100];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#6d8fd9',
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'monthly_chart_click', 'monthlyGain');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'monthly_chart_mouseOut', 'monthlyGain');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'monthly_chart_mouseOver', 'monthlyGain');
                    },
                }
            };


            $scope.totalGain.series[0] = {
                name: 'CUMULATIVE GAIN',
                data: _.map($scope.model.account.charts.total_returns, function (obj) {

                    if(obj.return == -1) {
                        return [obj.month, 0];
                    }
                    return [obj.month+ ' ' + obj.year, obj.return * 100];
                }),
                marker: { radius: 3 },
                dashStyle: 'solid',
                color: '#6d8fd9',
                events: {
                    click: function () {
                        window.ga('send', 'event', 'dashboard', 'cumulative_chart_click', 'totalGain');
                    },
                    mouseOut: function () {
                        window.ga('send', 'event', 'dashboard', 'cumulative_chart_mouseOut', 'totalGain');
                    },
                    mouseOver: function () {
                        window.ga('send', 'event', 'dashboard', 'cumulative_chart_mouseOver', 'totalGain');
                    },
                }
            };
        });

    };

}]);
