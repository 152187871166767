/**
 * Define the High Chart views
 */
nsr.factory('ChartDefinitions', [function () {

    return {
        initHighChartsDashboardTheme: function () {
            // @todo move this somewhere else!
            Highcharts.theme = {
                colors: ['#356493', '#DBD56E', '#88AB75', '#7D7C84', '#DE8F6E', '#ddaa00', '#6655cc', '#99aa00'],
                plotOptions: {
                    areaspline: {
                        marker: {
                            enabled: true,
                            lineWidth: 2,
                            radius: 5,
                            fillColor: null,
                            lineColor: null,
                            symbol: 'circle',
                            states: {
                                hover: {
                                    lineWidth: 3,
                                    radius: 6
                                }
                            }
                        },
                        fillOpacity: 0.05
                    },

                    bar: {
                        pointWidth: 3
                    },
                    column: {
                        pointWidth: 5
                    }
                },
                chart: {
                    style: {
                        fontFamily: 'NSR'
                        //fontWeight: 'normal'
                    }
                },
                xAxis: {
                    lineColor: '#ccc'
                },
                yAxis: {
                    gridLineColor: '#e0e0e0',
                    labels: {
                        enabled: true
                    }
                    //min: 0
                },
                credits: false,
                legend: {
                    borderRadius: 0,
                    borderWidth: 0,
                    align: 'center',
                    verticalAlign: 'top',
                    floating: false,
                    y: 0
                },
                tooltip: {
                    backgroundColor: 'black',
                    style: {
                        color: 'white'
                    }
                }

            };

            // Apply the theme
            Highcharts.setOptions(Highcharts.theme);
        },

        getBalanceChartDefinition: function () {
            return {
                options: {
                    chart: {
                        type: 'areaspline'
                    },
                    tooltip: {
                        style: {
                            padding: 10,

                        }
                    }
                },
                series: [],
                title: {
                    text: ''
                },
                loading: false,
                xAxis: {
                    categories: [],
                    labels: {
                        align: 'center',

                    },
                    //tickInterval: 1,
                    /*
                     minPadding: 0.1,
                     maxPadding: 0.1,
                     min: 0.4,                     // remove padding
                     max: null,
                     */
                    //startOnTick: false,           // allow to start not from tick
                    //endOnTick: false
                },
                yAxis: {
                    min: 0,
                    title: {
                        enabled: null,
                        text: ""
                    }
                },
                useHighStocks: false,

                size: {
                    //width: 'auto',
                    height: 300
                }
            };
        },

        getCashflowChartDefinition: function () {
            return {
                options: {
                    chart: {
                        type: 'column'
                    },
                    tooltip: {
                        style: {
                            padding: 10,
                            fontWeight: 'bold'
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                            }
                        }
                    },
                },
                series: [],
                //Title configuration (optional)
                title: {
                    text: ''
                },
                loading: false,
                xAxis: {
                    categories: [],
                    labels: {
                        align: 'center',

                    },
                    plotBands: [{
                        color: 'rgba(229,229,229, 0.2)', // Color value
                        from: -0.5, // Start of the plot band
                        to: 0.5, // End of the plot band
                        label: {
                            text: 'Remaining', // Content of the label.
                            align: 'left', // Positioning of the label.
                            x: 10 // Amount of pixels the label will be repositioned according to the alignment.
                        }
                    }],
                },
                yAxis: [
                    {
                        title: {
                            //enabled: null,
                            text: "Principal + Interest Payments"
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                            }
                        }
                    }
                ],
                useHighStocks: false,


                size: {
                    //width: 'auto',
                    height: 300
                }


            };
        },

        getPerformanceChartDefinition: function () {
            return {
                options: {
                    chart: {
                        type: 'column'
                    },
                    tooltip: {
                        style: {
                            padding: 10,
                            fontWeight: 'bold'
                        },
                        enabled: true,
                        formatter: function () {
                            return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + Highcharts.numberFormat((this.y), 2, '.') + '%</b>';
                        }
                    }
                },
                series: [],
                //Title configuration (optional)
                title: {
                    text: ''
                },
                loading: false,
                xAxis: {
                    categories: [],
                    labels: {
                        align: 'center',

                    }
                },

                yAxis: {
                    title: {
                        enabled: null,
                        text: ""
                    }
                },
                useHighStocks: false,

                size: {
                    //width: 'auto',
                    height: 300
                }
            };
        },
        getBalanceDetailedChartDefinition: function () {
            return {
                options: {
                    chart: {
                        zoomType: 'x',
                        style: {
                            fontFamily: 'NSR'
                            //fontWeight: 'normal'
                        }
                    },
                    rangeSelector: {
                        enabled: true
                    },
                    navigator: {
                        enabled: true
                    },
                    legend: {
                        enabled: true,
                        borderRadius: 0,
                        borderWidth: 0,
                        align: 'center',
                        verticalAlign: 'top',
                        floating: false,
                        y: 0
                    },
                    credits: false

                },

                //The below properties are watched separately for changes.

                //Series object (optional) - a list of series using normal highcharts series options.
                series: [],
                //Title configuration (optional)

                //Boolean to control showng loading status on chart (optional)
                //Could be a string if you want to show specific loading text.
                loading: false,
                useHighStocks: true,
                //size (optional) if left out the chart will default to size of the div or something sensible.
                size: {
                    //width: 400,
                    height: 400
                }
            };
        },
        getBenchmarkDefinition: function () {
            return {
                options: {
                    chart: {
                        type: 'line',
                        zoomType: 'x',
                        style: {
                            fontFamily: 'NSR'
                            //fontWeight: 'normal'
                        }
                    },
                    rangeSelector: {
                        enabled: true
                    },
                    navigator: {
                        enabled: true
                    },
                    legend: {
                        enabled: true,
                        borderRadius: 0,
                        borderWidth: 0,
                        align: 'center',
                        verticalAlign: 'top',
                        floating: false,
                        y: 0
                    },
                    credits: false

                },

                series: [],

                yAxis: {
                    plotBands: [{
                        from: 0,
                        to: 100,
                        color: 'rgba(178,255,102, 0.2)'
                    },{
                        from: 0,
                        to: -100,
                        color: 'rgba(255,153,153, 0.2)'
                    }]
                },
                xAxis: {
                  plotBands: [
                      {
                          from : moment("2007-12-01").unix() * 1000,
                          to: moment("2009-06-01").unix() * 1000,
                          color: 'rgba(255,153,255, 0.2)'
                      }
                  ]
                },
                //Could be a string if you want to show specific loading text.
                loading: 'Loading...',
                useHighStocks: true,
                size: {
                    //width: 400,
                    height: 400
                }
            };
        }
    }
}]);

