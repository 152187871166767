nsr.controller('AdvisorController', ['$scope', 'UserService', '$state', '$stateParams', 'API', '$uibModal', function($scope, UserService, $state, $stateParams, API, $uibModal) {
    $scope.clients = [];
    $scope.unassigned = [];

    $scope.losses = {};

    $scope.load = function() {
        API.get('advisor/clients').success(function (response) {
            $scope.clients = response;
        });

        API.get('advisor/unassigned').success(function (response) {
            $scope.unassigned = response;
        });
    };

    $scope.assign = function(clientId, accountId) {
        API.post('advisor/assign', {
            account_id : accountId,
            client_id: clientId
        }).success(function(response) {
            $scope.load();
        });
    };

    $scope.load();


    API.get('advisor').then(function(response) {
        $scope.firm = response.data;
    });

    $scope.save = function() {
        API.put('advisor', $scope.firm).then(function(response) {

        });
    };


    $scope.lossUpdate = function() {
        API.put('advisor/loss-factors', $scope.losses).then(function(response) {

        });
    };


    $scope.addClient = function () {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '_advisor-client-add.html',
            controller: 'AdvisorClientController',
            size: 'sm',
            resolve: {
                client: null
            }
        });

        modalInstance.result.then(function (client) {
            $scope.load();
        }, function () {
            // dismissed
        });
    };

    $scope.editClient = function (client) {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '_advisor-client-edit.html',
            controller: 'AdvisorClientController',
            size: 'sm',
            resolve: {
                client: function () {
                    return client;
                }
            }

        });

        modalInstance.result.then(function (client) {
            $scope.load();
        }, function () {
            // dismissed
        });
    };

    $scope.deleteClient = function(client) {
        bootbox.confirm("Remove client?", function(r) {
           if(r) {
               API.delete('advisor/client/' + client.client_id).then(function(response) {
                   $scope.load();
               });

           }
        });
    };

    $scope.unlink = function(account) {
        bootbox.confirm("This action will unlink the account from the client. The account will not be removed.", function(r) {
            if(r) {

                API.delete('advisor/client/account/' + account.account_id).then(function(response) {
                    $scope.load();
                });

            }
        });

    };


}]);


nsr.controller('AdvisorClientController', ['$scope', 'UserService', '$state', '$stateParams', 'API', '$uibModalInstance', 'client', function($scope, UserService, $state, $stateParams, API, $uibModalInstance, client) {

    if(client) {
        $scope.client = angular.copy(client);
    } else {
        $scope.client = {};
    }

    $scope.errors = {};

    $scope.create = function () {

        API.post('advisor/clients', $scope.client).then(function(response) {
            $uibModalInstance.close($scope.client);
        }, function(errors) {
            console.log(errors);
            $scope.errors = errors.data;
        });

    };

    $scope.update = function () {

        API.put('advisor/clients/' + $scope.client.client_id , $scope.client).then(function(response) {
            $uibModalInstance.close($scope.client);
        }, function(errors) {
            console.log(errors);
            $scope.errors = errors.data;
        });

    };

    $scope.getErrors = function(name) {
          if(!_.isUndefined($scope.errors[name])) {
              return $scope.errors[name][0];
          }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
