nsr.factory('SearchConnector', ['$log', '$timeout', '$q', 'StatsService', 'FilterService', 'VendorService', function ($log, $timeout, $q, StatsService, FilterService, VendorService) {
    var connector = {
        vendorId: 0,

        showSecondaryFilters: false,
        showPortfolio: true,

        showPrimaryCount: true,
        showSecondaryCount: true,

        portfolio: null,

        results: {}
    };

    connector.getRouteKeyForId = VendorService.getRouteKeyForId;

    connector.setVendorCallBack = function(vendorId) {};
    connector.realFilterMethod = null;

    connector.setVendorAndLoadStatsHelper = function(route_key) {


        var previousVendorId = connector.vendorId;
        var vendor = VendorService.getByRouteKey(route_key);

        if (vendor.vendor_id == null) {
            connector.vendorId = previousVendorId;
            return connector.statsHelper;
        }

        connector.vendorId = vendor.vendor_id;

        if (previousVendorId != connector.vendorId) {
            FilterService.reset();
        }

        connector.statsHelper = StatsService.getStatsHelper(connector.vendorId);
        connector.setVendorCallBack(connector.vendorId);
        return connector.statsHelper;
    };

    connector.setResults = function(secondaryMatches, primaryMatches) {
        connector.results = {};
        if (primaryMatches !== null) connector.results.primaryMatches = primaryMatches;
        if (secondaryMatches !== null) connector.results.secondaryMatches = secondaryMatches;
    };



    connector.getFilter = function() {
        var filterRequest = angular.copy(connector.statsHelper.results);
        filterRequest.filter = FilterService.getFormatted([1,2,3]);
        return filterRequest;
    };

    connector.getPrimaryFilter = function() {
        var filterRequest = angular.copy(connector.statsHelper.results);
        filterRequest.filter = FilterService.getFormatted([1]);
        return filterRequest;
    };

    connector.getHistoricalFilter = function() {
        var filterRequest = angular.copy(connector.statsHelper.results);
        filterRequest.filter = FilterService.getFormatted([1,2]);
        return filterRequest;
    };

    connector.getSecondaryFilter = function() {
        var filterRequest = angular.copy(connector.statsHelper.results);
        filterRequest.filter = FilterService.getFormatted([1,2,3]);
        return filterRequest;
    };

    connector.doFilter = function() {
        if (connector.realFilterMethod == null) {
            $log.warn("no filter method defined");
            var deferred = $q.defer();
            deferred.resolve("no filter method defined");
            return deferred.promise;
        }

        var searchPromise = connector.realFilterMethod();
        searchPromise.success(function() {
            $timeout(function () {
                //subtract 50 so we don't scroll part of results under the header nav
                window.scrollTo(0, angular.element("#scroll-here-on-filter").offset().top - 50);
            });
        });

        return searchPromise;
    };

    return connector;
}]);



nsr.directive('nsrCriteriaBlock', function(){
    return {
        restrict: 'E',
        scope: {
            criteriaElement: '=',
            filterScope: '=',
            class: '='
        },
        template:
            '<div ng-repeat="criteriaElement in filterService.filters | filter:{scope : filterScope}" class="col-md-6 criteriaBlock">' +
                '<div class="criteriaElement scope-{{filterScope}}" ng-class="classModifiers" >' +
                    '<div class="header">' +
                        '<b>{{::criteriaElement.name}} </b>' +
                        '<span ng-if="criteriaElement.type == \'multi\'" class="" >' +
                            '<a href="javascript:void(0)" ng-click="selectAll(criteriaElement)"><i class="icon-actions fa fa-check-square-o" aria-hidden="true"></i> All</a>' +
                            '<a href="javascript:void(0)" ng-click="clear(criteriaElement)"><i class="icon-actions fa fa-square-o" aria-hidden="true"></i> Clear</a>' +
                        '</span>' +
                        '<div class="headerAction">' +
                            //'<i class="fa fa-edit fa-lg" ng-show="!expanded" ng-click="toggle(true)"></i>' +
                            //'<i class="fa fa-check fa-lg" ng-show="expanded" ng-click="toggle(false)"></i>' +
                        '<i uib-tooltip="Remove" class="fa fa-times-circle fa-lg" ng-click="filterService.removeFilter(criteriaElement)"></i>' +
                        '</div>' +
                    '</div>' +
                    '<div ng-if="criteriaElement.type == \'multi\'">' +
                        '<ui-select multiple ng-model="criteriaElement._values"  append-to-body="true">' +
                        '<ui-select-match placeholder="Select values">{{$item.value}}</ui-select-match>' +
                        '<ui-select-choices repeat="option in criteriaElement.enum | filter:$select.search" style="text-transform: capitalize">{{option.value | underscoreless}}</ui-select-choices>' +
                        '</ui-select>' +
                    '</div>' +
                    '<div ng-if="criteriaElement.type == \'range\' || criteriaElement.type == \'date\'" class="form-group">' +
                        '<div class="col-sm-6 criteriaMin"><input class="range-selector" type="text" ng-model="criteriaElement._values.min" placeholder="{{::criteriaElement.min}}"/></div>' +
                        '<div class="col-sm-6 criteriaMax"><input class="range-selector" type="text" ng-model="criteriaElement._values.max" placeholder="{{::criteriaElement.max}}"/></div>' +
                    '</div>' +
                    '<div ng-if="criteriaElement.type == \'binary\'" style="font-size:larger;">' +
                        'Matches {{::criteriaElement.name}}' +
                        '<input type="hidden" ng-model="criteriaElement._values.min" ng-init="criteriaElement._values.min = \'1\'"/>' +
                    '</div>' +
                '</div>' +
        '</div>',

        controller: ['$scope', 'FilterService', 'SearchConnector', function($scope, FilterService, SearchConnector) {
            $scope.filterService = FilterService;

            $scope.expanded = false;
            $scope.classModifiers = [$scope.class, 'collapsed'];

            $scope.toggle = function(state) {
                $scope.expanded = state;
                $scope.classModifiers = ($scope.expanded) ? [$scope.class, 'expanded'] : [$scope.class, 'collapsed'];
            };

            $scope.isVisible = function(criteriaElement) {
                return (SearchConnector.showSecondaryFilters ||
                    SearchConnector.statsHelper.getFolioFilters()[criteriaElement.column] == null);
            };

            $scope.selectAll = function(criteriaElement) {
                criteriaElement._values = angular.copy(criteriaElement.enum);
            };

            $scope.clear = function(criteriaElement) {
                criteriaElement._values = null;
            }

        }]
    }
});

nsr.controller('SaveModalController', ['$scope', '$uibModalInstance', 'title', 'userFilter', function ($scope, $uibModalInstance, title, userFilter) {
    $scope.title = title;
    $scope.isNew = true;
    $scope.overwrite = true;

    if(userFilter === null) {
        $scope.name = null;
        $scope.description = null;
    } else {
        $scope.isNew = false;
        $scope.name = userFilter.name;
        $scope.description = userFilter.description;
    }

    $scope.userFilter = userFilter;

    $scope.save = function($event) {
        $event.preventDefault();
        $uibModalInstance.close({
            name:$scope.name,
            description: $scope.description,
            overwrite: $scope.overwrite,
            isNew : $scope.isNew

        });
    };

    $scope.overwriteChange = function(overwrite) {
        if(overwrite) {
            $scope.title = 'Update Strategy';
        } else {
            $scope.title = 'Create Strategy';
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);



nsr.controller('SearchController', ['$scope', 'SearchConnector', '$state', '$stateParams', '$location', 'API', '$http', '$uibModal', '$log', '$timeout', 'FilterService', 'FormulaService', 'UserService','VendorService',
    function ($scope, SearchConnector, $state, $stateParams, $location, API, $http, $uibModal, $log, $timeout, FilterService, FormulaService, UserService, VendorService) {


        $scope.connector = SearchConnector;

        $scope.vendor_id_select = SearchConnector.setVendorAndLoadStatsHelper($stateParams.platform).getVendorId();


        // Current User Filter
        $scope.userFilter = null;
        $scope.connector.portfolio = null;
        $scope.connector.subPortfolio = null;

        $scope.filterService = FilterService;



        var emptyGroup = { filters: [] };
        $scope.getPrivateFilterGroup = function(name) {
            var privateFilterGroups = $scope.connector.statsHelper.getFilterGroupsPrivate();
            for (var i = 0; i < privateFilterGroups.length; i++) {
                if (privateFilterGroups[i].name == name) return privateFilterGroups[i].filters;
            }
            return emptyGroup;
        };


        $scope.connector.statsHelper.initPromise.then(function() {

            // automatically load portfolio when passed in as parameter
            if($stateParams.portfolioId != null && $stateParams.portfolioId != '') {
                $timeout(function() {
                    $scope.connector.portfolio = {
                        portfolio_id : parseInt($stateParams.portfolioId)
                    };

                    $scope.updateResults();
                    
                    //lookup portfolio info so we can display the name
                    API.get("portfolio/" + $scope.connector.portfolio.portfolio_id).then(function(response) {
                        $scope.connector.portfolio = response.data;
                    });


                }, 0);
            } else if($stateParams.userFilterId != null && $stateParams.userFilterId != '') {
                UserService.getUserFiltersPromise($scope.vendor_id_select).then(function(filters) {
                    var userFilter = _.find(filters, function(filter) { return filter.user_filter_id == $stateParams.userFilterId; });
                    if (userFilter != null) {
                        $scope.loadUserFilter(userFilter);
                        $timeout(function() {
                            $scope.updateResults();
                        }, 0);
                    }
                })
            } else if ($location.search()['go'] == 'true') {
                //$location.search('go', null);
                $timeout($scope.updateResults);
            }
        });

        $scope.changeVendor = function(goToVendor) {
            if ($scope.connector.vendorId == goToVendor) return false;

            var vendor = VendorService.get(goToVendor);
            if (vendor.vendor_id != goToVendor) {
                bootbox.alert("Unknown vendor: " +  goToVendor);
                return false;
            }

            $state.go($state.current.name, {platform: vendor.route_key});
            return true;
        };



        $scope.primaryMarketLinkClass = function() {
            if (!$scope.connector.showPrimaryCount) return "ng-hide";
            if ($scope.connector.results.primaryMatches && $state.current.name != 'primary-market') return 'search-market-link';
            return "";
        };

        $scope.goToPrimaryMarket = function() {
            if ($scope.primaryMarketLinkClass() == 'search-market-link') {
                $state.go('primary-market' , {platform: $stateParams.platform});
            }
        };

        $scope.showBalanceClass = function() {
            return ($state.current.name == 'secondary-market') ? 'ng-show' : ' ng-hide';
        };

        $scope.secondaryMarketLinkClass = function() {
            if (!$scope.connector.showSecondaryCount) return "ng-hide";
            if ($scope.connector.results.secondaryMatches && $state.current.name != 'secondary-market') return 'search-market-link';
            return "";
        };

        $scope.goToSecondaryMarket = function() {
            if ($scope.secondaryMarketLinkClass() == 'search-market-link') {
                $state.go('secondary-market' , {platform: $stateParams.platform});
            }
        };



        $scope.activeSearches = 0;
        $scope.updateResults = function() {
            $scope.activeSearches++;
            $scope.connector.doFilter().finally(function() {
                $scope.activeSearches--;
            });
        };



        /* Load existing user filter */
        $scope.userFilter = null;
        $scope.previousUserFilter = null;

        $scope.setUserFilter = function(userFilter) {



            $scope.userFilter = userFilter;

            if (FilterService.getFilters().length > 0) {
                if (userFilter == null || userFilter == "") {
                    $scope.reset();
                    return;
                }

                bootbox.confirm("Reset back tester and load filter?", function (r) {
                    $timeout(function() {
                        if(r) {
                            $scope.loadUserFilter(userFilter);
                        } else {
                            $scope.userFilter = $scope.previousUserFilter;
                        }
                    });
                });
            } else {
                $scope.loadUserFilter(userFilter)
            }
        };


        $scope.loadUserFilter = function(userFilter) {

            // Intialize Checkboxes
            FilterService.reset();

            if(userFilter == null || userFilter == "") {
                //selected the 'empty option'
                $scope.previousUserFilter = userFilter;
                return;
            }
            FilterService.setFromUserFilter(userFilter.filter, $scope.connector.statsHelper);
            $scope.previousUserFilter = userFilter;
        };


        $scope.test = {};


        $scope.reset = function () {
            bootbox.confirm("Reset back tester?", function (r) {
                if(r) {
                    $timeout(function() {
                        FilterService.reset();
                        $scope.results = [];
                        $scope.userFilter = $scope.previousUserFilter = null;
                    });
                }
            });
        };




        /* Modals */

        $scope.portfolios = function () {
            API.get('portfolios?vendor_id=' + $scope.connector.vendorId).then(function(response) {

                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '_select.html',
                    controller: 'ModalController',
                    size: 'sm',
                    resolve: {
                        items: function () {
                            return response.data.data;
                        },
                        title: function() {
                            return 'Select a Portfolio'
                        },
                        displayFunction: function() {
                            return function (portfolio) {
                                return portfolio.name;
                            }
                        }
                    }
                });

                modalInstance.result.then(function (selectedItem) {
                    if(_.isArray(selectedItem)) {
                        $scope.connector.portfolio = {
                            portfolio_id: _.pluck(selectedItem, 'portfolio_id')
                        };
                    } else {
                        $scope.connector.portfolio = selectedItem;
                    }
                    $scope.updateResults();
                }, function () {

                });
            }); //set this to our list of portfolios for this vendor
        };

        $scope.saveFilter = function() {
            if(!UserService.isAuthenticated()) {
                UserService.upgrade("Saved Strategies");
                return;
            }



            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '_save.html',
                controller: 'SaveModalController',
                size: 'sm',
                resolve: {
                    title: function() {
                        return ($scope.userFilter === null) ? 'Create Strategy' : 'Update Strategy';
                    },
                    userFilter : function() {
                        return $scope.userFilter;
                    }
                }
            });

            modalInstance.result.then(function (save) {
                //console.log(save);

                var postSave = function(response) {
                    bootbox.alert(response.data);
                    $scope.connector.statsHelper.refreshUserFilters();
                };

                if(save.isNew || !save.overwrite) { // create
                    API.post('userfilters',
                        {
                            filter: SearchConnector.getFilter().filter,
                            vendor_id: $scope.connector.vendorId,
                            name: save.name,
                            description: save.description
                        }).then(postSave);
                } else { // Update
                    API.put('userfilters/' + $scope.userFilter.user_filter_id,
                        {
                            filter: SearchConnector.getFilter().filter,
                            name: save.name,
                            description: save.description
                        }).then(postSave);
                }
            });
        };

        $scope.saveView = function() {

        };

        $scope.formulaEdit = false;

        $scope.formula = function(formula) {
            $scope.formulaEdit = true;
            //this is somewhat hacky, the column name is formula_[id], so we parse the id off
            FormulaService.formulaId = (formula == null) ? null : formula.column.split("_")[1];
            FormulaService.vendorId = $scope.connector.vendorId;
        }
    }]
);


nsr.factory('FormulaService', ['$log', function ($log) {
    return {
        formulaId: null,
        vendorId: 1
    }
}]);

nsr.controller('FormulaEditController', ['$scope', '$log', '$timeout', 'FormulaService', 'StatsService', 'API', function ($scope, $log, $timeout, FormulaService, StatsService, API) {

    $scope.close = function() {
        $scope.setFormula(null);
        FormulaService.formulaId = null;
        $scope.$parent.formulaEdit = false;
    };

    $scope.setFormula = function(f) {
        if (f == null) {
            $scope.edit = false;
            $scope.formula = { vendor_id: FormulaService.vendorId, formula: []};
        } else {
            //formula is a json string, need to convert it to an array
            $scope.edit = true;
            $scope.formula = f;
            $scope.formula.formula = angular.fromJson(f.formula);
        }
        $scope.statsHelper = StatsService.getStatsHelper($scope.formula.vendor_id);
    };


    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    $scope.loanField = null;

    $scope.fieldOptions = function() {
        return _.filter($scope.statsHelper.getFilters(), function (obj) {

            //if we can't use it on listings, we don't want it in a formula
            var usageLimits = obj['usage_limits'];
            if (usageLimits != null && (usageLimits == 1 || usageLimits == 2)) return false;

            //we do not support models/formulas inside of models/formulas at this time
            var startsWith = obj.column.split('_')[0];
            if (startsWith == 'model' || startsWith == 'formula') return false;

            //ranges are numeric, these are good to go
            if (obj.type == 'range') return true;

            //multi where all options are numeric are good to go
            if (obj.type == 'multi') {
                return _.every(obj.enum, function (selectOption) {
                    var n = selectOption.value;
                    return isNumber(n);
                })
            }

            //exclude the rest
            return false;
        });
    };


    $scope.save = function () {
        //sanity check parenthesis
        var count = 0;
        var invalidNumbers = 0;
        for(var i = 0; i < $scope.formula.formula.length ; i++) {
            if ($scope.formula.formula[i].type == 'Group' && $scope.formula.formula[i].value == '(') count++;
            else if ($scope.formula.formula[i].type == 'Group' && $scope.formula.formula[i].value == ')') count--;
            else if ($scope.formula.formula[i].type == 'Number' &&
                ($scope.formula.formula[i].value.trim() == '' || !isNumber($scope.formula.formula[i].value))) invalidNumbers++;
        }
        if (invalidNumbers != 0) {
            alert('You have empty number boxes, or values that are not valid numbers.  Please fix before saving.');
            return;
        }

        if (count != 0) {
            alert('You do not have matching open and close parenthesis.  Please fix before saving.');
            return;
        }

        if (!$scope.enableSymbol()) {
            alert('Your formula is incomplete.  Please fix before saving.');
            return;
        }

        //formula is a json string on persisted object, so convert it before saving
        var formula = angular.copy($scope.formula);
        formula.formula = angular.toJson(formula.formula);
        //$log.debug(formula);

        if($scope.edit) {
            API.put('formulas/' + formula.formula_id, formula).success(function(response) {
                bootbox.alert("Formula Updated");
            });
        } else {
            API.post('formulas', formula).success(function(response) {
                bootbox.alert("Formula Saved");
                $scope.setFormula(response);
                $scope.statsHelper.refreshFilterGroupsPrivate();
            });
        }
    };

    $scope.load = function(formulaId) {
        var result = API.get('formulas/' + formulaId);
        result.success(function (response) {
            $scope.setFormula(response);
        });
    };

    $scope.delete = function(formulaId) {
        if (confirm("Are you sure you want to delete this formula?")) {
            var result = API.delete('formulas/' + formulaId);
            result.success(function () {
                $scope.statsHelper.refreshFilterGroupsPrivate();
                $scope.close();
            });
        }
    };

    $scope.$watch(function() { return FormulaService.formulaId }, function(n, o) {
        if(n != null && o != n) {
            $scope.load(n);
        }

        if(n === null) { // new Formula
            $scope.setFormula(null);
        }
    });

    $scope.addField = function($select) {
        if ($select.selected == null || $select.selected.column == null) return;
        $scope.addToFormula('Field', $select.selected.column);
        $select.selected = null;
    };

    $scope.addToFormula = function(type, value) {
        $scope.formula.formula[$scope.formula.formula.length] = {type: type, value: value};
    };

    $scope.removeItem = function(index) {
        $scope.formula.formula.splice(index, 1);
    };

    $scope.enableNumber = function() {
        if ($scope.formula.formula.length == 0) return true;
        var previousItem = $scope.formula.formula[$scope.formula.formula.length-1];
        return  (previousItem.type == 'Group' && previousItem.value == '(')
            || (previousItem.type == 'Symbol');
    };

    $scope.enableOpenParen = function() {
        if ($scope.formula.formula.length == 0) return true;
        var previousItem = $scope.formula.formula[$scope.formula.formula.length-1];
        return  (previousItem.type == 'Group' && previousItem.value == '(')
            || (previousItem.type == 'Symbol');
    };

    $scope.enableCloseParen = function() {
        if ($scope.formula.formula.length == 0) return false;
        var count = 0;
        for(var i = 0; i < $scope.formula.formula.length ; i++) {
            if ($scope.formula.formula[i].type == 'Group' && $scope.formula.formula[i].value == '(') count++;
            if ($scope.formula.formula[i].type == 'Group' && $scope.formula.formula[i].value == ')') count--;
        }
        if (count <= 0) return false;

        var previousItem = $scope.formula.formula[$scope.formula.formula.length-1];
        return  (previousItem.type == 'Group' && previousItem.value == ')')
            || (previousItem.type == 'Number' || previousItem.type == 'Field');
    };

    $scope.enableSymbol = function() {
        if ($scope.formula.formula.length == 0) return false;
        var previousItem = $scope.formula.formula[$scope.formula.formula.length-1];
        return  (previousItem.type == 'Group' && previousItem.value == ')')
            || (previousItem.type == 'Number' || previousItem.type == 'Field');
    };

}]);

nsr.controller('PermalinkController', ['$scope', '$state', '$stateParams', '$location', 'FilterService', 'StatsService', 'API', 'SearchConnector', 'VendorService',
    function ($scope, $state, $stateParams, $location, FilterService, StatsService, API, SearchConnector, VendorService) {
        API.get('permalink/' + $stateParams.hash + '?type=json').success(function(data) {

            if (data.found == false) {
                bootbox.alert("Invalid link.  Your search was not found.");
                $location.url('/');
                return;
            }

            var vendor = VendorService.get(data.vendor_id);
            if (vendor.vendor_id != data.vendor_id) {
                bootbox.alert("Unknown vendor: " +  data.vendor_id);
                $location.url('/');
                return;
            }

            var statsHelper = SearchConnector.setVendorAndLoadStatsHelper(vendor.route_key);

            statsHelper.initPromise.then(function() {
                FilterService.setFromUserFilter(data.filter.filter, statsHelper);
                if ((parseInt(data.filter.v) > 1) && data.filter.breakdowns != null) {
                    statsHelper.results.breakdowns = data.filter.breakdowns;
                 }
                $location.url('/stats/' + vendor.route_key + '//?go=true');
            });

        });
    }
]);


nsr.filter('searchFilters', function() {
    return function (filters, searchCriteria) {
        if (searchCriteria == null) searchCriteria = '';
        searchCriteria = searchCriteria.toUpperCase();
        return _.filter(filters, function(filter) {
            return searchCriteria == '' || ((filter.description != null && filter.description.toUpperCase().indexOf(searchCriteria) >= 0) ||
                    (filter.name != null && filter.name.toUpperCase().indexOf(searchCriteria) >= 0) ||
                    (filter.column != null && filter.column.toUpperCase().indexOf(searchCriteria) >= 0));
        });
    };
});