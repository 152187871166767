// add/remove error class to our elements if there is an error on the field
nsr.directive('nsrErrorCheck', ["ErrorHandler", function(ErrorHandler) {
    return { link: function(scope, element, attrs) {
        var keys = attrs.ngModel.split('.');
        ErrorHandler.watch(element, keys[keys.length-1]);

        scope.$on('$destroy', function() {
            ErrorHandler.unwatch(element);
        });
    } };
}]);


nsr.directive('dropdownMultiselect', function(){
    return {
        restrict: 'E',
        scope:{
            model: '=',
            options: '=',
            pre_selected: '=preSelected'
        },
        template: "<div class='btn-group' data-ng-class='{open: open}'>"+
        "<button class='btn btn-small'>Select</button>"+
        "<button class='btn btn-small dropdown-toggle' data-ng-click='open=!open;openDropdown()'><span class='caret'></span></button>"+
        "<ul class='dropdown-menu' aria-labelledby='dropdownMenu'>" +
        "<li><a data-ng-click='selectAll()'><i class='icon-ok-sign'></i>  Check All</a></li>" +
        "<li><a data-ng-click='deselectAll();'><i class='icon-remove-sign'></i>  Uncheck All</a></li>" +
        "<li class='divider'></li>" +
        "<li data-ng-repeat='option in options'> <a data-ng-click='setSelectedItem()'>{{option.name}}<span data-ng-class='isChecked(option.id)'></span></a></li>" +
        "</ul>" +
        "</div>" ,
        controller: ['$scope', '$log', function($scope, $log) {

            $scope.openDropdown = function(){
                $scope.selected_items = [];
                for(var i=0; i<$scope.pre_selected.length; i++) {
                    $scope.selected_items.push($scope.pre_selected[i].id);
                }
            };

            $scope.selectAll = function () {
                $scope.model = _.pluck($scope.options, 'id');
                $log.debug($scope.model);
            };
            $scope.deselectAll = function() {
                $scope.model=[];
                $log.debug($scope.model);
            };
            $scope.setSelectedItem = function(){
                var id = this.option.id;
                if (_.contains($scope.model, id)) {
                    $scope.model = _.without($scope.model, id);
                } else {
                    $scope.model.push(id);
                }
                $log.debug($scope.model);
                return false;
            };
            $scope.isChecked = function (id) {
                if (_.contains($scope.model, id)) {
                    return 'icon-ok pull-right';
                }
                return false;
            };
        }]
    }
});





nsr.directive('nsrUpgrade', ["AccountService", function(AccountService) {
    return {
        restrict: 'EA',

        link: function(scope, element, attributes) {
            scope.accountId = attributes.accountId;
        },
        scope: {

        },
        template:
        '<div ng-show="!selected" class="btn-group" style="width:100%" uib-dropdown is-open="status.isopen"> ' +
        '<button id="upgrade-account" type="button" class="btn btn-sq btn-block btn-dashed btn-primary"  uib-dropdown-toggle ng-disabled="disabled">' +
        '{{message}}' +
        '</button>' +

        '<ul uib-dropdown-menu role="menu" aria-labelledby="upgrade-account">' +
        '<li role="menuitem"><a href="javascript:void(0)" ng-click="upgrade(1)">Conservative</a></li>' +
        '<li role="menuitem"><a href="javascript:void(0)" ng-click="upgrade(2)">Balanced</a></li>' +
        '<li role="menuitem"><a href="javascript:void(0)" ng-click="upgrade(3)">Assertive</a></li>' +
        '</ul>' +
        '</div>',

        controller: ['$scope', 'API', function($scope, API) {
            $scope.id = null;
            $scope.selected = false;
            $scope.message = 'Upgrade to Fully Managed';

            $scope.accountTypes = [
                {management_style_id: 1, description : 'Conservative'},
                {management_style_id: 2, description : 'Balanced'},
                {management_style_id: 3, description : 'Assertive'}
            ];

            $scope.upgrade = function(id) {
                var description = _.where($scope.accountTypes, {management_style_id : id})[0].description;
                var template = '';


                bootbox.dialog({
                    title: "Upgrade account to " + description,
                    message: '<ul>' +
                        '<li>When you upgrade to a Fully-Managed account, we will invest in loans according to the proprietary strategies you select (<b>' + description + '</b>).</li>' +
                        '<li>The cost is a 0.60% annual fee (<b>billed quarterly</b>)</li>' +
                        '<li>You will no longer be able to add your own strategies or manage the account.</li>' +
                        '<li>You can downgrade from Fully-Managed status at any time from this page.</li>' +
                        '</ul>',
                    buttons: {
                        upgrade: {
                            label: "Upgrade",
                                className: "btn-success",
                                callback: function() {
                                    AccountService.upgrade($scope.accountId, id);
                                }
                            },
                        cancel : {
                            label: "Cancel",
                            className: "btn-warning",
                            callback: function() {
                                bootbox.alert("This account will not be upgraded");
                            }
                        }
                    }
                });

            };
        }]
    }
}]);


/**
 * Displays a single filter element in a UI friendly manner looking up the display versions of the name and values of
 * the filter element
 */
nsr.directive('nsrUserFilterElement', function(){
    return {
        restrict: 'E',
        scope: {
            filterName: '=',
            filterValues: '=',
            vendorId: '='
        },
        template:
                '<div class="btn btn-primary userFilterElement" style="margin: 5px; text-transform: capitalize; cursor: default; white-space: normal;">' +
                '{{::getDescription(filterName, filterValues)}}' +
                '</div>' +
            '</div>',

        controller: ['$scope', 'StatsService', '$log', function($scope,  StatsService, $log) {
            var statsHelper = StatsService.getStatsHelper($scope.vendorId);


            var isNumeric = function(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            };

            $scope.getDescription = function(filterName, filterValues) {
                if (!statsHelper.loaded) return undefined;

                var filter = statsHelper.getFilter(filterName);
                if (filter == null) {
                    //$log.warn("no filter found for criteria: " + filterName);
                    return filterName + " is an unknown search criteria";
                }

                if (filter.type == 'range') {

                    if (isNumeric(filterValues.min) && isNumeric(filterValues.max)) {
                        return filter.name + " >= " + filterValues.min + ' and <= ' + filterValues.max;
                    }

                    if (isNumeric(filterValues.min)) {
                        return filter.name + " >= " + filterValues.min;
                    }

                    return filter.name + " <= " + filterValues.max;
                }


                if(filter.type == 'multi') {
                    var enumLookupTable = _.indexBy(filter.enum, 'key');
                    //$log.debug(enumLookupTable);
                    var lookupValue = function(v) {
                        var result = v;
                        var enumValue = enumLookupTable[v];
                        if (enumValue != null) {
                            result = enumValue.value;
                        }
                        return underscoreless(result);
                    };

                    filterValues = angular.copy(filterValues);

                    var initialDescription = filter.name + ": \"" + lookupValue(filterValues.splice(0, 1)[0]) + "\"";
                    return _.reduce(filterValues, function(memo, filterValue) {
                        return memo + ", \"" + lookupValue(filterValue) + "\"";
                    }, initialDescription);

                }

                if(filter.type == 'date') {
                    if (filterValues.min != null && filter.max != null) {
                        return filter.name + " >= " + filterValues.min + ' and <= ' + filter.max;
                    }

                    if (filterValues.min != null) {
                        return filter.name + " >= " + filterValues.min;
                    }

                    return filter.name + " <= " + filterValues.max;
                }

                if(filter.type == 'binary') {
                    return filter.name;
                }

            }

        }]
    }
});
