var underscore = angular.module('underscore', []);
underscore.factory('_', function() {
    return window._;
});

var nsr = angular.module('nsr',
    [
        'ui.router',
        'underscore',
        'ngAnimate',
        'ui.bootstrap',
        'satellizer',
        'highcharts-ng',
        'angular-loading-bar',
        'ui.select',
        'ngSanitize',
        'ngMask',
        'rzModule',
        'checklist-model',
        'toastr',
        'pageslide-directive',
        'angular-svg-round-progress',
        'tableSort',
        'angular-stripe',
        'credit-cards',
        'angulartics',
        'angulartics.clicky',
        'angulartics.google.analytics',
        'updateMeta',
        'ngMaterial'
    ]
);

nsr.filter("trust", ['$sce', function($sce) {
    return function(htmlCode){
        return $sce.trustAsHtml(htmlCode);
    }
}]);

nsr.config(['$compileProvider', function ($compileProvider) {
    // $compileProvider.debugInfoEnabled(false);
}]);

nsr.factory('_', function() {
    return window._;
});


nsr.config(['highchartsNGProvider', function (highchartsNGProvider) {
    //highchartsNGProvider.lazyLoad();// will load hightcharts (and standalone framework if jquery is not present) from code.hightcharts.com

    //highchartsNGProvider.lazyLoad([highchartsNGProvider.HIGHCHART/HIGHSTOCK, "maps/modules/map.js", "mapdata/custom/world.js"]);// you may add any additional modules and they will be loaded in the same sequence

    //highchartsNGProvider.basePath("/js/"); // change base path for scripts, default is http(s)://code.highcharts.com/
}]);


/**
 * Configure authentication provider (uses ngSanitize)
 */
nsr.config(['$authProvider', function($authProvider) {
    // Internal
    $authProvider.loginUrl = window._rootUrl + '/api/auth';
    $authProvider.tokenName = 'auth_token';
    $authProvider.authHeader = 'X-Auth-Token';

    // Google
    $authProvider.google({
        url: window._rootUrl + '/auth/google',
        authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
        redirectUri: window._rootUrl + '/auth/callback',
        requiredUrlParams: ['scope'],
        optionalUrlParams: ['display'],
        scope: ['profile', 'email'],
        scopePrefix: 'openid',
        scopeDelimiter: ' ',
        display: 'popup',
        type: '2.0',
        clientId: '720681023970-8d03f4n23uffgr5aq9is407qu3v52mfu.apps.googleusercontent.com'
    });
}]);

/**
 * Service for handling errors returned from ajax calls.
 * Tied into the root scope, where we have a mechanism for displaying these on our master page template
 * Also has method for adding fields to watch in order to kick off watches
 */
nsr.factory('ErrorHandler', ['$rootScope', 'toastr', function($rootScope, toastr) {
    var ErrorHandler = {
        errors: { field: null },
        watches: {},

        clearErrors: function() {
            ErrorHandler.errors.field = null;
        },

        setFieldErrors: function(fieldErrors) {

            ErrorHandler.errors.field = fieldErrors;
            evaluateWatches(fieldErrors, ErrorHandler.watches);
            toastr.error(ErrorHandler.format());
        },

        watch: function(element, key) {
            element.nsrFieldKey = key;
            ErrorHandler.watches[key] = element;
        },

        unwatch: function(element) {
            delete ErrorHandler.watches[element.nsrFieldKey];
        },

        clearWatches: function() {
            ErrorHandler.watches = {};
        },
        format : function() {
            var formattedResponse = '';
            if(_.has(this.errors.field, 'errors')) {
                if(_.isArray(this.errors.field.errors)) {
                    formattedResponse += '<li>' + this.errors.field.errors[0] + '</li>';
                } else {
                    _.each(this.errors.field.errors, function (value, prop) {
                        formattedResponse += '<li>' + value[0] + '</li>';
                    });
                }
            } else {
                console.log(this.errors.field);
            }

            //toastr.error(formattedResponse, 'Error');

            return '<ul class="error-list">' + formattedResponse + '</ul>';
        }
    };

    function evaluateWatches(fieldErrors, watches) {
        for (var i = 0; i < watches.length; i++) {
            var element = watches[i];

            if (fieldErrors == null || fieldErrors.length == 0) {
                element.removeClass('nsr-field-error');
            }

            if (fieldErrors[element.nsrFieldKey] != null) {
                element.addClass('nsr-field-error');
                continue;
            }

            element.removeClass('nsr-field-error');
        }
    }


    $rootScope.errors = ErrorHandler.errors;

    //for dev purposes, probably should remove this connection in the future
    $rootScope.ErrorHandler = ErrorHandler;

    return ErrorHandler;
}]);


/**
 * Keep track of state prior to get redirected to to the login page so we can send the user back there.
 * This is helpful in case somebody bookmarks a page or sends a direct link that requires authentication
 */
nsr.factory('LoginService', ['$location', '$state', '$log', 'SupportService', 'MessageService', '$auth', function($location, $state, $log, SupportService, MessageService, $auth) {
    var defaultPostLogin = 'dashboard';
    var postLoginPage = defaultPostLogin;

    return {
        reset: function() {
            postLoginPage = defaultPostLogin;
        },

        showLoginPage: function(returnPage) {
            if (returnPage != null) {
                postLoginPage = returnPage;
            } else {
                postLoginPage = $location.url();
            }

            //$log.debug('return page: ' + postLoginPage);

            if (postLoginPage == null || postLoginPage.indexOf("/login") > -1) {
                postLoginPage = defaultPostLogin;
            }

            return $state.go('login');
        },

        showPostLoginPage: function() {

            SupportService.reload();
            MessageService.bindUserChannel($auth.getPayload().broadcast_id);


            //we don't want to do a redirect if we are logging in as part of the wizard signup process
            if ($location.path() == '/signup-wizard') {
                return $location.path();
            }

            var t = postLoginPage;
            postLoginPage = defaultPostLogin;
            return $location.url(t);
        },
        showSignupPage: function() {
            return $state.go('signup.account');
        },
        endSession: function() {
            $auth.logout().then(function() {
                MessageService.shutdown();
                SupportService.endSession();
                $state.go('login');
            });
        }
    }
}]);

nsr.factory('API', ['$http', 'ErrorHandler', 'LoginService', '$auth', function ($http, ErrorHandler, LoginService, $auth) {

    var basePath = 'api/';
    var loginRequiredMessageDisplayed = false;

    function makeRequest(verb, uri, data) {

        verb = verb.toLowerCase();

        //start with the uri
        var httpArgs = (isAbsolute(uri)) ? [uri] : [basePath + uri];
        if (verb.match(/post|put/)) {

            httpArgs.push(data);
        }

        var defer = $http[verb].apply(null, httpArgs);

        defer.error(function (data, status) {
            if(status == 401) {
                if (!loginRequiredMessageDisplayed) {
                    loginRequiredMessageDisplayed = true;
                    bootbox.alert("The requested resource requires authentication", function () {
                        loginRequiredMessageDisplayed = false;
                    });
                    if ($auth.isAuthenticated()) {
                        $auth.logout().then(function() {
                            LoginService.showLoginPage();
                        });
                    } else {
                        LoginService.showLoginPage();
                    }

                }
            }
            else if(status == 403) {
                bootbox.alert("You do not have permission to access this resource.");
            }
            else if(status == 409) {
                bootbox.alert(data.errors[0]);
            }
            else if(status == 402) {
                bootbox.alert(data.errors[0]);
            }
            else if (status == 400) {  //400 means we are returning known error messages to be displayed


                ErrorHandler.setFieldErrors(data);
            } else {
                if(typeof data == 'string') {
                    bootbox.alert(data);
                }
            }
        });

        //we might want to consider this to use some sort of time delay to make sure
        //we are not clearing errors to quickly in the case of multiple http requests close together
        defer.success(ErrorHandler.clearErrors);

        // Handle refresh token
        defer.success(function(data, status) {

        });

        return defer;

    }

    function isAbsolute(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
            return false;
        }
        return true;
    }

    return {
        get: function (uri, data) {
            return makeRequest('get', uri, data);
        }
        , post: function (uri, data) {
            return makeRequest('post', uri, data);
        }
        , put: function (uri, data) {
            return makeRequest('put', uri, data);
        }
        , delete: function (uri, data) {
            return makeRequest('delete', uri, data);
        }
    };
}]);


nsr.filter('pluck', function() {
    return function(data, key) {
        if(!key) {
            throw ":key required";
        }

        if(data.length == 0) {
            return [100];
        }
        return _.pluck(data, key);
    }
});


nsr.filter('sum', function() {
    return function(data, key) {

        if(!key) {
            throw ":key required";
        }

        var total = 0;
        for (var i = 0 ; i < data.length ; i++) {
            total += data[i][key];
        }

        return total;
    }
});


/**
 * Service for requesting data from the API, transforming it, and caching it for a defined amount of time
 */
nsr.factory('DataCache', ['API', '$q', '$log', function(API, $q, $log) {
    var millisInMinute = 1000 * 60;    //# of milliseconds in a minute
    var defaultTimeout = millisInMinute * 30; //default timeout of 30 mins

    var dataDefinitions = {};

    /**
     * cache contains objects with 4 attributes {
     * loaded,  //boolean if data has finished loading
     * data, //the data returned by API (or null if still loading)
     * promise, // a promise that returns the transformed data after it is loaded
     * expires //the time this cache object expires
     * }
     */
    var cache = {};


    /**
     * call backs for when data is refreshed so we can do post processing on that data {
     *      key,  //cache key the call back is for
     *      callback, //the callback function
     * }
     */
    var callbacks = [];


    var runCallbacks = function(key, data) {
        for(var i = 0; i < callbacks.length; i++) {
            if (callbacks[i].cacheKey == key) {
                callbacks[i].callback(data);
            }
        }
    };

    var cacheService = {
        definitionExists: function(key) {
          return (dataDefinitions[key] != null);
        },


        addDataDefinition: function (key, url, timeoutInMinutes, defaultValue, transform) {
            dataDefinitions[key] = { key: key, url: url, timeoutInMinutes: timeoutInMinutes, defaultValue: defaultValue, transform: transform };
        },

        /**
         * start an API call for the data and return a promise to that call unless the data is passed in, at which point we just process it
         * @param key cachekey
         * @param data optional paramater for the data to use in the cache
         */
        refresh: function(key, data)
        {
            var dataSet = dataDefinitions[key];
            if (dataSet == null) {
                $log.error("data definition does not exist: " + key);
            }

            var oldCacheEntry = cache[key];
            var newPromiseData = {loaded: false, data: null, promise: null, expires: null};
            if (oldCacheEntry != null) newPromiseData.data = oldCacheEntry.data;    //move data forward until it gets reloaded
            newPromiseData.expires = (new Date()).getTime() + ((dataSet.timeoutInMinutes == null) ? defaultTimeout : dataSet.timeoutInMinutes * millisInMinute);
            cache[key] = newPromiseData;


            if (data != null) {
                var deferred = $q.defer();
                newPromiseData.promise = deferred.promise;

                newPromiseData.data = (dataSet.transform == null) ? data : dataSet.transform(data, status);
                newPromiseData.loaded = true;
                deferred.resolve(newPromiseData.data);
                runCallbacks(key, newPromiseData.data);
                return newPromiseData.promise;
            }

            newPromiseData.promise = API.get(dataSet.url).success(function (data, status) {
                newPromiseData.data = (dataSet.transform == null) ? data : dataSet.transform(data, status);
                newPromiseData.loaded = true;
                return newPromiseData.data;
            }).error(function(data) {
                //reset the cache item if there is an error
                cache[key] = oldCacheEntry;
            }).then(function() {
                runCallbacks(key, newPromiseData.data);
                return newPromiseData.data
            });
            return newPromiseData.promise;
        },

        /**
         * returns the data if available
         * if the data is not immediately available we return 'false' until the data is available
         * @param key
         * @returns {*}
         */
        get: function(key) {
            if (cache[key] == null || (new Date()).getTime() > cache[key].expires) this.refresh(key);
            if (!cache[key].loaded) return false;
            return cache[key].data;
        },

        /**
         * if you know the data was loaded because of a previous promise, then return the data even if the cache
         * timeout has expired. Also kick off a reload if the cache has expired so its fresh for the next call.
         * @param key
         * @returns {*}
         */
        getSafe: function(key) {
            var data = (cache[key] == null || cache[key].data == null) ? false : cache[key].data;
            if (cache[key] == null || (new Date()).getTime() > cache[key].expires) this.refresh(key);
            return data;
        },


        /**
         * return the default value for the data set when it isn't available
         * this is most likely a blank list or a blank object, but it could be something else
         * @param key
         */
        getDefault: function(key) {
            return dataDefinitions[key].defaultValue;
        },

        /**
         * Get a promise to the data (useful for doing .success calls elsewhere)
         * @param key
         * @returns {promise|Function}
         */
        getPromise: function(key) {
            if (cache[key] == null || (new Date()).getTime() > cache[key].expires) this.refresh(key);
            return cache[key].promise;
        },


        /**
         * Clear data items (logging out?)
         * @param keys
         */
        clear: function(keys) {
            if (!Array.isArray(keys)) keys = [keys];
            for (var i = 0; i < keys.length; i++) {
                cache[keys[i]] = null;
            }
        },

        registerCallback: function(cacheKey, callback) {
            callbacks.push( { cacheKey: cacheKey, callback: callback } );
        },

        unregisterCallback: function(cacheKey, callback) {
            for(var i = 0; i < callbacks.length; i++) {
                if (callbacks[i].cacheKey == cacheKey && callbacks[i].callback == callback) {
                    callbacks.splice(i, 1);
                }
            }
        },

    };

    return cacheService;
}]);


nsr.factory('SystemMessages', ['DataCache', function(DataCache) {
    DataCache.addDataDefinition('system/messages', 'system/messages', 60, {});
    DataCache.refresh('system/messages');

    return {
        get: function(messageKey) {
            var messages = DataCache.getSafe('system/messages');
            return (messages == null) ? null : messages[messageKey];
        },

        refresh: function() {
            return DataCache.refresh('system/messages');
        }
    }
}]);


nsr.factory('VendorService', ['DataCache', 'UserService', function(DataCache, UserService) {
    var unknownVendor = { vendor_id: null, name: 'Unknown', route_key: 'unknown', public: false , stats: false, invest: false };

    //Storing the data in a couple different formats so we can return the same variable over and over again
    // instead of dynamically generating the list.  This makes it easier for angular to realize things have not changed

    var vendors = [];
    var statsVendors = [];

    var publicVendors = [];
    var publicStatsVendors = [];

    var vendorIdMap = {};
    var vendorUrlKeyMap = {};

    var processVendorList = function(vendorList) {
        var p = [];
        var ps = [];
        var s = [];
        var vmap = {};
        var urlMap = {};

        _.each(vendorList, function(vendor) {

            if (vendor.public) {
                p.push(vendor);
            }

            if (vendor.stats) {
                if (vendor.public) ps.push(vendor);
                s.push(vendor);
            }

            vmap[''+vendor.vendor_id] = vendor;
            urlMap[vendor.route_key] = vendor;
        });
        vendors = vendorList;
        publicVendors = p;
        publicStatsVendors = ps;
        statsVendors = s;
        vendorIdMap = vmap;
        vendorUrlKeyMap = urlMap;
    };

    DataCache.addDataDefinition('vendors', 'vendors', 60*24, []);
    DataCache.registerCallback('vendors', processVendorList);

    var VendorService = {
        loadList: function(vendorList) { DataCache.refresh('vendors', vendorList); },
        refresh: function() { DataCache.refresh('vendors'); },
        list: function() {
            return UserService.isBetaUser() ? vendors : publicVendors;
        },
        get: function(vendor_id) {
            var v = vendorIdMap[''+vendor_id];
            return (v == null) ? unknownVendor : v;
        },
        getByRouteKey: function(route_key) {
            var v = vendorUrlKeyMap[route_key];
            return (v == null) ? unknownVendor : v;
        },
        listStats: function() {
            return UserService.isBetaUser() ? statsVendors : publicStatsVendors;
        }
    };

    VendorService.getRouteKeyForId = function(vendor_id) {
        return VendorService.get(vendor_id).route_key;
    };

    return VendorService;
}]);




/**
 * Keep track of the user and the data
 */
nsr.factory('UserService', ['API', '$q', '$auth', 'DataCache', '$rootScope', 'LoginService', '$state', '$compile', function(API, $q, $auth, DataCache, $rootScope, LoginService, $state, $compile) {

    DataCache.addDataDefinition('user/roles', 'user/roles', 60, {});
    DataCache.addDataDefinition('user/state', 'user/state', 120, {});
    DataCache.addDataDefinition('dashboard', 'dashboard' , 30, {}, function(results) { return results.data });
    DataCache.addDataDefinition('accounts', 'accounts', 30, [], function(results) {

        return results.data
    });

    var loaded = false;
    var cacheKeysToLoadOnLogin = ['dashboard', 'accounts', 'user/roles', 'user/state'];
    var cacheKeysToClearOnLogout = ['dashboard', 'accounts', 'user/roles', 'user/state'];
    var initPromises = [];
    var initPromisesCompleted = 0;

    function initUser() {
        if (!$auth.isAuthenticated()) return $q.reject('not authenticated');

        initPromises = [];
        initPromisesCompleted = 0;
        _.each(cacheKeysToLoadOnLogin, function(cacheKey) {
            var initPromise = DataCache.refresh(cacheKey);
            initPromise.then(function() { initPromisesCompleted++; });
            initPromises.push(initPromise);
        });
        return $q.all(initPromises).then(function() {
            loaded = true;
            //make sure a digest goes off after roles finish loading so that the menu gets refreshed
            if (!$rootScope.$$phase) $rootScope.$apply();
        });
    }
    initUser();

    function postLogin(response) {
        initUser().then(function() {
            $rootScope.resendConfirmEmail = function() {
                bootbox.hideAll()
                API.get('confirm-email-resend').then(function(apiResponse) {
                    bootbox.alert('An email has been sent to ' + apiResponse.data + ' with a confirmation link, please follow the instructions in the email')
                });
            };

            LoginService.showPostLoginPage();
            if (response.data && !response.data.email_confirmed) {
                const tplCrop = '<p>Your email address needs to be verified. Check your email for a confirmation link.  Accounts where the email address have not been verified will be disabled. If you didn\'t receive the email and would like to resend it, click <a href=\'javascript:void(0)\' style=\'text-decoration: underline\' ng-click=\'resendConfirmEmail()\'>here</a>.</p>';
                const template = angular.element(tplCrop);
                const linkFn = $compile(template);
                const html= linkFn($rootScope);

                bootbox.alert({
                    message: html,
                });
            }
        });
    }

    function cashFlowTransformation(data, status) {
        switch (status) {
            case 200:
                return _.sortBy(data, function (obj) { return obj.sort });

            case 202: // request queued
                bootbox.alert("This account was recently created. Cash flows have not been processed yet.");
        }
        return [];
    }

    function upgrade(feature) {

        var strings = {
            title: '',
            message: ''
        };

        var buttons = {};

        if($auth.isAuthenticated()) {
            strings.title = 'Please upgrade your account to enable this feature.'
            strings.message = '<ul>' +
                    '<li>Accept the investor agreements</li>' +
                    '<li>Setup your billing information</li>' +
                    '</ul>';

            buttons = {
                create: {
                    label: "View Investor Agreement",
                    className: "btn-info",
                    callback: function () {
                        $state.go('settings.agreements');
                    }
                },
                matrix: {
                    label: "Setup Billing",
                    className: "btn-info",
                    callback: function () {
                        $state.go('settings.billing');
                    }
                }
            };
        } else {
            strings.title = 'This action requires an account.';
            strings.message = 'Upgrade your account to access ' + feature;

            buttons = {
                create: {
                    label: "View Features",
                    className: "btn-info",
                    callback: function () {
                        $state.go('features');
                    }
                },
                matrix: {
                    label: "Create Account",
                    className: "btn-info",
                    callback: function () {
                        LoginService.showSignupPage();
                    }
                }
            };
        }

        bootbox.dialog({
            title: '<b>' + strings.title +  '</b>',
            message: '<p>' + strings.message+ '</p>',
            buttons: buttons
        });
    }

    function initUserFiltersForVendor(vendorId) {

        var cacheKey = 'userfilters/' + vendorId;
        if (!DataCache.definitionExists(cacheKey)) {
            DataCache.addDataDefinition(cacheKey, cacheKey, 30, [], null);
            cacheKeysToClearOnLogout.push(cacheKey);
        }
    }

    return {
        upgrade : function(feature) {
          return upgrade(feature);
        },
        isAuthenticated: function() { return $auth.isAuthenticated() /*&& loaded */; },

        initUser: function() { DataCache.clear(cacheKeysToClearOnLogout); return initUser() },

        getLoginProgress: function() {
            if (initPromises.length == 0) return 100;
            return Math.ceil((initPromisesCompleted / initPromises.length) * 100);
        },

        addCacheKeyToClearOnLogout: function(cacheKey) {cacheKeysToClearOnLogout.push(cacheKey)},

        authGoogle: function () {
            return $auth.authenticate('google').then(postLogin);
        },

        authInternal: function (username, password, code) {
            return $auth.login({username: username, password: password, code: code}).then(postLogin);
        },

        logout: function() {
            $auth.logout();
            DataCache.clear(cacheKeysToClearOnLogout);
            LoginService.reset();
        },

        getUserId: function() {
            return $auth.getPayload().user_id;
        },

        refreshData: function(key) { return DataCache.refresh(key); },

        getData: function(key) {
            var data = DataCache.get(key);
            return (data) ? data : DataCache.getDefault(key);
        },


        hasRole: function(role) { return this.isAuthenticated() && this.getData('user/roles')[role] != null; },
        isBetaUser: function() { return this.hasRole('beta') },
        isInvestor: function() { return this.hasRole('auto_investing') },
        isAdvisor: function() { return this.hasRole('advisor') },
        isClient: function() { return this.hasRole('client') },
        isMigratedUser: function() {return this.hasRole('migrated_user') },
        isInstitutional: function() { return this.hasRole('institutional') },
        refreshRoles: function() { return DataCache.refresh('user/roles'); },

        getState: function() { return this.getData('user/state'); },
        refreshState: function() { return DataCache.refresh('user/state'); },
        getAccounts: function() { return DataCache.getPromise('accounts'); },
        refreshAccounts: function() { return DataCache.refresh('accounts'); },

        getUserFilters: function(vendorId) {
            initUserFiltersForVendor(vendorId);
            return DataCache.getSafe('userfilters/' + vendorId);
        },
        getUserFiltersPromise: function(vendorId) {
            initUserFiltersForVendor(vendorId);
            return DataCache.getPromise('userfilters/' + vendorId);
        },
        refreshUserFilters: function(vendorId) {
            initUserFiltersForVendor(vendorId);
            return DataCache.refresh('userfilters/' + vendorId);
        },

        getDashboardData: function() { return DataCache.getPromise('dashboard'); },
        refreshDashboardData: function() { return DataCache.refresh('dashboard'); },

        getCashFlow: function(accountId, cashFlowType, reinvest) {
            var cacheKey = 'cashflow/' + accountId + '/' + cashFlowType + '?reinvest=' + ((reinvest) ? 'true' : 'false');
            if (!DataCache.definitionExists(cacheKey)) {
                DataCache.addDataDefinition(cacheKey, cacheKey, 180, [], cashFlowTransformation);
                this.addCacheKeyToClearOnLogout(cacheKey);
            }
            return DataCache.getPromise(cacheKey);
        },

        getProfile: function() { return API.get('profile').then(function(apiResponse) {
            var profile = apiResponse.data;
            if (profile.extended == null) {
                profile.extended = {};
            }
            return profile;
        });},

        isSetupForInvesting: function() {
            if (!this.isAuthenticated()) return false;

            var state = this.getState();
            return (state.investing_override || (state.billable && state.agreement));
        },

        getDownloadToken: function() { return (this.isAuthenticated()) ? $auth.getToken : null; }

    };
}]);



nsr.factory('FilterService', ['API', '$rootScope', '$timeout', '$log', function (API, $rootScope, $timeout, $log) {
    function getColumns(filters) {
        return _.map(filters, function(obj) { return obj.column });
    }

    function setValue(filter, clear) {
        if (filter['_values'] == null || (clear === true)) {
            filter._values = (filter.type == 'multi') ? [] : {min: null, max: null};
        }
    }

    return {
        filters: [], // Only the filters the investor has selected, these are NOT all filters
        filterColumns: [], //work around for checkbox directive that needs to watch a static object

        addFilter: function (filter) {
            var i = this.getFilterIndex(filter);
            if (i >= 0) {
                $log.error("cannot add duplicate filter: " + filter.column);
            } else {
                this.filters.push(filter);
                this.filterColumns.push(filter.column);
                this.ensureValuesExist();
            }
        },
        removeFilter: function (filter) {
            var i = this.getFilterIndex(filter);
            if (i >= 0) {
                this.filters.splice(i,1);
                this.filterColumns.splice(i,1);
            }
            setValue(filter, true);
        },
        toggleFilter: function(filter, addToFilter) {
            if (addToFilter) {
                this.addFilter(filter);
            } else {
                this.removeFilter(filter);
            }
        },
        setFilters: function (filters) {
            this.filters = filters;
            this.filterColumns = getColumns(filters);
            this.ensureValuesExist();
        },
        getFilterIndex: function(filter) {
            // find index
            var i = -1;
            _.each(this.filters, function (obj, idx) {
                if (obj.column == filter.column) {
                    i = idx;
                }
            });
            return i;
        },
        getFilters: function () {
            return this.filters;
        },
        getFilterColumns: function() {
            return this.filterColumns;
        },
        reset: function() {
            this.filters = [];
            this.filterColumns = [];
        },
        setFromUserFilter: function(filter, statsHelper) {
            this.reset();
            if (filter == null) return;

            var myself = this;
            _.each(filter, function(val, key) {
                myself.setFilterValues(statsHelper, key, val);
            });
        },
        setFilterValues: function(statsHelper, key, val) {
            var filter = statsHelper.getFilter(key);
            if (filter == null) {
                $log.warn("no filter found for criteria: " + key);
                return;
            }

            //we don't want duplicates, so override the existing filter
            var i = this.getFilterIndex(filter);
            if (i >= 0) {
                filter = this.filters[i];
            } else {
                this.addFilter(filter);
            }

            if(filter.type == 'multi') {
                var enumLookupTable = _.indexBy(filter.enum, 'key');
                filter._values = (_.map(val, function(v) {
                    var enumValue = enumLookupTable[v];
                    if (enumValue == null) {
                        $log.warn("no filter enum value found for criteria/value: " + key + " / " + v);
                    }
                    return enumValue;
                }))
            } else {
                filter._values = val;
            }
        },
        /**
         * Get the proper filter to send to NSRj based on scopes.
         * @param scope
         * @returns {{}}
         */
        getFormatted: function (scope) {
            this.ensureValuesExist();
            var filter = {};

            _.each(this.filters, function(criteriaElement) {

                if(_.contains(scope, criteriaElement.scope)) {
                    if (criteriaElement.type == 'multi') {
                        var values = _.map(criteriaElement._values, function (value) {
                            return value.key;
                        });
                        if (values != null && values.length != 0) filter[criteriaElement.column] = values;
                    } else {
                        if (criteriaElement._values.min != null && criteriaElement._values.min != '') {
                            filter[criteriaElement.column + '_min'] = criteriaElement._values.min;
                        }

                        if (criteriaElement._values.max != null && criteriaElement._values.max != '') {
                            filter[criteriaElement.column + '_max'] = criteriaElement._values.max;
                        }
                    }
                } else {
                    $log.debug("Ignoring: " + criteriaElement.column);
                }
            });

            return filter;
        },
        ensureValuesExist: function() {
            _.each(this.filters, setValue);
        }
    }
}]);


/**
 * Service to help manage back testing
 */
nsr.factory('StatsService', ['API', 'DataCache', 'UserService', '$rootScope', '$q', '$log', function(API, DataCache, UserService, $rootScope, $q, $log) {

    var statsHelpers = [];

    function endsWith(str, suffix) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }

    function createStatsHelper(vendorId) {
        var statsHelper = {};
        
        statsHelper.loaded = false;

        statsHelper.getVendorId = function() { return vendorId; };
        statsHelper.getBreakdownViews = function() { return []; };
        statsHelper.getFilterGroupsPublic = function() { return []; };
        statsHelper.getFilterGroupsPrivate = function() { return []; };
        statsHelper.getFilterGroupsSecondary = function() { return []; };

        /** Not implemented yet since we don't need these directly yet... **/
        //statsHelper.getModels = function() { return null; };
        //statsHelper.getFormulas = function() { return null; };

        statsHelper.results = {
            columns: {
                prepaid: false, completed: false, interest: true, roi : true, avg_apr : true,
                fees: false, loss: false, outstanding: true, principal: true,
                loss_apr: true, count: true, age: true, weightedAge: false,
                std_dev: true, eff_maturity: false, eff_duration: false
            },
            lossEstimates: {},
            breakdowns: [],
            dynamicBreakdowns: [],
            use1kloans: false
        };

        statsHelper.getFilter = function(key) {
            if (endsWith(key, '_max') || endsWith(key, '_min')) key = key.substring(0, key.length-4);

            var filter = statsHelper.getFilters()[key];
            return (filter == null) ? statsHelper.getFolioFilters()[key] : filter;
        };

        statsHelper.getFilterDisplayName = function(key) {
            var filter = statsHelper.getFilter(key);
            return (filter == null) ? key : filter.name;
        };

        function nullOrBlank(value) { return value == null || value == ''; }

        statsHelper.getDescription = function(criteriaElement) {
            var desc = criteriaElement.filter.name;
            if (criteriaElement.filter.type == 'multi') {
                if (criteriaElement.values == null || criteriaElement.values.length == 0) {
                    desc += " is anything";
                } else {
                    if(criteriaElement.values.length > 3) {
                        desc += " has " + criteriaElement.values.length + " selected";
                    } else {
                        desc += " is " + _.map(criteriaElement.values, function (value) {
                                return value.value;
                            }).concat();
                    }
                }
            } else {
                if (nullOrBlank(criteriaElement.min) && nullOrBlank(criteriaElement.max)) {
                    desc += " is anything";
                } else {
                    if(!nullOrBlank(criteriaElement.min)) {
                        desc += " >= " + criteriaElement.min;
                    }
                    if(!nullOrBlank(criteriaElement.max)) {
                        desc += " <= " + criteriaElement.max;
                    }
                }
            }
            return desc;
        };

        statsHelper.filterCache = {};
        statsHelper.createFilterCache = function() {
            var filterCache = [];
            _.map(statsHelper.getFilterGroupsPublic(), function(filterGroup) {
                Array.prototype.push.apply(filterCache, filterGroup.filters);
            });
            _.map(statsHelper.getFilterGroupsPrivate(), function(filterGroup) {
                Array.prototype.push.apply(filterCache, filterGroup.filters);
            });
            statsHelper.filterCache = _.indexBy(filterCache, 'column');
            //$log.debug("filter cache " + vendorId + " updated: " + filterCache.length);
        };
        DataCache.registerCallback('filters-public/' + vendorId, statsHelper.createFilterCache);
        DataCache.registerCallback('filters-private/' + vendorId, statsHelper.createFilterCache);
        statsHelper.getFilters = function() { return statsHelper.filterCache; };

        statsHelper.filterCacheSecondary = {};
        statsHelper.createFilterCacheSecondary = function() {
            var filterCache = [];
            Array.prototype.push.apply(filterCache, statsHelper.getFilterGroupsSecondary());
            statsHelper.filterCacheSecondary = _.indexBy(statsHelper.getFilterGroupsSecondary(), 'column');
            //$log.debug("secondary filter cache " + vendorId + " updated: " + filterCache.length);
        };
        DataCache.registerCallback('filters-public-secondary/' + vendorId, statsHelper.createFilterCacheSecondary);
        statsHelper.getFolioFilters = function() { return statsHelper.filterCacheSecondary; };


        statsHelper.breakdownOptionCache = [];
        statsHelper.createBreakdownOptionCache = function() {
            var iterator = function (filter) {
                var type = 'field';
                var parts = filter.column.split("_");
                if (parts.length == 2 && (parts[0] == 'formula' || parts[0] == 'model')) type = parts[0];
                return {name: filter.name, field: filter.column, view: 'table', zoomLevel: 0, type: type};
            };
            statsHelper.breakdownOptionCache = _.map(statsHelper.getFilters(), iterator);
            //$log.debug("breakdown cache " + vendorId + " updated: " + statsHelper.breakdownOptionCache.length);
        };
        DataCache.registerCallback('filters-public/' + vendorId, statsHelper.createBreakdownOptionCache);
        DataCache.registerCallback('filters-private/' + vendorId, statsHelper.createBreakdownOptionCache);
        statsHelper.getBreakdownOptions = function() { return statsHelper.breakdownOptionCache; };


        statsHelper.orderedLossCategories = [];
        statsHelper.createdOrderedLossCategories = function(lossEstimates) {
            var data = [];
            _.each(lossEstimates, function(value, key) {
                data.push( { cat: key, val: value} );
            });
            statsHelper.orderedLossCategories = _.pluck(_.sortBy(data, 'val'), 'cat');
        };
        DataCache.registerCallback('loss-estimates/' + vendorId, statsHelper.createdOrderedLossCategories);


        return statsHelper;
    }

    return {

        initVendor: function (vendorId) {
            var initPromises = [];

            if (!DataCache.definitionExists('filters-public/' + vendorId)) {
                DataCache.addDataDefinition('filters-public/' + vendorId, 'filters-public/' + vendorId , 180, {}, null);
            }
            initPromises.push(DataCache.getPromise('filters-public/' + vendorId));

            if (!DataCache.definitionExists('filters-public-secondary/' + vendorId)) {
                DataCache.addDataDefinition('filters-public-secondary/' + vendorId, 'filters-public-secondary/' + vendorId , 180, {}, null);
            }
            initPromises.push(DataCache.getPromise('filters-public-secondary/' + vendorId));

            if (!DataCache.definitionExists('filters-private/' + vendorId)) {
                DataCache.addDataDefinition('filters-private/' + vendorId, 'filters-private/' + vendorId , 5, {}, null);
                UserService.addCacheKeyToClearOnLogout('filters-private/' + vendorId);
            }
            initPromises.push(DataCache.getPromise('filters-private/' + vendorId));


            if (!DataCache.definitionExists('loss-estimates/' + vendorId)) {
                DataCache.addDataDefinition('loss-estimates/' + vendorId, 'loss-estimates/' + vendorId , 180, {}, null);
            }
            initPromises.push(DataCache.getPromise('loss-estimates/' + vendorId));

            if (!DataCache.definitionExists('breakdown-views/' + vendorId)) {
                DataCache.addDataDefinition('breakdown-views/' + vendorId, 'breakdown-views/' + vendorId , 5, {}, null);
                UserService.addCacheKeyToClearOnLogout('breakdown-views/' + vendorId);
            }
            initPromises.push(DataCache.getPromise('breakdown-views/' + vendorId));

            if (UserService.isAuthenticated()) {
                initPromises.push(UserService.getUserFiltersPromise(vendorId));
            }
            return $q.all(initPromises);
        },


        /**
         * Initialize a helper object for this vendor or returned the already generated helper object
         * if we have been here before
         * @param vendorId
         * @returns StatsHelper
         */
        getStatsHelper: function(vendorId) {
            if (statsHelpers[vendorId] != null) {
                return statsHelpers[vendorId];
            }

            var statsHelper = createStatsHelper(vendorId);


            statsHelper.initPromise = this.initVendor(vendorId).then(function() {
                statsHelper.getUserFilters = function() { return (UserService.isAuthenticated()) ? UserService.getUserFilters(vendorId) : null; };
                statsHelper.refreshUserFilters = function() { UserService.refreshUserFilters(vendorId); };

                statsHelper.getBreakdownViews = function() { return DataCache.getSafe('breakdown-views/' + vendorId); };
                statsHelper.getFilterGroupsPublic = function() { return DataCache.getSafe('filters-public/' + vendorId).filters; };

                statsHelper.getFilterGroupsPrivate = function() { return DataCache.getSafe('filters-private/' + vendorId).filters; };
                statsHelper.refreshFilterGroupsPrivate = function() { return DataCache.refresh('filters-private/' + vendorId); };

                statsHelper.getFilterGroupsSecondary = function() { return DataCache.getSafe('filters-public-secondary/' + vendorId).filters; };

                statsHelper.results.lossEstimates = DataCache.getSafe('loss-estimates/' + vendorId);

                statsHelper.results.breakdowns = statsHelper.getBreakdownViews()[0].breakdowns;

                statsHelper.createFilterCache();
                statsHelper.createBreakdownOptionCache();
                statsHelper.createFilterCacheSecondary();
                statsHelper.createdOrderedLossCategories(statsHelper.results.lossEstimates);

                statsHelper.loaded = true;

                if (!$rootScope.$$phase) $rootScope.$apply();
            });
            statsHelpers[vendorId] = statsHelper;
            return statsHelper;
        }
    };
}]);


nsr.factory('MessageService', ['$rootScope', 'toastr', '$log', function($rootScope, toastr, $log) {
    var pusher;

    var channelId;
    var channel;

    var callbacks = {};

    function executeCallbacks(queue, data) {
        var queueCallbacks = callbacks[queue];
        if (queueCallbacks != null) {
            _.each(queueCallbacks, function(callback) { callback(data); } );
        }
    }

    function displayMessage(message) {
        toastr.info(message);
    }

    return {
        init: function() {
            pusher = new Pusher(window._wsKey, {
                wsHost: window._wsHost,
                wsPort: window._wsPort,
                wssPort: window._wssPort,
                enabledTransports: ['ws', 'flash']
            });

            var globalChannel = pusher.subscribe('global');
            globalChannel.bind('message', function(data){
                $log.debug(data);
                toastr.info(data);
            });

            channelId = null;
            channel = null;
            callbacks = {};
        },

        registerQueue: function(queue) {
            channel.bind(queue, function(data) {
                //$log.debug(data);
                executeCallbacks(queue, data);
            })
        },

        registerCallback:  function (messageQueue, callback) {
            if (callbacks[messageQueue] == null) callbacks[messageQueue] = [];
            if (! _.contains(callbacks[messageQueue], callback)) {
                callbacks[messageQueue].push(callback);
            }
        },

        bindUserChannel : function(id) {
            channelId = id;
            $log.debug('Binding User Channel: ' + id);
            channel = pusher.subscribe(id);

            this.registerQueue('message');
            this.registerQueue('reload-account');
            this.registerQueue('reload-userfilter');

            //setup the callback for the message queue (toastr display)
            this.registerCallback('message', displayMessage);
        },

        showMessage : function(message) {
            displayMessage(message);
        },
        shutdown : function() {
            pusher.unsubscribe(channelId);
        }
     }
}]);


nsr.factory('TransitionService', ['$rootScope', 'toastr', function($rootScope, toastr) {

    var transitionService = {
        inProgress: false,
        message: ''
    };

    transitionService.flagInProgress = function(event) {
        if(event == null || event.defaultPrevented == null || !event.defaultPrevented) {
            transitionService.inProgress = true;
            transitionService.message = '';
        }
    };

    transitionService.flagCompleted = function(event) {
        if(event == null || event.defaultPrevented == null || !event.defaultPrevented) {
            transitionService.inProgress = false;
            transitionService.message = '';
        }
    };

    transitionService.flagInProgressUntilCompleted = function(promise, msg) {
        if (promise["finally"] != null) {
            transitionService.flagInProgress();
            if (msg != null) transitionService.message = msg;
            promise["finally"](transitionService.flagCompleted);
        }
    };

    transitionService.showToastUntilCompleted = function(promise, msg) {
        msg = '<i class="fa fa-spinner fa-spin"></i> &nbsp; ' + msg;
        if (promise != null && promise["finally"] != null) {
            var newToast = toastr.info(msg, null, {
                timeOut: 0,
                extendedTimeOut: 0
                ,iconClass: 'toast-loading',
                allowHtml: true
            });
            promise["finally"](function() {
                toastr.clear(newToast);
            });
        }
        return promise;
    };

    $rootScope.$on('$stateChangeStart', transitionService.flagInProgress);
    $rootScope.$on('$stateChangeSuccess', transitionService.flagCompleted);
    $rootScope.$on('$stateChangeError', transitionService.flagCompleted);

    $rootScope.transition = transitionService;

    return transitionService;
}]);

nsr.factory('SupportService', ['$rootScope', '$auth', '$timeout', '$window', function($rootScope, $auth, $timeout, $window) {

    var payload = function () {
        var appId = window._intercom_app_id;

        if ($auth.isAuthenticated()) {

            return {
                app_id: appId,
                user_id: $auth.getPayload().user_id,
                user_hash: $auth.getPayload().user_hash,
                email: $auth.getPayload().email,
                created_at: $auth.getPayload().created_at
            };
        } else {

            return {
                app_id: appId
            };
        }
    };

    var intercomActivated = function() {
        return (window.Intercom != null);
    };

    return {
        reload: function () {
            if (!intercomActivated()) return;

            if ($auth.isAuthenticated()) {
                //console.log('reload auth');
                window.Intercom('boot', payload());
            } else {
                //console.log('reload anon');
                window.Intercom('boot', payload());
            }
        },

        pageChange: function() {
            $timeout(function() {
                if (window._elev && window._elev.reloadTips) window._elev.reloadTips();
            }, 1000);


            if (!intercomActivated()) return;

            window.Intercom('update', payload());
        },
        endSession : function() {
            if (!intercomActivated()) return;

            window.Intercom('shutdown');
        },
        start: function(){
            window.Intercom('show');
        }
    };
}]);



nsr.factory('OrderQueueService', ['API', '$rootScope', '$timeout', '$log', function (API, $rootScope, $timeout, $log) {
    var orders = [];

    return {
        addOrder: function(noteId, loanId, orderId) {
            orders.push({
               loanId : loanId,
                orderId: orderId,
                noteId: noteId
            });
        },
        getOrders : function (){
            return orders;
        }

    }
}]);


function underscoreless(input) {
    input = input + '';
    return input.replace(/_/g, ' ');
}

nsr.filter('underscoreless', function () {
    return underscoreless;
});

nsr.filter('numberOfKeys', function () {
    return function(input) {
        return _.keys(input).length;
    };
});

nsr.config(['$analyticsProvider', function ($analyticsProvider) {
    $analyticsProvider.firstPageview(true);
    /* Records pages that don't use $state or $route */
    $analyticsProvider.withAutoBase(true);
    /* Records full path */
}]);

/**
 * Startup code
 *
 * 1) initialize ErrorHandler and TransitionService
 *
 * 2) put the UserService on the root scope so that all views have access to user if/then else logic
 *      (i.e. user.isClient() or user.hasRoles('role') etc..)
 *
 * 3) add "vendorList" method to root scope to quickly get vendor list on the UI
 *
 * 4) add SystemMessages to root scope for easy access in UI
 *
 * 5) Initialize intercom
 *
 */
nsr.run(['ErrorHandler', '$rootScope', 'UserService', 'TransitionService', 'SystemMessages', 'toastrConfig', '$timeout', 'StatsService', 'MessageService', 'SupportService', 'VendorService', '$auth', '$analytics', '$location',
    function(ErrorHandler, $rootScope, UserService, TransitionService, SystemMessages, toastrConfig, $timeout, StatsService, MessageService, SupportService, VendorService, $auth, $analytics, $location) {

        $rootScope.user = UserService;

        $rootScope.vendors = VendorService;
        //VendorService.refresh();

        $rootScope.systemMessages = SystemMessages;

        toastrConfig.positionClass = 'toast-bottom-right';
        toastrConfig.allowHtml = true;

        MessageService.init();

        if($auth.isAuthenticated()) {
            MessageService.bindUserChannel($auth.getPayload().broadcast_id);
        }
        SupportService.reload();

        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            if(!event.defaultPrevented) {
                ErrorHandler.clearErrors();
            }

            SupportService.pageChange();

        });

    }
]);
