nsr.controller('ChartController', ['$scope', '$http', 'API', '$auth', '$window','$stateParams',  function ($scope, $http, API, $auth, $window, $stateParams) {

    var dictionary = {
        'lendingclub-volume' : '',
        'prosper-volume' : '',
    };


//This is not a highcharts object. It just looks a little like one!
    var chartConfig = {
        options: {
            //This is the Main Highcharts chart config. Any Highchart options are valid here.
            //will be overriden by values specified below.
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'NSR'
                    //fontWeight: 'normal'
                }
            },
            tooltip: {
                style: {
                    padding: 10,
                    fontWeight: 'bold'
                }
            }
        },
        //The below properties are watched separately for changes.

        //Series object (optional) - a list of series using normal Highcharts series options.
        series: [],
        //Title configuration (optional)
        title: {
            text: ''
        },
        //Boolean to control showing loading status on chart (optional)
        //Could be a string if you want to show specific loading text.
        loading: false,
        //Configuration for the xAxis (optional). Currently only one x axis can be dynamically controlled.
        //properties currentMin and currentMax provided 2-way binding to the chart's maximum and minimum
        xAxis: {
            type: 'datetime',
            title: {
                text: ''
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        //Whether to use Highstocks instead of Highcharts (optional). Defaults to false.
        useHighStocks: false,
        //size (optional) if left out the chart will default to size of the div or something sensible.

        size: {
            height: 600
        },
        //function (optional)
        func: function (chart) {
            //setup some logic for the chart
        }
    };

    $scope.chartConfig = chartConfig;

    $scope.load = function() {
        console.log($stateParams);
        API.get('charts/' + $stateParams.type).then(function(response) {

            $scope.chartConfig.options.chart.type = response.data.chart.type;
            $scope.chartConfig.series = response.data.data;
            $scope.chartConfig.title.text = response.data.title;
            $scope.chartConfig.xAxis = response.data.xAxis;
            $scope.chartConfig.yAxis = response.data.yAxis;

        });
    };

    $scope.load();

}]);