nsr.controller('SimpleModeController', ['$scope', 'SearchConnector', 'UserService', 'AccountService', '$state', '$stateParams', 'API', '$http', '$uibModal', '$log', '$timeout', 'toastr', 'FilterService', '$location', '$analytics',
    function ($scope, SearchConnector, UserService, AccountService, $state, $stateParams, API, $http, $uibModal, $log, $timeout, toastr, FilterService, $location, $analytics) {

        $scope.slider = {
            value: 1,
            min: 0,
            max: 2,
            step: 1,
            translate: function (value) {
                switch (value) {
                    case 0 :
                        return 'Conservative';
                    case 1 :
                        return 'Balanced';
                    case 2 :
                        return 'Assertive';
                }
            }
        };


        $scope.onSliderChange = function () {
            $scope.updateExpectedReturnChart();
        };


        var getVendor = function (vendor_id) {
            switch (vendor_id) {
                case 1:
                    return 'LC';
                case 2:
                    return 'P';
            }
        };

        var getVendorId = function (platform) {
            switch (platform) {
                case 'lendingclub':
                    return 1;
                case 'prosper':
                    return 2;

            }
        };


        var account_id = $stateParams.accountId;
        $scope.vendor_id = getVendorId($stateParams.platform);

        $scope.accountService = AccountService;
        $scope.transition.flagInProgressUntilCompleted(AccountService.refresh());


        $scope.account = $scope.accountService.accounts.filter(function (a) {
            return a.account_id == account_id
        })[0];

        // # need update
        $scope.vendor = getVendor($scope.vendor_id);

        var conservative =
            {
                LC: {
                    avg: 0.06175966,
                    min: 0.04166177,
                    max: 0.07885337
                },
                P: {
                    avg: 0.05839517,
                    min: 0.03230917,
                    max: 0.08004804
                }
            };

        var balanced =
            {
                LC: {
                    avg: 0.08628667,
                    min: 0.05530229,
                    max: 0.10947132
                },
                P: {
                    avg: 0.07628507,
                    min: 0.03757143,
                    max: 0.10505153
                }
            };

        var assertive =
            {
                LC: {
                    avg: 0.08935236,
                    min: 0.05456839,
                    max: 0.11857264
                },
                P: {
                    avg: 0.09093360,
                    min: 0.04680301,
                    max: 0.12460848
                }
            };

        var range = {
            LC: {
                min: 3.0,
                max: 12.0
            },

            P: {
                min: 3.0,
                max: 13.0
            }
        };

        $scope.conservative = conservative[$scope.vendor];
        $scope.balanced = balanced[$scope.vendor];
        $scope.assertive = assertive[$scope.vendor];

        $scope.range = range[$scope.vendor];

        var getStrategy = function (strategy_id) {
            switch (strategy_id) {
                case 0:
                    return $scope.conservative;
                case 1:
                    return $scope.balanced;
                case 2:
                    return $scope.assertive;
            }
        };

        $scope.pdf = function (x) {
            var limit = x > $scope.avg ? $scope.max : $scope.min;
            var x2 = 3 * (x - $scope.avg) / (limit - $scope.avg);
            r = Math.exp(Math.pow(x2, 2));
            return Math.exp(-Math.pow(x2, 2));
        };


        $scope.updateExpectedReturnChart = function () {
            $scope.strategy = getStrategy($scope.slider.value);

            $scope.avg = $scope.strategy.avg;
            $scope.min = $scope.strategy.min;
            $scope.max = $scope.strategy.max;

            $scope.categories_data = [];
            $scope.chart_data = [];
            var step = .1;
            var x = $scope.range.min;

            while (x <= $scope.range.max) {
                $scope.categories_data.push(x);
                $scope.chart_data.push($scope.pdf(x / 100));
                x += step;
            }

            //chart_data.sort();

            function isEquals(element, index, array) {
                var el = Math.round(element * 10) / 10;
                return el === Math.round($scope.avg * 1000) / 10;
            }

            $scope.mean = 0;
            for (var i = 0 ; i < $scope.categories_data.length; i++) {
                if (isEquals($scope.categories_data[i])) {
                    $scope.mean = i;
                    break;
                }
            }


            // create Chart
            $('#expected-return-chart').highcharts({
                chart: {
                    type: 'area'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    allowDecimals: false,
                    labels: {
                        formatter: function () {
                            return parseInt(this.value) + '%'; // clean, unformatted number for year
                        },
                    },
                    tickInterval: 20,
                    categories: $scope.categories_data,
                    plotLines: [{
                        color: 'red',
                        value: $scope.mean, // Insert your average here
                        width: '1',
                        zIndex: 2 // To not get stuck below the regular plot lines
                    }]
                },
                yAxis: {
                    gridLineWidth: 0,
                    visible: false,
                    title: {
                        text: ''
                    },
                    labels: {
                        enabled: false
                    }
                },
                tooltip: {enabled: false},
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    },
                    area: {
                        pointStart: range.min,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,

                        }
                    },
                    series: {
                        animation: false
                    }
                },
                series: [{
                    name: 'Expected return',
                    data: $scope.chart_data,
                    line: {
                        allowPointSelect: false
                    }
                }]
            });

        };

        $scope.updateExpectedReturnChart();


        $scope.upgrade = function () {
            AccountService.upgrade(account_id, $scope.slider.value + 1);
            $state.go('accounts');
        }
    }]);