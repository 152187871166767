nsr.controller('BenchmarkController', ['$log', 'API', 'TransitionService', 'ErrorHandler', '$timeout', 'MessageService', '$scope', '$stateParams', 'UserService', 'StatsService', 'ChartDefinitions', '$http', '$q',
    function ($log, API, TransitionService, ErrorHandler, $timeout, MessageService, $scope, $stateParams, UserService, StatsService, ChartDefinitions, $http, $q) {

    $scope.options = {
        lockDate: false,
        lockRate: false
    };

    $scope.recessions = [

    ];

    $scope.index = {};
        $scope.chartConfig = ChartDefinitions.getBenchmarkDefinition();
        $scope.selectedPortfolio = null;

        var breakdowns = {
            lendingclub: [
                {
                    field: 'grade',
                    type: 'field',
                    view: 'table',
                    zoomLevel: 0

                },
                {
                    field: 'issue_d',
                    type: 'field',
                    view: 'table',
                    zoomLevel: 2

                }],
            prosper: [
                {
                    field: 'ProsperRating',
                    type: 'field',
                    view: 'table',
                    zoomLevel: 0

                },
                {
                    field: 'OriginationDate',
                    type: 'field',
                    view: 'table',
                    zoomLevel: 2

                }

            ]
        };


        function getVendorId() {
            return $scope.selectedPortfolio.vendor_id;
        }

        function getBreakDown() {
            switch (getVendorId()) {
                case 1:
                    return breakdowns['lendingclub'];
                    break;

                case 2:
                    return breakdowns['prosper'];
                    break;

                default:
                    return false;
            }
        }

        function getYValue() {
            switch (getVendorId()) {
                case 1:
                    return 'issue_d';
                    break;

                case 2:
                    return 'OriginationDate';
                    break;

                default:
                    return false;
            }
        }


        // Load Portfolios
        $http({
            method: 'GET',
            url: 'api/portfolios',
            data: {}
        }).then(function (response) {
            $scope.portfolios = response.data.data;
        });

        function keyTimeToJs(value) {
            return moment(value).unix() * 1000;
        }

        // Back Tester
        function getIndex(filter, indexFilter) {

            return $http({
                    method: 'POST',
                    url: 'api/stats/filter?vendor=' + getVendorId(),
                    data: {
                        breakdowns: getBreakDown(),
                        filter: _({}).extend(filter, indexFilter)
                    }
                }
            ).success(function (data, status) {
                $scope.validationErrors = {};
                $scope.index = data.breakdowns[0].results[0];
                var y = _.findWhere(data.breakdowns, {column: getYValue()});

                var data = [];

                _.each(y.results, function (row) {
                    data.push({y: row.roi * 100, x: keyTimeToJs(row.key_value)});
                });


                $scope.chartConfig.series.push({
                    name: 'Index',
                    color: '#6d8fd9',
                    data: data.reverse()
                });


            }).error(function (data, status) {

            });

        };

        function getPortfolio(filter) {

            console.log(filter);
            return $http({
                    method: 'POST',
                    url: 'api/stats/filter?vendor=' + getVendorId(),
                    data: {
                        breakdowns: getBreakDown(),
                        filter: _({}).extend({
                            portfolio_id: $scope.selectedPortfolio.portfolio_id
                        }, filter)
                    }
                }
            ).success(function (data, status) {
                $scope.portfolio = data.breakdowns[0].results[0];

                var y = _.findWhere(data.breakdowns, {column: getYValue()});

                var data = [];

                _.each(y.results, function (row) {
                    data.push({y: row.roi * 100, x: keyTimeToJs(row.key_value)});
                });

                $scope.chartConfig.series.push({
                    name: 'Portfolio',
                    color: '#F4604D',
                    data: data.reverse()
                });


            }).error(function (data, status) {

            });
        }


        function portfolioDetails() {
            return $http({
                    method: 'GET',
                    url: 'api/portfolio/details/' + $scope.selectedPortfolio.portfolio_id,
                }
            );
        }

        $scope.generate = function () {
            $scope.lock = true;
            $scope.chartConfig.loading = 'Loading...';
            $scope.chartConfig.series = [];


            portfolioDetails().then(function (response) {

                var filter = {};
                var indexFilter = {};

                var criteria = response.data;

                if($scope.options.lockDate) { // applies to both
                    if (!_.isUndefined(criteria.start_origination_date)) {
                        filter[getYValue() + '_min'] = criteria.start_origination_date;
                    }
                }



                if($scope.options.lockRate) { // index only
                    // @todo refactor
                    if(getVendorId() == 1) {
                        indexFilter['int_rate_min'] = (criteria.average_rate * 0.95).toString();
                        indexFilter['int_rate_max'] = (criteria.average_rate * 1.05).toString();
                    } else {
                        indexFilter['BorrowerRate_min'] = (criteria.average_rate * 0.95).toString();
                        indexFilter['BorrowerRate_max'] = (criteria.average_rate * 1.05).toString();
                    }
                }



                $q.all([getIndex(filter, indexFilter), getPortfolio(filter)]).then(function () {
                    $scope.chartConfig.loading = false;
                    $scope.lock = false;
                }, function () {
                    $scope.lock = false;
                });
            });


        };
    }]);
