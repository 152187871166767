nsr.controller('ModalController', ['$scope', '$modalInstance', 'items', 'title', 'displayFunction', function ($scope, $modalInstance, items, title, displayFunction) {
    $scope.title = title;
    $scope.items = items;

    $scope.display = (displayFunction == null) ? function (item) {
        return item;
    } : displayFunction;

    $scope.select = function (selectedItem, $event) {
        $event.preventDefault();
        $modalInstance.close(selectedItem)
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}]);


nsr.controller('LandingController', ['UserService', '$state', function (UserService, $state) {
    if (UserService.isAuthenticated()) {
        $state.go('dashboard');
    }
}]);

nsr.controller('StaticLandingController', ['UserService', '$state', function (UserService, $state) {
    // if (UserService.isAuthenticated()) {
    //     $state.go('dashboard');
    // }
}]);

nsr.controller('PasswordController', ['$scope', '$http', '$state', '$stateParams', '$auth', function ($scope, $http, $state, $stateParams, $auth) {


    $scope.email = "";

    $scope.requestReset = function () {
        $http({
            method: 'post',
            url: 'password/email',
            data: {
                email: $scope.email
            }
        }).then(function (data, status) {
            $scope.email = "";
            bootbox.alert("Password Reset, please check your email");
            $state.go('login');
        }, function (response) {

            bootbox.alert(response.data.errors);
        });
    };

    $scope.resetPassword = function () {
        $http({
            method: 'post',
            url: 'password/reset',
            data: {
                token: $stateParams.token,
                email: $scope.email,
                password_confirmation: $scope.password_confirmation,
                password: $scope.password
            }
        }).then(function (response, status) {
            bootbox.alert("Your account is now confirmed.");
            $auth.setToken(response.data.auth_token);
            $state.go('dashboard');

        }, function (response) {
            if (response.data.errors) {
                bootbox.alert(response.data.errors);
            } else {
                var message = "";
                _.each(response.data, function (attribute) {
                    _.each(attribute, function (msg) {
                        message = message + msg + "\n";
                    });
                });
                bootbox.alert(message);
            }
        });
    }
}]);

nsr.controller('AuthController', ['$scope', '$log', 'UserService', '$http', '$state', '$uibModal', function ($scope, $log, UserService, $http, $state, $uibModal) {

    $scope.username = '';
    $scope.password = '';
    $scope.code = null;

    if (UserService.isAuthenticated()) {
        $state.go('dashboard');
    }

    $scope.authGoogle = function () {
        UserService.authGoogle()
            .catch(function () {
                bootbox.alert("Invalid login");
            });
    };

    $scope.authInternal = function () {
        if ($scope.username.length == 0 || $scope.password.length == 0) {
            bootbox.alert("Enter your email and password");
            return;
        }

        UserService.authInternal($scope.username, $scope.password, $scope.code)
            .catch(function (response) {
                if (response.data.twofa) {
                    $scope.promptForCode();
                } else {
                    $scope.password = '';
                    bootbox.alert(response.data.errors[0]);
                }
            });
    };

    $scope.promptForCode = function () {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '_2fa.html',
            controller: 'TwoFactorCodeController',
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

        modalInstance.result.then(function (code) {
            $scope.code = code;
            $scope.authInternal();
        }, function () {
            // clear auth
        });
    };

}
]);


nsr.controller('TwoFactorCodeController', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {


    $scope.code = '';
    $scope.ok = function () {
        $uibModalInstance.close($scope.code);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);


nsr.controller('TransactionController', ['$scope', '$http', 'API', 'UserService', function ($scope, $http, API, UserService) {


    $scope.groupBy = 'intraday';
    $scope.pagination = {
        current_page: 1
    };

    $scope.type = '';
    $scope.transactions = [];
    $scope.accountId = null;

    $scope.allAccounts = [];

    UserService.getAccounts().then(function (accounts) {
        $scope.allAccounts = accounts;
    });

    $scope.load = function () {
        var url = ($scope.accountId == null) ? 'transactions' : 'transactions/' + $scope.accountId;
        var result = API.get(url + '?page=' + $scope.pagination.current_page + '&group_by=' + $scope.groupBy + '&type=' + $scope.type);

        result.then(function (response) {
            $scope.transactions = response.data.data;
            $scope.pagination = response.data.meta.pagination;
        })
    };

    $scope.loadGrouping = function () {
        $scope.pagination.current_page = 1;
        $scope.load();
    };

    $scope.reset = function () {
        if ($scope.groupBy != 'intraday' || $scope.pagination.current_page != 1) {
            $scope.groupBy = 'intraday';

            $scope.pagination = {
                current_page: 1
            };

            $scope.load();
        }
    }

}]);

nsr.controller('SecondaryController', ['$scope', 'UserService', '$state', 'API', function ($scope, UserService, $state, API) {

    $scope.selling = [];

    $scope.pagination = {
        current_page: 1
    };


    $scope.load = function () {
        API.get('secondary/selling' + '?page=' + $scope.pagination.current_page).success(function (response) {
            $scope.selling = response.data;
            $scope.pagination.per_page = response.meta.pagination.per_page;
            $scope.pagination.current_page = response.meta.pagination.current_page;
            $scope.pagination.total = response.meta.pagination.total;
        });
    };

    $scope.cancelAll = function () {

    };

}]);
/*
nsr.controller('SecondaryMarketController', ['$scope', 'SearchConnector', 'API', '$stateParams', '$timeout', 'OrderQueueService', 'UserService', function ($scope, SearchConnector, API, $stateParams, $timeout, OrderQueueService, UserService) {
    $scope.allAccounts = [];
    $scope.account = null;

    if (UserService.isAuthenticated()) {
        UserService.getAccounts().then(function (accounts) {
            $scope.allAccounts = accounts;
        });
    }

    $scope.isAuthenticated = function () {
        return UserService.isAuthenticated();
    };

    SearchConnector.setVendorCallBack = function (vendorId) {
        $scope.vendor_id = vendorId;
    };
    SearchConnector.showSecondaryFilters = true;
    SearchConnector.showPortfolio = false;
    SearchConnector.showSecondaryCount = true;
    SearchConnector.showPrimaryCount = false;
    SearchConnector.setVendorAndLoadStatsHelper($stateParams.platform);


    $scope.listings = [];

    $scope.load = function () {
        return API.post('markets/secondary/' + $stateParams.platform + '?page=' + $scope.pagination.current_page, {
            current_page: $scope.pagination.current_page,
            direction: "asc",
            filter: SearchConnector.getSecondaryFilter().filter,
            order_by: "id",
            pageSize: 25,
            vendor_id: $scope.vendor_id
        }).success(function (response) {
            $scope.listings = response.data;
            $scope.pagination.total = response.total;
            $scope.pagination.total_pages = response.total_pages;
            $scope.pagination.per_page = response.per_page;

            SearchConnector.setResults(response.total, null);
        }).error(function (data, status) {
            switch (status) {
                case 429:
                case 422:
                case 400:
                    if (_.has(data, 'error')) {
                        bootbox.alert(data.error);
                    } else {
                        $scope.validationErrors = data;
                    }
                    break;

                default:
                    bootbox.alert("Unable to process your request at this time. Please try again.");
            }
        });
    };


    SearchConnector.realFilterMethod = $scope.doFilter = function () {
        $scope.pagination = {current_page: 1, total: 0};
        return $scope.load();
    };

    $timeout($scope.doFilter);

    $scope.enqueue = function () {
        //OrderQueueService.addOrder()
    };

    $scope.buy = function (note) {
        if (!UserService.isAuthenticated()) {
            bootbox.alert("You must sign in and upgrade to buy secondary market listings.");
            return;
        }

        if ($scope.account == null) {
            bootbox.alert("Please select an account to buy with.");
            return;
        }

        bootbox.confirm('Please confirm to proceed buying Note ID ' + note.noteId + ' for $' + note.price + '...', function (r) {

            if (r) {
                API.post('secondary/buy', {
                    accountId: $scope.account.account_id,
                    noteId: note.noteId,
                    loanId: note.loanId,
                    orderId: note.orderId,
                    bidPrice: note.price
                }).then(function (response) {
                    if (response.data.success) {
                        bootbox.alert("Note order queued");
                    } else {
                        bootbox.alert("Failed: Unable to purchase Loan ID " + note.loanId);
                    }
                });
            }
        });

    }

    $scope.lcBand = function (grade) {
        switch (grade.substring(0, 1)) {
            case 'A':
                return 'loan-grade lc-a';
                break;
            case 'B':
                return 'loan-grade lc-b';
                break;
            case 'C':
                return 'loan-grade lc-c';
                break;
            case 'D':
                return 'loan-grade lc-d';
                break;
            case 'E':
                return 'loan-grade lc-e';
                break;
            case 'F':
                return 'loan-grade lc-f';
                break;
            case 'G':
                return 'loan-grade lc-g';
                break;

        }
    }

    $scope.prosperBand = function (grade) {
        switch (grade) {
            case 'AA':
                return 'loan-grade p-aa';
                break;
            case 'A':
                return 'loan-grade p-a';
                break;
            case 'B':
                return 'loan-grade p-b';
                break;
            case 'C':
                return 'loan-grade p-c';
                break;
            case 'D':
                return 'loan-grade p-d';
                break;
            case 'E':
                return 'loan-grade p-e';
                break;
            case 'HR':
                return 'loan-grade p-hr';
                break;

        }
    }

}]);

nsr.controller('PrimaryMarketController', ['$scope', 'SearchConnector', 'UserService', 'API', '$stateParams', '$timeout', '$window', function ($scope, SearchConnector, UserService, API, $stateParams, $timeout, $window) {
    SearchConnector.setVendorCallBack = function (vendorId) {
        $scope.vendorId = vendorId;
    };
    SearchConnector.showSecondaryFilters = false;
    SearchConnector.showPortfolio = false;
    SearchConnector.showSecondaryCount = false;
    SearchConnector.showPrimaryCount = true;
    SearchConnector.setVendorAndLoadStatsHelper($stateParams.platform);

    $scope.pagination = {current_page: 1, total: 0, pageSize: 25};

    $scope.listings = [];

    $scope.load = function () {
        return API.post('markets/primary/' + $stateParams.platform + '?page=' + $scope.pagination.current_page, {
            current_page: $scope.pagination.current_page,
            direction: "asc",
            filter: SearchConnector.getPrimaryFilter().filter,
            order_by: "id",
            pageSize: $scope.pagination.pageSize,
            vendor_id: $scope.vendorId
        }).success(function (response) {
            //console.log(response);
            $scope.listings = response.data;
            $scope.pagination.total = response.total;
            $scope.pagination.total_pages = response.total_pages;
            $scope.pagination.per_page = response.per_page;

            SearchConnector.setResults(null, response.total);
        });
    };

    SearchConnector.realFilterMethod = $scope.doFilter = function () {

        return $scope.load();
    };

    $timeout($scope.doFilter);


    $scope.details = function (id) {

        switch ($stateParams.platform) {
            case 'lendingclub':
                $window.open("https://www.lendingclub.com/browse/loanDetail.action?loan_id=" + id, '_blank');
                break;

            case 'prosper':
                $window.open("https://www.prosper.com/listings#/detail/" + id, '_blank');
                break;
        }
    }

}]);
*/
nsr.controller('SecondarySellController', ['$scope', 'UserService', '$state', 'API', '$timeout', '$uibModal', function ($scope, UserService, $state, API, $timeout, $uibModal) {
    $scope.filters = {};

    $scope.name = '';

    $scope.grade = {};

    //min 3 days max 10
    $scope.duration = 5;

    $scope.payments = {
        min: 0,
        max: 60,
        ceil: 60,
        floor: 0
    };

    $scope.markup = {
        min: 1,
        max: 4,
        ceil: 10,
        floor: -90
    };

    $scope.discount = false;


    $scope.$watch(function () {
            return $scope.markup.max + $scope.markup.min;
        }, function (n, o) {
            if ($scope.markup.max < 0 || $scope.markup.min < 0) {
                $scope.discount = true;
            } else {
                $scope.discount = false;
            }
        },
        true
    );

    $scope.filter = {};

    $scope.inProcess = [];
    $scope.completed = [];

    $scope.account = null;

    API.get('secondary/filters').then(function (response) {
        $scope.filters = response.data;
    });

    $scope.accountChange = function () {
        $scope.inProcess = [];
        $scope.completed = [];

        API.post('secondary/verify', {
            account_id: $scope.account.account_id
        }).then(function (response) {
            $scope.verified = response.data.verified;

            if ($scope.verified) {
                $scope.inProgressSales($scope.account.account_id);
                $scope.completedSales($scope.account.account_id);
            }
        });
    };

    $scope.updateSalesEstimate = function () {
        if (!_.has($scope.account, 'account_id')) {
            return;
        }

        API.post('secondary/sale-estimate',
            {
                account_id: $scope.account.account_id,
                filter: $scope.formatFilter($scope.filter),
                payments: {
                    min: $scope.payments.min,
                    max: $scope.payments.max
                }
            }).then(function (response) {
            $scope.proceeds = response.data;
        });

        $scope.updatePricing();
    };


    $scope.$watch(function () {
        return $scope.filter
    }, $scope.updateSalesEstimate, true);


    UserService.getAccounts().then(function (accounts) {
        $scope.allAccounts = accounts;
    });

    $scope.inProgressSales = function (accountId) {
        API.get('secondary/in-process/' + accountId).then(function (response) {
            $scope.inProcess = response.data;
        });
    };

    $scope.completedSales = function (accountId) {
        API.get('secondary/completed/' + accountId).then(function (response) {
            $scope.completed = response.data;
        });
    };


    $scope.cancel = function (autoSell) {

        API.delete('secondary/selling/' + autoSell.auto_sell_id).then(function () {
            $scope.inProgressSales($scope.account.account_id);
            $scope.completedSales($scope.account.account_id);
        });
    };


    $scope.save = function () {
        if ($scope.account === null) {
            bootbox.alert("Select an account to proceed");
            return;
        }

        bootbox.confirm("<b>Warning!</b> By continuing your notes will be listed on the secondary market for sale.", function (r) {
            if (r) {
                API.post('secondary/sell',
                    {
                        name: $scope.name,
                        account_id: $scope.account.account_id,
                        filter: $scope.formatFilter($scope.filter),
                        duration: $scope.duration,
                        markup: {
                            min: $scope.markup.min,
                            max: $scope.markup.max
                        }
                    }).then(function (response) {
                    $scope.inProgressSales($scope.account.account_id);
                    bootbox.alert("Sell Strategy Saved");
                });
            }
        });
    };

    $scope.formatFilter = function (filter) {
        var result = {};

        _.each(filter, function (values, key) {

            _.each(values, function (isSet, k) {
                if (result[key] === undefined && isSet) {
                    result[key] = [];
                }

                if (isSet) {
                    result[key].push(k);
                }
            });
        });

        result.payments = {
            min: $scope.payments.min,
            max: $scope.payments.max
        };

        return result;
    };

    $scope.updatePricing = function () {
        API.post('secondary/pricing',
            {
                filter: $scope.formatFilter($scope.filter),
                payments: {
                    min: $scope.payments.min,
                    max: $scope.payments.max
                }
            }).then(function (response) {
            updatePricing(response.data);
        });
    };

    $scope.updatePricing();


    function updatePricing(pricing) {
        //console.log($window._folio);


        var age = _.pluck(pricing, 'age');
        var premium = _.pluck(pricing, 'premium');
        var count = _.pluck(pricing, 'count');


        $('#container').highcharts({
            chart: {
                zoomType: 'xy',
                style: {
                    fontFamily: 'NSR'
                    //fontWeight: 'normal'
                }
            },
            credits: {
                enabled: false,
                text: 'NSR Platform'
            },
            title: {
                text: 'Markup/Discount vs Age'
            },
            subtitle: {
                text: 'Lending Club Secondary Market'
            },
            xAxis: [{
                categories: age, // remaining payments
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: '#356493'
                    }
                },
                title: {
                    text: 'Total Notes Available',
                    style: {
                        color: '#356493'
                    }
                },
                opposite: true

            }, { // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: 'Markup/Discount',
                    format: '{value}',
                    style: {
                        color: '#7D7C84'
                    }
                },
                labels: {
                    format: '{value} %',
                    style: {
                        color: '#7D7C84'
                    }
                }

            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                x: 0,
                verticalAlign: 'top',
                y: 0,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{ // total loans
                name: 'Total Notes Available',
                type: 'column',
                yAxis: 0,
                data: count,
                tooltip: {
                    valueSuffix: ' '
                }

            }, { // Premium
                name: 'Markup/Discount',
                type: 'spline',
                yAxis: 1,
                data: premium,
                marker: {
                    enabled: false
                },
                dashStyle: 'shortdot',
                tooltip: {
                    valueSuffix: ' %'
                }

            }]
        });
    }


    $scope.view = function (strategy) {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'view-sell-strategy.html',
            controller: 'ViewSellController',
            size: 'md',
            resolve: {
                strategy: strategy,
                account: $scope.account
            }
        });
    }

}]);


nsr.controller('ViewSellController', ['$scope', 'API', 'strategy', 'account', 'AccountService', '$uibModalInstance', function ($scope, API, strategy, account, AccountService, $uibModalInstance) {
    $scope.account = account;


    /*
    AccountService.refresh().then(function(ok) {
        $scope.account = _.find(AccountService.accounts, function(a) {
            return a.account_id = strategy.account_id;
        });
    });*/

    $scope.filterFormat = function (filter) {
        var out = '';
        _.each(filter, function (obj, key) {

            out += '<div>';
            out += '<b>' + key.toUpperCase() + '</b>: ';

            if (_.isArray(obj)) {
                out += obj.join(', ');
            } else {
                out += obj.min + ' - ' + obj.max;
            }
            out += '</div>';

        });
        return out;
    };

    $scope.strategy = strategy;

    $scope.close = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('StatementController', ['$scope', 'API', function ($scope, API) {
    $scope.page = 1;
    $scope.statements = [];

    $scope.next_page_url = null;
    $scope.prev_page_url = null;
    $scope.current_page = 1;


    $scope.load = function (url) {
        if (url === null) {
            url = 'statements';
        }

        API.get(url).then(function (response) {

            $scope.statements = response.data.data;
            $scope.next_page_url = response.data.next_page_url;
            $scope.prev_page_url = response.data.prev_page_url;
            $scope.current_page = response.data.current_page;
        })
    };

    $scope.next = function () {
        $scope.load($scope.next_page_url);
    };

    $scope.previous = function () {
        $scope.load($scope.prev_page_url);
    };

    $scope.load(null);

}]);
// @todo remove
nsr.controller('BillingController', ['$scope', '$http', 'API', 'UserService', 'stripe', '$window', '$timeout', '$uibModal', function ($scope, $http, API, UserService, stripe, $window, $timeout, $uibModal) {
    $scope.edit = false;
    $scope.saving = false;

    $scope.payment = {
        type: 'ach'
    };


    $scope.cardType = false;

    stripe.setPublishableKey($window._stripe_publishable);

    $scope.loadPaymentMethods = function () {
        API.get('billing/payment-sources').then(function (response) {
            if (response.data.missing) {
                $scope.edit = true;
            } else {
                $scope.paymentMethods = response.data;
            }
        });
    };

    if (UserService.isAuthenticated()) $scope.loadPaymentMethods();

    $scope.tokenizePaymentSource = function () {

        $scope.saving = true;

        $timeout(function () {
            $scope.saving = false;
        }, 2000);

        var stripePromise = null;
        if ($scope.payment.type == 'cc') {
            stripePromise = stripe.card.createToken(
                {
                    number: $scope.payment.number,
                    cvc: $scope.payment.cvc,
                    exp_month: $scope.payment.expiry.split('/')[0],
                    exp_year: $scope.payment.expiry.split('/')[1]
                }
            );
        }

        if ($scope.payment.type == 'ach') {
            stripePromise = stripe.bankAccount.createToken(
                {
                    country: 'US',
                    currency: 'USD',
                    routing_number: $scope.payment.routing_number,
                    account_number: $scope.payment.account_number,
                    name: $scope.payment.name,
                    account_holder_type: 'individual'
                }
            );
        }

        if (stripePromise != null) stripePromise.then(function (response) {
            API.post('billing/add-payment-source', response).then(function (response) {
                $scope.payment = {type: 'ach'};
                $scope.loadPaymentMethods();
                bootbox.alert("Payment Method Saved!");
                $scope.edit = false;
                if (!UserService.getState().billing) UserService.refreshState();
            });
        }, function (response) {
            bootbox.alert(response);
        });

    };

    $scope.verifyDeposits = function () {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '_verify.html',
            controller: 'DepositVerifyController',
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

        modalInstance.result.then(function (deposits) {
            API.post('billing/verify', deposits).then(function (response) {
                bootbox.alert("Checking Account is now verified");

            });
        }, function () {
            bootbox.alert("Failed to verify deposits");
        });

    };

    $scope.editPayment = function () {
        $scope.edit = true;
    };


}]);

nsr.controller('DepositVerifyController', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.deposits = {
        first: null,
        second: null
    };

    $scope.verify = function () {
        $uibModalInstance.close($scope.deposits);
    };
    /* Model Controls */
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('BalanceController', ['$scope', '$http', 'API', '$stateParams', '$timeout', 'ChartDefinitions', '$uibModal', function ($scope, $http, API, $stateParams, $timeout, ChartDefinitions, $uibModal) {
    $scope.transfers = [];
    $scope.account = {};
    $scope.balanceChart = {};
    $scope.percentage = false;


    $scope.render = function () {
        API.get('balances/' + $stateParams.account_id + '?percentage=' + $scope.percentage).success(function (data, status) {
            $timeout(function () {
                $scope.transfers = data.transfers;
                $scope.account = data.account;

                $scope.balanceChart.series = [
                    {
                        name: 'ACCOUNT TOTAL',
                        data: _.map(data.balances, function (obj) {
                            return [moment(obj.timestamp).unix() * 1000, parseFloat(obj.account_total)]
                        }),
                        marker: {radius: 3},
                        color: '#6d8fd9',
                        events: {
                            click: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_click', 'ACCOUNT TOTAL');
                            },
                            mouseOut: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOut', 'ACCOUNT TOTAL');
                            },
                            mouseOver: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOver', 'ACCOUNT TOTAL');
                            },
                        }
                    },
                    {
                        name: 'OUTSTANDING PRINCIPAL',
                        data: _.map(data.balances, function (obj) {
                            return [moment(obj.timestamp).unix() * 1000, parseFloat(obj.outstanding_principal)]
                        }),
                        marker: {radius: 3},
                        color: '#FFE672',
                        events: {
                            click: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_click', 'OUTSTANDING PRINCIPAL');
                            },
                            mouseOut: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOut', 'OUTSTANDING PRINCIPAL');
                            },
                            mouseOver: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOver', 'OUTSTANDING PRINCIPAL');
                            },
                        }
                    },
                    {
                        name: 'MANAGED PRINCIPAL',
                        data: _.map(data.balances, function (obj) {
                            return [moment(obj.timestamp).unix() * 1000, parseFloat(obj.managed_principal)]
                        }),
                        marker: {radius: 3},
                        color: '#cddff7',
                        events: {
                            click: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_click', 'MANAGED PRINCIPAL');
                            },
                            mouseOut: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOut', 'MANAGED PRINCIPAL');
                            },
                            mouseOver: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOver', 'MANAGED PRINCIPAL');
                            },
                        }
                    },
                    {
                        name: 'PENDING',
                        data: _.map(data.balances, function (obj) {
                            return [moment(obj.timestamp).unix() * 1000, parseFloat(obj.pending)]
                        }),
                        marker: {radius: 3},
                        color: '#8870DB',
                        events: {
                            click: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_click', 'PENDING');
                            },
                            mouseOut: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOut', 'PENDING');
                            },
                            mouseOver: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOver', 'PENDING');
                            },
                        }
                    },
                    {
                        name: 'CASH',
                        data: _.map(data.balances, function (obj) {
                            return [moment(obj.timestamp).unix() * 1000, parseFloat(obj.cash)]
                        }),
                        marker: {radius: 3},
                        color: '#F4604D',
                        events: {
                            click: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_click', 'CASH');
                            },
                            mouseOut: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOut', 'CASH');
                            },
                            mouseOver: function () {
                                window.ga('send', 'event', 'balance_history', 'chart_mouseOver', 'CASH');
                            },
                        }

                    }

                ];
            });
        });
    };

    $scope.switching = function (switcher, label) {
        window.ga('send', 'event', 'balance_history', 'switching ' + switcher, label);
    }

    $scope.render();

    ChartDefinitions.initHighChartsDashboardTheme();
    $scope.balanceChart = ChartDefinitions.getBalanceDetailedChartDefinition();


    $scope.add = function () {
        window.ga('send', 'event', 'balance_history', 'click__add_transfer_manually__button');
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '_record-transfer.html',
            controller: 'RecordTransferController',
            size: 'sm',

            resolve: {
                account: function () {
                    return $scope.account;
                }
            }
        });

        modalInstance.result.then(function () {
            $scope.render();
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    $scope.removeTransfer = function (transfer) {

        bootbox.confirm("Remove transfer amount " + transfer.amount, function (r) {
            if (r) {
                API.delete('transfers/' + transfer.transfer_id).then(function () {
                    $scope.render();
                });
            }
        })
    }
}
]);

// This controller should not be needed, but apparenty no one things knowing when you added money is import.
nsr.controller('RecordTransferController', ['$scope', '$uibModalInstance', 'account', 'API', function ($scope, $uibModalInstance, account, API) {
    $scope.transfer = {};


    $scope.account = account;
    $scope.record = function () {
        API.post('transfers/' + $scope.account.account_id, $scope.transfer).then(function () {
            $scope.errors = {};
            $uibModalInstance.close();
        }, function (response) {
            $scope.errors = response.data.errors;
        });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.getErrors = function (name) {
        if (!_.isUndefined($scope.errors[name])) {
            return $scope.errors[name][0];
        }
    };
}]);

nsr.controller('NavController', ['$scope', 'UserService', '$state', 'LoginService', function ($scope, UserService, $state, LoginService) {
    $scope.siteName = 'NSR';

    $scope.endSession = function () {
        $scope.menuItemClick('logout');
        LoginService.endSession();
    };

    // $scope.openSupport = function () {
    //     Intercom('show');
    // }

    $scope.menuItemClick = function (name) {
        if (window.ga) {
            window.ga('send', 'event', 'menu_click', name, new Date());
        }
    }

}]);


nsr.factory('ClientSignup', ['API', 'UserService', '$q', function (API, UserService, $q) {

    var service = {};
    service.client = {};

    function reload() {
        return API.get('signup/client').success(function (data) {
            service.client = data;
            //console.log(data);
        }).then();  //the 'then' makes a new promise that happens after this promise finishes
    }

    service.promise = reload();


    service.createLogin = function () {
        if (service.client.password != service.client.password_confirmation) {
            bootbox.alert('Passwords do not match, please retype your password.');
            var deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
        }

        var client = angular.copy(service.client);
        delete client.password_confirmation;
        return API.post('signup/create-user', client).then(function () {

            //log the user in
            return UserService.authInternal(client.email, client.password);
        });
    };

    return service;
}]);


nsr.controller('SettingsController', ['API', 'UserService', '$scope', '$log', '$auth', '$state', '$stateParams', '$window', '$uibModal', '$timeout', function (API, UserService, $scope, $log, $auth, $state, $stateParams, $window, $uibModal, $timeout) {
    $scope.userProfile = {
        extended: {}
    };

    $scope.userService = UserService;


    $scope.password = {};

    $scope.selectedTab = {};
    $scope.selectedTab[$state.$current.name] = true;

    $scope.setTab = function (tab) {
        $state.go(tab);
    };

    $scope.notificationFrequency = 'realtime';

    $scope.payment = {
        type: 'cc'
    };

    UserService.getProfile().then(function (data) {
        $scope.userProfile = data;
    });


    /** Service Methods **/
    $scope.updateProfile = function () {
        if ($scope.userProfile.extended === null) {
            $scope.userProfile.extended = {};
        }

        API.post('profile', _.pick($scope.userProfile, 'email', 'extended', 'first_name', 'last_name')).success(function (data, status) {
            bootbox.alert("Profile Updated");
            if (!UserService.getState().profile) {
                UserService.refreshState();
                $scope.selectedTab['settings.profile'] = false;
                $scope.selectedTab['settings.agreements'] = true;
                $state.go("settings.agreements");
            }
        });
    };

    $scope.updateNotifications = function () {

        API.post('notifications', $scope.userProfile.notifications).success(function (data, status) {
            bootbox.alert("Notifications Updated");
        });
    };

    $scope.billing = function () {
        API.put('billing', $scope.payment).then(function (response) {
            $log.debug(response.data);
        });
    };

    /*
     API.get('billing/sources/' + $auth.getPayload().user_id).then(function(response){

     });*/

    $scope.deleteAccount = function () {
        bootbox.prompt("Close your account will stop all auto-investing and portfolio monitoring. Type \"DELETE\" to close your account. This can not be undone!",
            function (r) {
                if (r == 'DELETE') {
                    API.delete('user').then(function () {
                        $auth.logout();
                        $state.go('landing');
                    });

                }
            });
    };

    /* Two-Factor */

    $scope.disableTwoFactor = function () {
        bootbox.confirm("Are you sure you want to disable Two Factor authentication. This action is not recommended", function (r) {
            if (r) {
                API.post('twofactor/disable').then(function () {
                    $scope.userProfile.two_factor = false;
                });
            }
        });
    };

    $scope.enableTwoFactor = function () {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'partials/twofactor.html',
            controller: 'TwoFactorEnrollController',
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

        modalInstance.result.then(function (response) {
            $scope.userProfile.two_factor = response.data.enrolled;
        }, function () {
            // clear auth
        });
    };

    /* Password */

    $scope.changePassword = function () {
        API.post('user/change-password', $scope.password).then(function () {
            bootbox.alert("Your password has been changed");
            $scope.password = {};
        });
    };

    $scope.downgrade = function () {
        API.get('user/closure-fees').then(function (response) {
            var msg = '';
            var fees = response.data.fees;

            if (fees > 0) {
                msg = "There is $" + fees + " in pending fees. To close your account your billing source will be charged. Auto-investing will stop. You can resume at anytime.";
            } else {
                msg = "Your account will be downgraded to analytics only, auto-investing will stop. You can resume at anytime."
            }

            bootbox.confirm('<b>' + msg + '</b>', function (r) {
                if (r) {
                    API.put('user/downgrade').then(function (response) {
                        bootbox.alert("Account successfully downgraded");
                        UserService.refreshState().then(function (data) {
                            UserService.getProfile().then(function (data) {
                                $scope.userProfile = data;
                            });
                        });
                    });
                }
            });
        });
    }


}]);

nsr.controller('PricingController', ['$scope', function ($scope) {


    $scope.fees = 0;

    $scope.fullyManaged = false;

    $scope.slider = {
        min: 0,
        value: 0,
        floor: 0,
        interval: 500,
        ceil: 1000000,
        step: 5000,
        translate: function (value) {
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        update: function () {
            if ($scope.fullyManaged) {
                $scope.fees = (this.min * 0.006) / 4;
            } else {
                if (this.min <= 20000) {
                    $scope.fees = 0;
                } else {
                    $scope.fees = (this.min * 0.0045) / 4;
                }
            }

        }

    };

}]);

nsr.controller('TwoFactorEnrollController', ['$scope', '$uibModalInstance', 'API', '$window', function ($scope, $uibModalInstance, API, $window) {
    $scope.verify = '';
    $scope.verifyTwoFactor = function () {

        API.post('twofactor/verify', {
            secret: $window._secret,
            verify: $scope.verify
        }).then(function (response) {
            $uibModalInstance.close(response);
        }, function (response) {
            bootbox.alert("Failed to Enroll your Account, check your 6 digit 2FA code");
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);


nsr.controller('SignupController', ['$scope', '$location', 'ClientSignup', 'API', '$state', '$auth', 'UserService', '$uibModal', '$window', function ($scope, $location, ClientSignup, API, $state, $auth, UserService, $uibModal, $window) {


    $scope.done = false;
    $scope.step = 1;
    $scope.ClientSignup = ClientSignup;
    $scope.errors = [];
    $scope.steps = [{
        sref: 'account',
        name: 'Account Type'
    }]; // Initial State

    $scope.fromLR = $location.search().from && $location.search().from === "lendingrobot";

    var modalHtml = '<div class="modal-header">' +
        '<a class="close" ng-click="$dismiss()">×</a>' +
        '<h4 class="modal-title">' + 'The LendingRobot and NSR Invest platforms have merged!</h4>' +
        '</div>' +
        '<div class="modal-body">' +
        '<p>You were redirected from the LendingRobot website as the LendingRobot ' +
        'and NSR Invest platforms have merged!</p>' +
        '<p>We look forward to helping you achieve your investment goals!' +
        ' Please enter your information below to get started.</p>' +
        '</div>';

    modalHtml += '<div class="modal-footer"><button' +
        ' class="btn btn-primary" ng-click="ok()' +
        '">OK</button></div>';

    if ($scope.fromLR) {
        var modalInstance = $uibModal.open({
            animation: true,
            template: modalHtml,
            controller: 'MigrationMessageController'
        });
    }

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    if (getParameterByName('utm_source') !== 'lendingrobot') {
        bootbox.confirm({
            message: "I am US citizen or Permanent Resident",
            buttons: {
                cancel: {
                    label: 'No',
                    className: 'btn-success'
                },
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
            },
            callback: function (result) {
                if (result === false) {
                    $window.location.href = 'https://lendingrobot.com/';
                }
            }
        });
    }

    $scope.isAuthenticated = function () {
        return $auth.isAuthenticated();
    };


    $scope.done = function () {
        // Do something special when the application is completed
    };

    $scope.next = function () {

        if ($scope.step > $scope.steps.length) {
            alert('end');
            $state.go('index');
            return;
        }

        var current = $scope.steps[$scope.step - 1].sref;

        switch (current) {
            case 'create':
                if (!ClientSignup.client.provisioned) {
                    API.post('signup/create', ClientSignup.client).success(function (data) {
                        $auth.setToken(data.auth_token);
                        ClientSignup.client = data;
                    });
                }
                break;

            case 'profile':
                API.post('signup/profile', ClientSignup.client).success(function (data) {

                });
                break;

            case 'funds':
                API.post('signup/funds', ClientSignup.client).success(function (data) {

                });
                break;

            case 'legal':
                API.post('signup/legal', ClientSignup.client).success(function (data) {

                });
                break;

            case 'guidance':
                API.post('signup/guidance', ClientSignup.client).success(function (data) {

                });
                break;

            case 'account':
                // At this point all we do it select the account
                break;


            default:
                bootbox.alert("Unknown step: " + $scope.step + ".) " + current);
        }

        var next = $scope.steps[$scope.step].sref;
        $state.go('signup.' + next);
        $scope.step += 1;
    };

    $scope.previous = function () {
        $scope.step -= 1;
        var previous = $scope.steps[$scope.step - 1].sref;
        $state.go('signup.' + previous);

    };

    $scope.totalSteps = function () {
        return $scope.steps.length;
    };

    $scope.setAccountType = function (type) {
        $scope.step = 1;
        switch (type) {
            case 'analysis-user':
                $scope.steps = [{
                    sref: 'account',
                    name: 'Account Type'
                }, {
                    sref: 'create',
                    name: 'Login'
                }, {
                    sref: 'profile',
                    name: 'Profile'
                }, {
                    sref: 'legal',
                    name: 'Legal'
                }];
                break;

            case 'self-directed':
                $scope.steps = [{
                    sref: 'account',
                    name: 'Account Type'
                }, {
                    sref: 'create',
                    name: 'Login'
                }, {
                    sref: 'profile',
                    name: 'Profile'
                }, {
                    sref: 'funds',
                    name: 'Funds'
                }, {
                    sref: 'guidance',
                    name: 'Guidance'
                }, {
                    sref: 'legal',
                    name: 'Legal'
                }];
                break;

            default:
                $scope.steps = [{
                    sref: 'account',
                    name: 'Account Type'
                }, {
                    sref: 'create',
                    name: 'Login'
                }, {
                    sref: 'profile',
                    name: 'Profile'
                }, {
                    sref: 'funds',
                    name: 'Funds'
                }, {
                    sref: 'guidance',
                    name: 'Guidance'
                }, {
                    sref: 'legal',
                    name: 'Legal'
                }];
        }
        $scope.ClientSignup.client.account_type = type;
        $scope.next();
    };


    $scope.getAccountType = function () {
        if ($scope.ClientSignup.client.account_type) {
            return $scope.ClientSignup.client.account_type.replace('-', ' ');
        }
        return false;
    };

    $scope.isFullyManaged = function () {
        return ($scope.ClientSignup.client.account_type != 'analysis-user' && $scope.ClientSignup.client.account_type != 'self-directed');
    };

    $scope.isProvisioned = function () {
        $scope.ClientSignup.client.provisioned;
    };


    $scope.createLogin = ClientSignup.createLogin;

}]);

nsr.controller('MigrationMessageController', ['$scope', 'API', 'UserService', '$uibModalInstance', 'ErrorHandler', function ($scope, API, UserService, $uibModalInstance, ErrorHandler) {
    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('StrategyController', ['$scope', 'API', '$log', 'UserService', 'VendorService', function ($scope, API, $log, UserService, VendorService) {
    $scope.slideovers = {
        settings: false
    };

    $scope.search = {
        name: ''
    };

    $scope.display = {
        showCriteria: false
    };

    $scope.selected = {};


    $scope.userFilter = {};
    $scope.strategies = [];

    $scope.load = function () {
        return $scope.transition.showToastUntilCompleted(API.get('strategies').then(function (response) {
            $scope.strategies = response.data;
        }), 'Loading Strategies');
    };


    $scope.transition.flagInProgressUntilCompleted($scope.load());

    $scope.strategy = function (strategy) {
        $scope.slideovers.settings = true;
        $log.info(strategy);
        $scope.userFilter = strategy;
    };

    var accounts = [];

    UserService.getAccounts().then(function (response) {
        accounts = response;
    });

    // Update strategy name
    $scope.update = function () {
        API.put('userfilters/' + $scope.userFilter.user_filter_id, {
            name: $scope.userFilter.name
        }).then(function (response) {
            $scope.slideovers.settings = false;
            UserService.refreshUserFilters(u.vendor_id);
            bootbox.alert("Strategy name updated");
        });
    };

    $scope.getAccounts = function (vendorId) {
        if (_.isUndefined(vendorId)) {
            return accounts;
        } else {
            return _.filter(accounts, function (account) {
                return account.vendor_id == vendorId;
            })
        }
    };


    $scope.deleteUserFilter = function (u) {
        bootbox.confirm('Remove Strategy "' + u.name + '"? Associated accounts will no longer use this strategy.', function (r) {
            if (r) {
                API.delete('userfilters/' + u.user_filter_id).then(function (response) {
                    bootbox.alert("Strategy removed.");
                    $scope.load();
                    UserService.refreshUserFilters(u.vendor_id);
                });
            }
        });
    };


    $scope.edit = function (ai) {
        $scope.selected = angular.copy(ai);
    };

    $scope.cancel = function () {
        $scope.selected = {};
    };

    $scope.getTemplate = function (ai) {
        if (ai.auto_invest_id == $scope.selected.auto_invest_id) {
            return 'edit';
        }

        return 'view';
    };

    $scope.enabled = function (s) {
        var count = 0;
        _.each(s, function (obj) {
            if (obj.enabled) {
                count++;
            }
        });

        return count;
    };

    $scope.getRouteKeyForId = VendorService.getRouteKeyForId;

}]);

nsr.controller('StrategySubController', ['$scope', 'API', '$log', 'UserService', function ($scope, API, $log, UserService) {
    $scope.add = false;
    $scope.userFilter = {};

    $scope.autoInvest = {
        'enabled': true
    };

    $scope.createAutoInvest = function (s) {
        $scope.autoInvest.user_filter_id = s.user_filter_id;
        API.post('autoinvests', $scope.autoInvest).then(function (response) {
            $scope.load();
        });
    };

    $scope.deleteAutoInvest = function (autoInvestId) {
        bootbox.confirm('Remove account from this strategy? Your account will <b>not</b> be removed.', function (r) {
            if (r) {
                API.delete('autoinvests/' + autoInvestId).then(function (response) {
                    bootbox.alert("Strategy removed from account.");
                    $scope.load();
                });
            }
        });
    };


    $scope.updateAutoInvest = function () {
        API.put('autoinvests/' + $scope.selected.auto_invest_id, $scope.selected).then(function (response) {
            $scope.$parent.selected = {};
            $scope.load();
        });
    };

    $scope.setAdd = function (userFilter) {
        $scope.add = true;
        $scope.userFilter = userFilter;
    };

    $scope.cancelAdd = function () {
        $scope.add = false;
        $scope.userFilter = {};
    };

    $scope.getAddTemplate = function () {
        if ($scope.add) {
            return 'add';
        }
        return 'add-button';
    };
}]);

nsr.controller('UserStateController', ['$scope', 'API', '$timeout', 'UserService', function ($scope, API, $timeout, UserService) {
    $scope.hidden = false || localStorage.getItem('hideUpgrade');

    if (!$scope.hidden) {
        if (UserService.getState().completed) {
            $scope.hidden = true;
        }
    }

    $scope.hide = function () {
        if ($scope.hidden) return true;

        if (UserService.getState().completed) {
            $scope.hidden = true;
        }

        return $scope.hidden;
    };

    $scope.cancel = function () {
        bootbox.alert("You can upgrade later by visiting \"Settings\"", function () {
            $timeout(function () {
                $scope.hidden = true;
                localStorage.setItem('hideUpgrade', true);
            });
        });
    };

    $scope.state = function () {
        return UserService.getState();
    }

}]);

nsr.controller('AgreementController', ['API', 'UserService', '$auth', '$scope', '$state', function (API, UserService, $auth, $scope, $state) {
    $scope.signature = null;
    $scope.consent = false;

    $scope.sign = function () {
        if ($scope.consent) {
            API.post('agreements', {signature: $scope.signature}).then(function (response) {
                var state = UserService.getState();
                if (!state.agreement) {
                    UserService.refreshState();
                    if (!state.billable) {
                        $scope.selectedTab['settings.agreements'] = false;
                        $scope.selectedTab['settings.billing'] = true;
                        $state.go('settings.billing')
                    }
                }
            });
        } else {
            bootbox.alert("You must agree to the investor agreement to sign");
        }
    }
}]);

/**
 * Move Money in and out of account
 */
nsr.controller('TransferController', ['API', '$state', '$scope', '$uibModalInstance', 'accountId', function (API, $state, $scope, $uibModalInstance, accountId) {


    $scope.transfer = {
        type: 'deposit',
        transferAmount: null
    };

    $scope.loading = true;

    $scope.bankAccount = null;


    API.get('transfers/' + accountId + '/pending').then(function (response) {
        $scope.transfers = response.data;
        $scope.loading = false;
    });

    API.get('transfers/' + accountId + '/bank-accounts').then(function (response) {
        $scope.bankAccount = response.data[0];
    });

    $scope.ok = function () {
        $uibModalInstance.close($scope.transfer);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('BankingController', ['API', '$state', '$scope', '$uibModalInstance', function (API, $state, $scope, $uibModalInstance) {
    $scope.banking = {
        accountType: 'Checking',
        amount: null,
        routingNumber: null,
        accountNumber: null
    };


    $scope.ok = function () {
        $uibModalInstance.close($scope.banking);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

nsr.controller('InvoiceController', ['UserService', '$state', '$scope', 'API', function (UserService, $state, $scope, API) {
    $scope.invoices = [];

    API.get('invoices').then(function (response) {
        $scope.invoices = response.data;
    })


}]);


nsr.controller('SignupWizardController', ['$scope', '$http', 'API', '$log', 'UserService', 'ClientSignup', 'AccountSingletonService', 'AccountService', '$stateParams', '$timeout', '$state', '$uibModal', '$location',
    function ($scope, $http, API, $log, UserService, ClientSignup, AccountSingletonService, AccountService, $stateParams, $timeout, $state, $uibModal, $location) {

        //var fakeState = {
        //    profile: true,
        //    agreement: false
        //};
        //
        //var authenticated = false;
        //
        ////override user service for this page just to demo it
        //$scope.user = {
        //    isAuthenticated: function() { return authenticated; },
        //    getState : function() { return fakeState; }
        //};

        //link the service in so the user registration process works as expected
        $scope.ClientSignup = ClientSignup;

        $scope.isAuthenticated = function () {
            return $scope.user.isAuthenticated();
        };


        //some of the included views will pick this up and act accordingly
        $scope.wizard = "Wizard";


        $scope.step = 1;
        $scope.completed_step = [false, false, false, false, false];


        //stub/helper to test walking through steps
        $scope.nextStep = function () {
            $scope.completed_step[$scope.step] = true;
            $scope.step++;
        };


        //we are going to be adding a new account, so this should be nulled out
        AccountSingletonService.setAccount(null);


        $scope.createUserInProgress = false;
        $scope.userProfile = {};
        $scope.accountType = null;
        $scope.userType = null;

        $scope.setAccountType = function (accountType) {
            $scope.accountType = accountType;
        };

        var loadProfile = function () {
            return UserService.getProfile().then(function (profile) {
                $scope.createUserInProgress = false;
                $scope.userProfile = profile;
            });
        };

        //use the client signup mechanism to create the user
        $scope.createLogin = function () {
            $scope.createUserInProgress = true;
            ClientSignup.createLogin().then(loadProfile, function () {
                $scope.createUserInProgress = false;
            });
        };

        $scope.setUserType = function (userType) {
            $scope.step = 1;
            $scope.userType = userType;
        };


        $scope.selectedCss = function (variableValue, value) {
            if (variableValue == value) {
                return {'border': '8px solid', 'border-color': 'lightblue'}
            } else {
                return {'margin': '8px'}
            }
        };


        /**
         * Initialization Code to run on controller startup
         */

        //if already signed up/logged in, just load the profile since the creation will not run
        if (UserService.isAuthenticated()) loadProfile();


        //allow account type to be passed in
        if ($location.search()['accountType'] != null) {
            $scope.accountType = $location.search()['accountType'];
        }

    }
]);
