

nsr.controller('StatsController', ['$scope', 'SearchConnector', 'UserService', '$state', '$stateParams', 'API', '$http', '$uibModal', '$log', '$timeout', 'toastr', 'FilterService', '$location', '$analytics',
    function ($scope, SearchConnector, UserService, $state, $stateParams, API, $http, $uibModal, $log, $timeout, toastr, FilterService, $location, $analytics) {
        $scope.validationErrors = {};
        $scope.criteriaSearch = '';

        SearchConnector.setVendorCallBack = function(vendorId) {
            $scope.vendor_id = vendorId;
        };
        SearchConnector.showSecondaryFilters = false;
        SearchConnector.showPortfolio = true;
        SearchConnector.showSecondaryCount = true;
        SearchConnector.showPrimaryCount = true;
        $scope.statsHelper = SearchConnector.setVendorAndLoadStatsHelper($stateParams.platform);



        $scope.results = {
            breakdowns: []
        };

        $scope.volumeResults = { };

        /* dropup/down views */
        $scope.dropdownTemplates = {
            columns: 'columns.html',
            assumptions: 'assumptions.html',
            portfolios: null,
            breakdownViews : null,
            monthlyOptions: 'monthlyOptions.html'
        };

        /* Input sliders for ROI and Count */
        $scope.sliders =  {
            roi: {
                ceil: 20,
                value: 0,
                interval: 0.5
            },
            count: {
                ceil: 20000,
                value: 0,
                floor: 0,
                interval: 5000
            },
            heatMap: false
        };

        var now = new Date();
        var endMonth = now.getFullYear() * 12 + now.getMonth(); //end month = this month
        var startMonth = 2007 * 12 + 6;         //start month = July 2007

        $scope.dateDisplay = {};
        _.each(_.range(endMonth, startMonth, -1), function(i) { $scope.dateDisplay[i] = ((i%12+1 < 10) ? '0' : '') + (i%12+1) + "/" + Math.floor(i/12); });

        $scope.termRange = _.range(1, 70);  //add a little extra for late loans
        $scope.dateRange = _.range(endMonth, startMonth, -1);
        $scope.loanLevelMonthlyOptions = {
            loanMonthEnd: null, loanMonthStart: null,
            historicalDateStart: null, historicalDateEnd: null,
            lateStartIncludeOnlyCurrent: true
        };

        $scope.isHistoricalActive = function() {
            return (
                $scope.loanLevelMonthlyOptions.loanMonthEnd != null ||
                $scope.loanLevelMonthlyOptions.loanMonthStart != null ||
                $scope.loanLevelMonthlyOptions.historicalDateStart != null ||
                $scope.loanLevelMonthlyOptions.historicalDateEnd != null
            ) ? {'color': 'red'} : {};
        };

        $scope.clearMonthlyHistorical = function(value) {
            if (value == null || value == "") return;
            $scope.loanLevelMonthlyOptions.historicalDateStart = null;
            $scope.loanLevelMonthlyOptions.historicalDateEnd = null;
        };

        $scope.clearMonthlyFixed = function(value) {
            if (value == null || value == "") return;
            $scope.loanLevelMonthlyOptions.loanMonthEnd = null;
            $scope.loanLevelMonthlyOptions.loanMonthStart = null;
        };


        $scope.isPortfolio = function() {
            return SearchConnector.portfolio !== null;
        };

        $scope.portfolio = function() {
            return SearchConnector.portfolio;
        };

        $scope.history = []; // save last 5 runs


        $scope.hasResults = function() {
            return !_.isUndefined($scope.results.breakdowns) && $scope.results.breakdowns.length > 0;
        };

        $scope.checkVolume = function(filter) {
            if (filter.portfolio_id != null) {
                $scope.volumeResults = {};
                return;
            }

            $http({
                    method: 'POST',
                    url: 'api/stats/volume?vendor=' + $scope.vendor_id,
                    data: filter
                }
            ).success(function(data) {
                    $scope.volumeResults = data;
                });
        };


        $scope.addBreakdownToFilter = function(breakdown, row) {
            // console.log(breakdown);
            // console.log(row);
            FilterService.setFilterValues($scope.statsHelper, breakdown.column, row.breakdownValues);
            if (breakdown.maxZoomLevel > breakdown.zoomLevel) {
                var i = _.indexOf($scope.results.breakdowns, breakdown);
                $scope.statsHelper.results.breakdowns[i-1].zoomLevel = breakdown.zoomLevel+1;
            }
            $scope.doFilter(false);
        };


        $scope.lastFilterRequest = "{}";

        $scope.getFilterRequest = function() {
            var filterRequest = SearchConnector.getHistoricalFilter();

            // Always keeps the portfolio separate so it doesn't get mixed in with the user filter
            if(SearchConnector.portfolio !== null) {

                filterRequest.filter.portfolio_id = SearchConnector.portfolio.portfolio_id;

                if(_.has(SearchConnector.portfolio, 'sub_portfolio_id')) {
                    filterRequest.filter.sub_portfolio_id = SearchConnector.portfolio.sub_portfolio_id;
                }
            }

            filterRequest.loanLevelMonthlyOptions = $scope.loanLevelMonthlyOptions;

            return filterRequest;
        };


        // Back Tester
        SearchConnector.realFilterMethod = $scope.doFilter = function(download) {
            var filterRequest = $scope.getFilterRequest();
            $scope.lastFilterRequest = angular.toJson(filterRequest);

            return $http({
                    method: 'POST',
                    url: 'api/stats/filter?vendor=' + $scope.vendor_id + ((download) ? '&download=true' : ''),
                    data: filterRequest
                }
            ).success(function (data, status, headers, config) {
                $scope.validationErrors = {};

                    if (download) {
                        $scope.processing = false;
                        var downloadPopup = window.open('api/stats/download/' + data.file);
                        if (downloadPopup == null || typeof(downloadPopup)=='undefined') {
                            bootbox.alert('Please disable your pop-up blocker and click the "Download" link again.');
                        }
                        else {
                            downloadPopup.focus();
                        }
                    } else {
                        data.fromFilter = filterRequest;    //possibly need this first, before putting on scope so UI can see it
                        $scope.results = data;
                        SearchConnector.setResults(data.folioMatches, data.primaryMatches);
                        $scope.summary = angular.copy(data.breakdowns[0].results[0]);
                        $scope.processing = false;
                        $scope.checkVolume(SearchConnector.getPrimaryFilter().filter);
                    }

                }).error(function (data, status, headers, config) {
                    $scope.summary = false;

                    switch (status) {
                        case 429:
                        case 422:
                        case 400:
                            if(_.has(data, 'error')) {
                                bootbox.alert(data.error);
                            } else {
                                $scope.validationErrors = data;
                            }
                            break;

                        default:
                            bootbox.alert("Unable to process your request at this time. Please try again.");
                    }

                    $scope.processing = false;
                });

        };



        $scope.generateLink = function() {
            var filterRequest = SearchConnector.getFilter();

            API.post('permalink/' + $scope.vendor_id, filterRequest).success(function(data) {
                bootbox.alert(window._rootUrl + "/permalink?l=" + data.hash);
            });
        };

        $scope.zoomBreakdown = function(breakdownIndex, zoomLevel) {



            $scope.results.breakdowns[breakdownIndex].zooming = true;

            //$log.debug(breakdownIndex);
            //$log.debug($scope.results.fromFilter.breakdowns[breakdownIndex-1]);

            //copy the filter we used to run this query, clear out the other breakdowns, and update the zoom level
            var post_data = angular.copy($scope.results.fromFilter);
            post_data.breakdowns = [ post_data.breakdowns[breakdownIndex-1] ];  //index is 1 higher because results have 'All Matching'
            post_data.breakdowns[0].zoomLevel = zoomLevel;

            $http({
                method: 'POST',
                url: 'api/stats/filter?vendor=' + $scope.vendor_id,
                data: post_data
            }).success(function(data, status, headers, config) {
                if (data.breakdowns.length < 2) {
                    if (data.breakdowns.length == 1) $log.warn("breakdown data not available");
                    return;
                }
                $scope.results.breakdowns[breakdownIndex] = data.breakdowns[1];
                $scope.results.fromFilter.breakdowns[breakdownIndex-1].zoomLevel = data.breakdowns[1].zoomLevel;
                $scope.statsHelper.results.breakdowns[breakdownIndex-1].zoomLevel = data.breakdowns[1].zoomLevel;
            }).error(function(data, status, headers, config) {
                bootbox.alert(data);
            });
        };

        // Charting
        function scale(data, multiplier) {
            for (var i = 0 ; i < data.length ; i++) data[i] = data[i] * multiplier;
            return data;
        }

        $scope.charts = {};

        $scope.viewChange = function(view, breakdownIndex) {
            if (breakdownIndex == 0) return;  // All Matching is always a table
            var previousView = $scope.results.fromFilter.breakdowns[breakdownIndex-1].view;
            if (previousView != view) {
                $scope.results.fromFilter.breakdowns[breakdownIndex-1].view = view;
                $scope.statsHelper.results.breakdowns[breakdownIndex-1].view = view;
                if (view != 'table') {
                    //its not a table, so draw the chart!
                    $scope.drawChart($scope.results.breakdowns[breakdownIndex], view)
                }
            }
        };

        $scope.viewRemove = function(breakdownIndex) {
            if (breakdownIndex == 0) return;  // All Matching is always visible
            $scope.statsHelper.results.breakdowns.splice(breakdownIndex-1, 1);
            $scope.results.fromFilter.breakdowns.splice(breakdownIndex-1, 1);
            $scope.results.breakdowns.splice(breakdownIndex, 1);
        };

        $scope.viewOptions = function() {
            var options = _.filter($scope.statsHelper.getBreakdownOptions(), function(filter) {
                return (_.findWhere($scope.results.breakdowns, {column: filter.field}) === undefined);
            });
            //$log.debug("options count: " + options.length);
            return options;
        };

        $scope.viewAdd = function($select) {
            if(!UserService.isAuthenticated()) {
                UserService.upgrade("Custom Breakdowns");
                return;
            }

            if ($select.selected == null) return;

            var field = angular.copy($select.selected);
            delete(field.name);

            $scope.statsHelper.results.breakdowns.push(field);
            $scope.results.fromFilter.breakdowns.push(field);
            $scope.results.breakdowns.push({column:$select.selected.field, description: $select.selected.name, results: [], zoomLevel: 0, maxZoomLevel: 0, stats: null});
            $scope.zoomBreakdown($scope.statsHelper.results.breakdowns.length, field.zoomLevel);

            $select.selected = null;
        };

        $scope.viewCheck = function(view, breakdownIndex) {
            if (breakdownIndex == 0) return (view == 'table');  // All Matching is always a table
            return ($scope.viewLookup(breakdownIndex) == view);
        };

        $scope.viewLookup = function(breakdownIndex) {
            return $scope.results.fromFilter.breakdowns[breakdownIndex-1].view;
        };


        $scope.saveBreakdownView = function() {
            if(!UserService.isAuthenticated()) {
                UserService.upgrade("Saved Custom Breakdowns");
                return;
            }



            bootbox.prompt("Enter a descriptive name for your breakdown view", function(name) {
                if(name === null) {
                    return;
                }

                API.post('breakdown-views/' + $scope.vendor_id,
                    {
                        name: name,
                        breakdown: $scope.statsHelper.results.breakdowns
                    });
            });
        };




        $scope.rowClassModifiers = function(row) {
            if ($scope.sliders.heatMap) return {};
            var sliders = $scope.sliders;
            return { 'row-highlight-roi': (row.roi * 100 ) > sliders.roi.value && sliders.roi.value != 0, 'row-highlight-count': (row.count > sliders.count.value && sliders.count.value != 0) }
        };

        $scope.heatMapModifiers = function(row) {
            var base = $scope.sliders.roi.value;

            if (!$scope.sliders.heatMap || base == 0) return "";

            var min = base * 0.20;
            var max = base * 1.20 - min;

            var location = (row.roi * 100 - min);

            var hue = location / max * 120;
            if (hue > 120) hue = 120;
            if (hue < 0) hue = 0;

            //using hue instead of rgb color
            return {'background-color': "hsla(" + hue + ", 100%, 50%, 0.60)" };


            // var delta = (location - max/2) / (max/2);   //percentage off from center i.e. -50% or +72%
            //
            // var red = 256;
            // var green = 256;
            //
            // var color = 256 - Math.round(Math.abs(delta) * 166);    //color range is 90 to 256
            // if (color < 90) color = 90;
            // if (color > 256) color = 256;
            //
            // var blue = color;
            // if (delta < 0) {
            //     green = color;
            // } else {
            //     red = color;
            // }
            //
            // // console.log("roi: " + row.roi + " :  " + min + " :  " + max + " :  " + location + " :  " + delta + " :  " + blue + " :  " + green + " :  " + red);
            //
            // return {'background-color': "rgba(" + red + "," + green + "," + blue + ", 0.65)" };
        };


        function heatMapColorforValue(value){
            var h = (value) * 140;
            return "hsla(" + h + ", 100%, 50%, 0.65)";
        }


        $scope.drawChart = function(dataset, chartType) {
            $timeout(function() {
                if (chartType == 'ef') {
                    var seriesData = [];
                    var xAxisData = [];
                    var yAxisData = [];


                    var radiusCol = 'count';
                    var radius = _.pluck(dataset.results, radiusCol);
                    var radiusMin = _.min(radius);
                    var radiusMax = _.max(radius);


                    var colorCol = 'loss_roi'
                    var color = _.pluck(dataset.results, colorCol);
                    var colorMin = _.min(color);
                    var colorMax = _.max(color);


                    var colors = [];
                    _.each(dataset.results, function(obj) {


                        var color = heatMapColorforValue(1 - ((obj[colorCol] - colorMin) / (colorMax - colorMin)));

                        colors.push(color);
                        var stdDev = Math.round(obj.std_dev * 1000)/1000;
                        var roi = Math.round(obj.roi * 10000)/100;
                        xAxisData.push(stdDev);
                        yAxisData.push(roi);
                        seriesData.push({
                            name: obj.key_value,
                            data: [{
                                    x: stdDev,
                                    y: roi,
                                    radius: 4 + ((obj[radiusCol] - radiusMin) / (radiusMax - radiusMin) * 14),
                                    color : color,
                                    name: obj.key_value
                                }]
                        });
                    });

                    $('#hc_' + dataset.column).highcharts({
                        colors: colors,
                        credits: {
                            enabled: false
                        },
                        chart: {
                            type: 'scatter',
                            height: 400,
                            style: {
                                fontFamily: 'NSR'
                            }
                        },
                        title: { 
                            text: dataset.description 
                        },
                        subtitle: {
                            text: 'Circle radius: Volume least to greatest; Color: Loss - green less - red more'
                        },
                        legend: {
                            verticalAlign: "bottom",
                            floating: false
                        },
                        xAxis: {
                            // categories: _.range(Math.floor(_.min(xAxisData)), Math.ceil(_.max(xAxisData)), 0.5),
                            // categories: _.range(0, 10, 0.5),
                            min: Math.floor(_.min(xAxisData)),
                            max: Math.ceil(_.max(xAxisData)),
                            crosshair: {
                                dashStyle: 'LongDash',
                                color: '#3A3A3A'
                            },
                            title: { text: 'Standard Deviation (Risk)' },
                        },
                        yAxis: {
                            title: { text: 'ROI % (Reward)' },
                            min: Math.floor(_.min(yAxisData) - 1),
                            max: Math.ceil(_.max(yAxisData) + 1),
                            crosshair: {
                                dashStyle: 'LongDash',
                                color: '#3A3A3A'
                            }
                        },
                        tooltip: {
                            headerFormat: '<table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0"><b>{series.name}</b></td></tr>' +
                            '<tr><td style="padding:0">ROI: <b>{point.y:.2f}%</b> </td></tr>'+
                            '<tr><td style="padding:0">STD DEV: <b>{point.x:.2f}</b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        plotOptions: {
                            column: { pointPadding: 0.2, borderWidth: 0 },
                            scatter: {
                                marker: {
                                    radius: 4,
                                    symbol: 'circle'
                                },
                                dataLabels: {
                                    enabled: true,
                                    align: 'center',
                                    y: -10,
                                    formatter: function () {
                                        return this.key; //+ " (" + this.x + "," + this.y + ")";
                                    }
                                }
                            }
                        },
                        series: seriesData
                        // series: [{
                        //     name: 'ROI',
                        //     data: seriesData
                        // }]
                    });
                }

                else if (chartType != 'pie') $('#hc_' + dataset.column).highcharts({
                    colors: ['#356493', '#DBD56E', '#88AB75', '#7D7C84', '#DE8F6E', '#ddaa00', '#6655cc', '#99aa00'],
                    legend: {
                        verticalAlign: "bottom"
                    },
                    chart: {
                        type: chartType,
                        height: 400,
                        style: {
                            fontFamily: 'NSR'

                        }
                    },
                    credits: {
                        enabled: false
                    },
                    title: { text: dataset.description },
                    xAxis: {
                        categories: _.pluck(dataset.results, 'key_value'),
                        
                    },
                    yAxis: {
                        crosshair: {
                            dashStyle: 'LongDash',
                            color: '#3A3A3A'
                        },
                        title: { text: '%' }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: { pointPadding: 0.2, borderWidth: 0 },
                        line: { dataLabels: { enabled: false } }
                    },
                    series: [{
                        name: 'ROI',
                        data: scale(_.pluck(dataset.results, 'roi'), 100)
                    }, {
                        name: 'Loss',
                        data: scale( _.pluck(dataset.results, 'loss_roi'), 100)
                    }, {
                        visible: false,
                        name: 'Avg Rate',
                        data: scale(_.pluck(dataset.results, 'average_apr'), 100)
                    }]
                });

                else $('#hc_' + dataset.column).highcharts({
                        colors: ['#356493', '#DBD56E', '#88AB75', '#7D7C84', '#DE8F6E', '#ddaa00', '#6655cc', '#99aa00'],
                        legend: {
                            verticalAlign: "bottom"
                        },
                    chart: {
                        height: 400,
                        style: {
                            fontFamily: 'NSR'

                        },
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false
                    },
                    title: { text: dataset.description },
                    tooltip: {
                        pointFormat: '${point.y:,.2f}: <b>{point.percentage:.2f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: false,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: [{
                        type: 'pie',
                        name: 'Principal',
                        data: _.map(dataset.results, function(breakdown) { return [ breakdown['key_value'], breakdown['outstanding_principal']] })
                    }],
                    credits: {
                        enabled: false
                    }
                });
            })};


    }
]);



